import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { ListModuleData } from "../../api/module";
import GenerateImageUrl from "../../utils/utils";
import { useMediaQuery } from 'react-responsive'
var qs = require('qs');

function Index(props) {
    const [moduleData, setModuleData] = useState([]);
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' })


    useEffect(() => {
        let requestBody
            requestBody = {
                categories: ["Best in Graphics"],
                offsetRange: { "offset": 0, "limit": 20 }
            };

        GetModuleData(requestBody)

    }, []);

    const GetModuleData = (requestBody) => {
        ListModuleData(props.rootPath, requestBody)
            .then((response) => {
                let data = []
                if (props.rootPath === "/podcast") {
                    data = response.data.podcasts;
                } else if (props.rootPath === "/books") {
                    data = response.data.books;
                } else if (props.rootPath === "/games") {
                    data = response.data.games;
                } else if (props.rootPath === "/ott") {
                    data = response.data.movies;
                }

                setModuleData(data || []);
            })
            .catch((error) => {
                console.error(error);
            });
    }


    if (!moduleData) {
        return <div>Loading...</div>;
    }

    return (
        <div className='explore-cooking-new-section best-in-graphics'>
            <div className='title-section'>
                <div className='title'>
                    <h3>Best In Graphics</h3>
                </div>
                <Link className='view-all-btn' to={{
                    pathname: `${props.rootPath}/content`,
                    search: qs.stringify({ categories: ["Best in Graphics"] }),
                }}>View All</Link>
            </div>

            <Swiper navigation={false} className="explore--slider" slidesPerView={'auto'} spaceBetween={18} loop={true}>
                {moduleData.map((val, key) => {
                    return (
                        <SwiperSlide key={key}>
                            <div className='explore-card' onClick={() => props.handleRedirect(val.slug)}>
                                <div className='explore-image'>
                                    <img src={GenerateImageUrl(val?.photos?.Cards_Explore_Whats_Cooking, 0, 0, false)} alt={''} />
                                </div>
                                <label className='tranding-postion'><span>{(parseInt(key) + 1) < 10 ? 0 + '' + (parseInt(key) + 1) : (parseInt(key) + 1)}</span> Trending</label>
                            </div>
                        </SwiperSlide>
                    )
                })}
            </Swiper>
        </div>
    )
}

export default Index
import React from "react";
// import './App.css';
import AllRoute from "./router";
import "./sass/style.scss";
import { AuthProvider } from "react-auth-kit";

function App() {
  return (
    <AuthProvider
      authType={"cookie"}
      authName={"_auth"}
      cookieDomain={window.location.hostname}
      cookieSecure={window.location.protocol === "https:"}
    >
      <div className="App" id="scrool">
        <AllRoute />
      </div>
    </AuthProvider>
  );
}

export default App;

import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import React, { Fragment, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Autoplay } from "swiper";
import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";
import { AddBlogsLike } from "../../api/blog";
import GenerateImageUrl, { TruncateText, GetFormatedDate, TruncateTextHtml } from "../../utils/utils";
import { useMediaQuery } from '@mui/material';
import PopupMessage from '../PopupMessage';
import { useAuthUser,    useIsAuthenticated } from "react-auth-kit";

function Index(props) {
    const [expandedSections, setExpandedSections] = useState(new Array(props.blogsData.length).fill(false));
    const isMobile  = useMediaQuery('(max-width:600px)')
    const [likedComments, setLikedComments] = useState([]);
    const navigate = useNavigate();
    const [showAuthError, setShowAuthError] = useState(false)
    const isAuthenticated = useIsAuthenticated();
    const auth = useAuthUser();
    const location = useLocation();

        const updateLikes = (index, commentSlug) => {
        if (!isAuthenticated || !auth()) {
            setShowAuthError(true);
            return
          }
        let newData = [...props.blogsData];
        if (newData[index].user_liked=='true') {
            newData[index].user_liked = 'false'
            newData[index].likes -= 1
            // Remove the commentSlug from likedComments
            setLikedComments((prevLikedComments) =>
            prevLikedComments?.filter((slug) => slug !== commentSlug));
        }else{
            newData[index].likes += 1
            newData[index].user_liked = 'true'
            setLikedComments([...likedComments, commentSlug]);
          }
          AddBlogsLike(props.label, { slug: commentSlug })
          .then((response) => { 
                props.setBlogsData(newData);
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const handleReadMoreClick = (sectionIndex) => {
        const expandedCopy = [...expandedSections];
        expandedCopy[sectionIndex] = !expandedCopy[sectionIndex];
        setExpandedSections(expandedCopy);
    };
    

    return (
        <Fragment>
            <Swiper
                slidesPerView={"auto"}
                spaceBetween={isMobile?20:32}
                autoplay={{
                    delay: 5000,
                }}
                modules={[Autoplay]}
                className="event-slider"
            >
                {props?.blogsData && props.blogsData.map((val, key) => {
                    return (
                        <SwiperSlide className='event-card' key={key}>
                            <div className='event-img-weapper'>
                                <div className='event-img'>
                                    {
                                        val.slug ?
                                            <Link to={`/blogs/${props.label}/${val.slug}`}>
                                                <img src={GenerateImageUrl(props.label=="interviews"?val.bannerImage_url:val.image, 0, 0, false)} alt='' />
                                            </Link> :
                                            <img src={GenerateImageUrl(val.image, 0, 0, false)} alt='' />
                                    }
                                </div>
                                <div className='event-meta'>
                                    <span className={"event-date"}>{GetFormatedDate(val.publish_date)}</span>
                                    <div className="event-handles">
                                        <span className={"event-likes"}><FavoriteIcon style={{fill:`${val.user_liked=='true'?'#D21A4E':'#fff'}`}} onClick={() => updateLikes(key, val.slug)} /> {val.likes}</span>
                                        <a className={"event-share"} href='#!' onClick={() => props.handleShareBtnClick(`/blogs/${props.label}/${val.slug}`)}><ShareIcon /></a>
                                    </div>
                                </div>
                            </div>
                            <div className='event-content'>
                                {
                                    val.slug ?
                                        <h4 className='event-title'>
                                            <Link to={`/blogs/${props.label}/${val.slug}`}>{val.title}</Link>
                                        </h4> :

                                        <h4 className='event-title'>
                                            {val.title}
                                        </h4>
                                }
                                <p className='event-desc'>
                                    {
                                    TruncateTextHtml(
                                        (val.description || val.introduction),
                                        expandedSections[key] ? 50000 : 200
                                    ) + " "}
                                    {(val.description || val.introduction) &&
                                        (val.description || val.introduction).length > 200 &&
                                        !expandedSections[key] && (
                                            <Link className='event-read' onClick={() => handleReadMoreClick(key)}>
                                                Read more
                                            </Link>
                                        )}
                                    {(val.description || val.introduction) &&
                                        (val.description || val.introduction).length === (val.description || val.introduction).length &&
                                        expandedSections[key] &&
                                        (
                                            <Link className='event-read' onClick={() => handleReadMoreClick(key)}>
                                                Read less
                                            </Link>
                                        )}
                                </p>
                            </div>
                        </SwiperSlide>
                    )
                })}
            </Swiper>
            {showAuthError && (
                <PopupMessage
                type="error"
                message="Authentication Failed!"
                desc={"Please log in to your account."}
                buttonTitle={"Log In"}
                handleClose={()=> {setShowAuthError(false)}}
                open={showAuthError}
                handleSubmit={()=> {navigate(`/login?next=${location.pathname}`)}}
                />
            )}
        </Fragment>
    )
}

export default Index
import React from 'react';
import GenerateImageUrl, {GetFormatedDate} from '../../utils/utils';
import { Sanitize } from '../../utils/sanitizeHtml';

function Events(props) {
    return (
        <div className='blogs-container'>
            <div className='event-content'>
                <h4 className='event-heading'>Event Details</h4>
                <div className='event-metalist'>
                    <div className='event-list'>
                        <h5 className='event-label'>Venue</h5>
                        <h4 className='event-value'>{props.data.venue}</h4>
                    </div>
                    <div className='event-list'>
                        <h5 className='event-label'>Date & Time</h5>
                        <h4 className='event-value'><span>{GetFormatedDate(props.data.publish_date)}</span>  |  <span>{`${props.data.event_time_start} to ${props.data.event_time_end}`}</span></h4>
                    </div>
                    <div className='event-list'>
                        <h5 className='event-label'>Age Limit</h5>
                        <h4 className='event-value'>{props.data.suitableFor}</h4>
                    </div>
                    <div className='event-list'>
                        <h5 className='event-label'>Fees</h5>
                        <h4 className='event-value'>₹ {props.data.fees}/-</h4>
                    </div>
                </div>
                <div className='event-detail'>
                {props.data?.descriptions?.map((item) => (
                    <div className='content' key={item} >
                    <div className='desc' dangerouslySetInnerHTML={{ __html: Sanitize(item?.description) }}></div>
                        <br />
                        <img src={GenerateImageUrl(item?.desc_image_url, 0, 0, false)} />
                        {item?.desc_image_url !== null ? <br /> : null}
                    </div>
                    ))}

                </div>
                {/* <div className='event-register-form'>
                    <form>
                        <h4 className='form-heading'>Register for the Masterclass</h4>
                        <div className='form-grid'>
                            <div className='from-control'>
                                <label>Name</label>
                                <input placeholder='Enter name' onChange={(e) => setFullName(e.target.value)} defaultValue={fullName} required />
                            </div>
                            <div className='from-control'>
                                <label>Email address</label>
                                <input placeholder='Enter Email' onChange={(e) => setEmail(e.target.value)} defaultValue={email} required />
                            </div>
                            <div className='from-control'>
                                <label>Mobile Number</label>
                                <input placeholder='Enter mobile number' onChange={(e) => setMobile(e.target.value)} defaultValue={mobile} required />
                            </div>
                        </div>
                        <div className='form-action'>
                            <button className='btn-register' type='submit' name='register'>Register</button>
                        </div>
                        <p className='form-disclimer'>*Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus.</p>
                    </form>
                </div> */}
            </div>
        </div>
    )
}

export default Events
import DOMPurify from 'dompurify';

DOMPurify.addHook('afterSanitizeAttributes', (node) => {
    if ('target' in node) {
      node.setAttribute('target', '_blank');
      node.setAttribute('rel', 'noopener noreferrer');
    }
  });

  export const Sanitize=(htmlString)=>{
    return DOMPurify.sanitize(htmlString, { ADD_ATTR: ['target'] })
  }
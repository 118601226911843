import React, { Fragment, useState } from 'react'
import { useMediaQuery } from "react-responsive";
import TopBar from './TopBar'
import SideBar from './SideBar'
function Navbar(props) {
    const [isOpen, setisOpen] = useState(false);
    const isTabletOrMobile = useMediaQuery({ query: "(max-width: 998px)" });
    const handleMenu = () => {
        setisOpen(!isOpen)
    }
    return (
        <Fragment>
            <TopBar handleMenu={handleMenu}/>
            <SideBar open={isOpen} isTabletOrMobile={isTabletOrMobile} handleMenu={handleMenu}/>
        </Fragment>
    )
}

export default Navbar
import React from 'react'
import interviewUser from '../../images/blogs/interview-user.png';
import interviewer from '../../images/blogs/interviewer.png';
import GenerateImageUrl, { TruncateText } from '../../utils/utils';
import placeholderImage from '../../images/profile-image.jpg'
import { Sanitize } from '../../utils/sanitizeHtml';

function Interview(props) {
    return (
        <div className='blogs-container'>
            <div className='interview-inner'>
                <div className='user-message'>
                    <div className='message-box' dangerouslySetInnerHTML={{ __html: Sanitize(props.data.introduction) }}></div>
                </div>

                {props.data?.question_answers?.map((item) => (
                    <div key={item}>
                            {item.question &&<div className='interview-inner'>
                                <div className='user-message'>
                                    <div className='interviewer-message'>
                                        <div className='message-box' dangerouslySetInnerHTML={{ __html: Sanitize(item.question) }}>
                                        </div>
                                        <div className='user-info'>
                                            <div className='user-img'>
                                                <img src={props.data.interviewer_details?.image?GenerateImageUrl(props.data.interviewer_details.image):placeholderImage} alt={props.data.interviewer_details?.name } />
                                            </div>
                                            <div className='user-content'>
                                                <h6 className='user-title'>{props.data.interviewer_details?.name }</h6>
                                                <span className='user-type'>{TruncateText(props.data.interviewer_details?.profile, 20)}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>}
                            {item.answer &&<div className='user-message with-box'>
                                <div className='message-box' dangerouslySetInnerHTML={{ __html: Sanitize(item.answer) }}></div>
                                <div className='user-info'>
                                    <div className='user-img'>
                                        <img src={props.data.interviewee_details?.image?GenerateImageUrl(props.data.interviewee_details?.image):placeholderImage} alt={props.data.interviewee_details?.name} />
                                    </div>
                                    <div className='user-content'>
                                        <h6 className='user-title'>{props.data.interviewee_details?.name}</h6>
                                        <span className='user-type'>{TruncateText(props.data.interviewee_details?.profile,20)}</span>
                                    </div>
                                </div>
                            </div>}
                    </div>
                ))}
                <div className='user-message'>
                    <div className='message-box' dangerouslySetInnerHTML={{ __html: Sanitize(props.data.conclusion) }}></div>                    
                </div>
            </div>
        </div>
    )
}

export default Interview
import React, { Fragment, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import "swiper/css";
import GenerateImageUrl from "../../utils/utils"
import ottThumb from '../../images/ott_image/ott-thumb-crop.png';
import gamesThumb from '../../images/ott_image/game-thum.png';
import prodcastThumb from '../../images/ott_image/prodcasts-thum.png';
import booksThumb from '../../images/ott_image/books-thumb.png';

function GridSlider(props) {
    const [load, setload] = useState(false);
    useEffect(() => {
        setload(true)
    }, [props.data, props.data.title])

    var thumbImage = ottThumb;
    if (props.rootPath === "/games") {
        thumbImage = gamesThumb;
    } else if (props.rootPath === "/podcast") {
        thumbImage = prodcastThumb;
    } else if (props.rootPath === "/books"){
        thumbImage = booksThumb
    }

    return (
        <Fragment>
            {load === true &&
                <div className='grid-collection-section'>
                    <div className='grid-slider-box'>
                        <div className='grid-slider-img' style={{ background: `url(${props.bgImg})` }}>
                            <img className='grid-slider-thumb' src={thumbImage} alt='thumb' />
                            <Swiper
                                slidesPerView={'auto'}
                                spaceBetween={16}
                                autoplay={{
                                    delay: 5000,
                                }}
                                modules={[Autoplay]}
                                className="grid-swiper"
                            >
                                {props.data.map((val, key) => {
                                    return (
                                        <SwiperSlide className='grid-slide' key={key}>
                                            <Link to={`${props.rootPath}/details/${val.slug}`}>
                                                <img src={GenerateImageUrl(val?.photos?.Cards_Your_Mood, 300, 235)} className='grid-slide-img' alt={val.Title} />
                                            </Link>
                                        </SwiperSlide>
                                    )
                                })}
                            </Swiper>
                        </div>
                    </div>
                </div>}
        </Fragment>

    )
}

export default GridSlider
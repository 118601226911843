import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Autoplay, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
// import PropTypes from 'prop-types'
import { GradientCircularProgress } from "react-circular-gradient-progress";
import { GetHomePostsData } from "../../api/home_api";
import GenerateImageUrl, { TruncateText } from "../../utils/utils";
import { useMediaQuery } from 'react-responsive';
import TimeIcon from '../../images/icons/timer';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';

function PlatformButtonComponant(props) {
    if (props.value.length <= 0) {
        return
    }
    let data = props.value[0]
    let buttonTtile = `Watch on ${data.name}`
    switch (props.module) {
        case 'games':
            buttonTtile = `Play on ${data.name}`
            break;
        case 'podcasts':
            buttonTtile = `Listen on ${data.name}`
            break;
        case 'books':
            buttonTtile = `Read on ${data.name}`
            break;
        default:
            buttonTtile = `Watch on ${data.name}`
            break;
    }
    return <Link className="fws-btn btn-bg-blue" to={data.platform_url} target="_blank" rel="noopener noreferrer">{buttonTtile}</Link>;
}


function FullWdSlider(props) {
    const [sliderData, setSliderData] = useState({});
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' })

    const UrlMap = {
        "movies": "/ott",
        "podcasts": "/podcast",
        "books": "/books",
        "games": "/games",
    }
    useEffect(() => {
        GetHomePostsData("Homepage Top")
            .then((response) => {
                setSliderData(response.data.result);
            })
            .catch((error) => {
                console.error(error);
            });
    }, [])

    const AspactData = (key, data) => {
        var result
        if (key === "movies") {
            result = [
                {
                    "title": "Story",
                    "value": data.aspect_story
                },
                {
                    "title": "Direction",
                    "value": data.aspect_direction
                },
                {
                    "title": "Performance",
                    "value": data.aspect_performance
                },
                {
                    "title": "Music",
                    "value": data.aspect_music
                }
            ]
        } else if (key === "books") {
            result = [
                {
                    "title": "Plot",
                    "value": data.aspect_plot
                },
                {
                    "title": "Setting",
                    "value": data.aspect_setting
                },
                {
                    "title": "Characters",
                    "value": data.aspect_characters
                },
                {
                    "title": "Style Of Writing",
                    "value": data.aspect_styleOfWriting
                }
            ]
        } else if (key === "podcasts") {
            result = [
                {
                    "title": "Introduction",
                    "value": data.aspect_introduction
                },
                {
                    "title": "Content",
                    "value": data.aspect_content
                },
                {
                    "title": "Audio Quality",
                    "value": data.aspect_audioQuality
                },
                {
                    "title": "Voices",
                    "value": data.aspect_voices
                },
                {
                    "title": "Outro",
                    "value": data.aspect_outro
                }
            ]
        } else if (key === "games") {
            result = [
                {
                    "title": "Graphics",
                    "value": data.aspect_graphics
                },
                {
                    "title": "Performance",
                    "value": data.aspect_performance
                },
                {
                    "title": "Animation",
                    "value": data.aspect_animation
                },
                {
                    "title": "Ease Of Use",
                    "value": data.aspect_easeOfUse
                }
            ]
        }
        return result
    }

    return (
        <div className='fws-slider'>
            <Swiper
                slidesPerView={"auto"}
                spaceBetween={isTabletOrMobile?10:30}
                pagination={isTabletOrMobile?false:{
                    clickable: true,
                }}
                autoplay={{
                    delay: 3000,
                }}
                modules={[Pagination, Autoplay]}
                className="mySwiper"
            >
                {Object.keys(sliderData).map((key) => (
                    <div>
                        {sliderData[key].map((val, idx) => (
                            <SwiperSlide className='fws-slide' onClick={()=>{console.log(val)}}>
                                <img className='bg-img' src={GenerateImageUrl(isTabletOrMobile?(val?.photos?.Top_Image_Banner_HomePage_Responsive):(val?.photos?.Banner_Details_Page), 0, 0, false)} alt={val.Title} />
                                <div className='overlay-bg'></div>
                                <div className='fws-content'>
                                    <div className="flex">
                                    <div className="">
                                                {
                                                    (val.isSeries==1) && isTabletOrMobile?
                                                    <div className='item-timer'><TimeIcon /> <span>{val.episodes} episodes</span></div>:
                                                    val.Pages && isTabletOrMobile?
                                                    <div className='item-timer'><AutoStoriesIcon /> <span>{val.Pages} Pages</span></div>:
                                                    val.duration && isTabletOrMobile?
                                                    <div className='item-timer'><TimeIcon /> <span>{val.duration} mins</span></div>:<></>
                                                    
                                                }
                                        <h4 className='fws-title'>{val.Title}</h4>
                                        {Array.isArray(val?.genres) && val.genres.length > 0 && <ul className='fws-categoties'>
                                            {val?.genres && val.genres.join(" | ")}
                                        </ul>}
                                        <div className='fws-desc'>{TruncateText(val?.description, 220)}</div>
                                    </div>
                                    <div className='fws-info-graph'>
                                        <div className='fws-main-graph'>
                                            <div className='rating-box'>
                                                <GradientCircularProgress
                                                    animate={true}
                                                    startColor="#FE316C"
                                                    middleColor="#ED2860"
                                                    endColor="#A5002F"
                                                    emptyColor="rgba(255, 255, 255, 0.5)"
                                                    size={70}
                                                    progress={val.ebitsRating * 10}
                                                />
                                                <span className='rating-count'>{val.ebitsRating < 10 ? val.ebitsRating.toFixed(1) : val.ebitsRating}</span>
                                                <h4 className='fws-main-graph-title'>EQbits Ratings</h4>
                                            </div>
                                        </div>
                                        {!isTabletOrMobile && <div className='fws-sec-graph'>
                                            <ul>
                                                {AspactData(key, val).map((aspectVal, idx) => (
                                                    <li>
                                                        <div className='fws-bar-graph'>
                                                            <div className='bar-filled' style={{ width: `${aspectVal.value}%` }}></div>
                                                        </div>
                                                        <p className='fws-graph-title'>{aspectVal.title}</p>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>}
                                    </div>
                                    </div>
                                    <div className='fws-btn-group'>
                                        <PlatformButtonComponant value={val.platforms} module={key} />
                                        <Link className='fws-btn btn-outline'
                                            to={`${UrlMap[key]}/details/${val.slug}`}
                                        >View Details</Link>
                                    </div>
                                </div>
                            </SwiperSlide>
                        ))}
                    </div>
                ))}
            </Swiper>
        </div>
    )
}

// FullWdSlider.propTypes = {}

export default FullWdSlider

import React, { useState } from "react";
import {
    Bar,
    BarChart,
    CartesianGrid,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis
} from "recharts";
import { chartcolors } from '../../utils/constant';
import { useMediaQuery } from "@mui/material";



export default function EQbitBarChart(props) {
    const [activeLabel, setactiveLabel] = useState(0);
    const isTab  = useMediaQuery('(max-width:1024px)')

    let label = []
    for (let item in props.data[0]) {
        if (item !== "name") {
            label.push({
                "key": item,
                "label": item.toUpperCase()
            })
        }
    }

    // Generate an array of random colors for each line
    const colors = props.data.map((_,key) => chartcolors[key].hex);

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <div className="custom-tooltip" style={{ background: payload[0]?.fill, padding: "0 10px", borderRadius: '8px', height: '20px', lineHeight: "20px", color: '#fff' }}>
                    <span className="label" style={{ fontSize: "12px" }}>{`${label} : ${payload[0].value}`}</span>
                </div>
            );
        }

        return null;
    };
    const CustomizedAxisTick = (props) => {
        const { x, y, payload } = props;
          const truncateLabel = (label, maxLength) => {
            return label.length > maxLength ? label.substring(0, maxLength) + '..' : label;
          };
        
          const truncatedValue = truncateLabel(payload.value, 6);
        return (
          <g transform={`translate(${x},${y})`} width={10}>
            <text className="xAxis-Label" x={0} y={0} dy={20} width={4} fontSize={isTab?9:12} textAnchor="end" fill="#fff" transform="rotate(-45)">
            {truncatedValue}
            </text>
          </g>
        );
      };

    return (
        <div className="bar-chart">
            <div className="bar-chart-container" style={{ width: '110%', height: props.height+'px' || "300px", maxWidth:"600px" }}>
                <ResponsiveContainer>
                    <BarChart
                        width={'100%'}
                        height={parseInt(props.height) || 300}
                        data={props.data}
                        margin={{
                            // top: 20,
                            // right: 30,
                            left: 0,
                            // bottom: 5
                        }}
                    >
                        <CartesianGrid horizontal={false} vertical={false} />
                        <XAxis 
                            dataKey="name" 
                            tickLine={false} 
                            axisLine={false} 
                            interval={0} 
                            // angle={-45} 
                            padding={{ left: 50, right: 50 }}
                            tick={<CustomizedAxisTick />}
                            height={80} // Adjust the height of the X-axis to accommodate the rotated labels
                            fill="#fff"
                            />
                        <YAxis tickLine={false} axisLine={false} width={5}
                            label={{ value: props.label, angle: -90, position: "insideLeft",  }}
                        />
                        <Tooltip cursor={false} content={<CustomTooltip />} />
                        {/* <Legend /> */}
                        {label.map((val, key) => (
                            <Bar
                                key={key}
                                dataKey={val.key}
                                stackId="a"
                                fill={colors[key]}
                                maxBarSize={isTab?7:15}
                                fillOpacity={activeLabel === val.key ? 1 : 0.9}
                                stroke={activeLabel === val.key ? '#fff' : colors[key]}
                                strokeWidth={2}
                                shape={(props) => (
                                    <rect
                                        {...props}
                                        rx={8}
                                        ry={8}
                                        width={props.width}
                                        height={props.height}
                                    />
                                )}
                            />
                        ))}
                    </BarChart>
                </ResponsiveContainer>

            </div>
            <div className='about-meta-slider'>
                <ul className='chart-data-list'>
                    {label.map((val, key) => {
                        return (
                            <li
                                onMouseEnter={() => setactiveLabel(val.key)}
                                onClick={() => { setactiveLabel(val.key) }}
                                key={key}
                                className={`${activeLabel === val.slug ? 'active' : ''}`}
                                style={{ background: `${activeLabel === key ? val.fill : ''}` }}
                                data-color={colors[key]}>
                                <span style={{ background: colors[key] }}></span><p>{val.key}</p>
                            </li>
                        )
                    })}
                </ul>
            </div>
        </div>
    );
}

import qs from 'qs'
import React, { Component } from "react";
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import logo from '../../images/footer-logo-new.svg'
import { Link } from "react-router-dom";


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  width: '100%',
  color: theme.palette.text.secondary,
}));
class Footer extends Component {
  constructor(props) {
      super(props);
      this.state = {
          activeId: null,
          activeLink: "/",
          isMobile: this.props.isTabletOrMobile
      }
  }
  render(){
    return (
      <footer className={'eqbits-footer'}>
        {/* <Container maxWidth="lxx">
          <Grid container spacing={2}>
        */}
            <div item xs={12} md={3} className="footer-left"> 
              <div className="footer-logo">
                <img className="logo" src={logo} alt="EQbits" />
              </div>
              <div className="footer-copyright">
                <p className="copyright-desc">© {new Date().getFullYear()} EQbits. All rights reserved</p>
              </div>
              </div>
              <div item xs={12} md={9} className="footer-right">
            {/* 
              <Grid container>
                <Grid xs={6} lg> */}
                  <div className="footer-links">
                    <h4 className="footer-heading"
                      id="category-a"
                    >
                      OTT
                    </h4>
                    <Box component="ul" aria-labelledby="category-a" sx={{ pl: 2 }}>
                      <li>
                        <Link to={{
                                pathname: `/ott/content`,
                                search: qs.stringify({"categories": ["Best In Story"]}),
                            }}>Best In Story</Link></li>
                      <li><Link to={{
                                pathname: `/ott/content`,
                                search: qs.stringify({"categories": ["Hidden Gems"]}),
                            }}>Hidden Gems</Link></li>
                      <li><Link to={{
                                pathname: `/ott/content`,
                                search: qs.stringify({"categories": ["Upcoming Movies"]}),
                            }}>Upcoming Movies</Link></li>
                      <li><Link to={{
                                pathname: `/ott/content`,
                                search: qs.stringify({"categories": ["New Releases"]}),
                            }}>New Releases</Link></li>
                    </Box>
                  </div>
                {/* </Grid>
                <Grid xs={6} lg> */}
                  <div className="footer-links">
                    <h4 className="footer-heading"
                      id="category-a"
                    >
                      Books
                    </h4>
                    <Box component="ul" aria-labelledby="category-a" sx={{ pl: 2 }}>
                      <li><Link to={{
                                pathname: `/books/content`,
                                search: qs.stringify({"categories": ["Popular"]}),
                            }}>Most Popular</Link></li>
                      <li><Link to={{
                                pathname: `/books/content`,
                                search: qs.stringify({"categories": ["Ebits_Treasure"]}),
                            }}>EQbits Treasure </Link></li>
                      <li><Link to={{
                                pathname: `/books/content`,
                                search: qs.stringify({"genres": ["Ethnic"]}),
                            }}>Ethnic Genre</Link></li>
                      <li><Link to={{
                                pathname: `/books/content`,
                                search: qs.stringify({"categories": ["Newly Added"]}),
                            }}>Newly Added </Link></li>
                    </Box>
                  </div>
                {/* </Grid>
                <Grid xs={6} lg> */}
                  <div className="footer-links">
                    <h4 className="footer-heading"
                      id="category-b"
                    >
                      Podcasts
                    </h4>
                    <Box component="ul" aria-labelledby="category-b" sx={{ pl: 2 }}>
                      <li><Link to={{
                                pathname: `/podcast/content`,
                                search: qs.stringify({"categories": ["Best In Story"]}),
                            }}>Best In Story</Link></li>
                      <li><Link to={{
                                pathname: `/podcast/content`,
                                search: qs.stringify({"categories": ["Recommended"]}),
                            }}>Recommended</Link></li>
                      <li><Link to={{
                                pathname: `/podcast/content`,
                                search: qs.stringify({"categories": ["Trending Now"]}),
                            }}>Trending Now</Link></li>
                      <li><Link to={{
                                pathname: `/podcast/content`,
                                search: qs.stringify({"moods":["Comedy", "Crime", "informative"]
                                  }),
                            }}>What's your mood</Link></li>
                    </Box>
                  </div>
                {/* </Grid>
                <Grid xs={6} lg> */}
                  <div className="footer-links">
                    <h4 className="footer-heading"
                      id="category-c"
                    >
                      Games
                    </h4>
                    <Box component="ul" aria-labelledby="category-c" sx={{ pl: 2 }}>
                      <li><Link to={{
                                pathname: `/games/content`,
                                search: qs.stringify({"categories": ["Popular"],"genres":["Action"]}),
                            }}>Popular in Action</Link></li>
                      <li><Link to={{
                                pathname: `/games/content`,
                                search: qs.stringify({"categories": ["Ebits_Treasure"]}),
                            }}>EQbits Treasure</Link></li>
                      <li><Link to={{
                                pathname: `/games/content`,
                                search: qs.stringify({"moods": ["Stealth"]}),
                            }}>Stealth Mode</Link></li>
                      <li><Link to={{
                                pathname: `/games/content`,
                                search: qs.stringify({"categories": ["Newly Added"]}),
                            }}>Newly Added</Link></li>
                    </Box>
                  </div>
                {/* </Grid>
                <Grid xs={6} lg> */}
                  <div className="footer-links">
                    <h4 className="footer-heading"
                      id="category-c"
                    >
                      Specials
                    </h4>
                    <Box component="ul" aria-labelledby="category-c" sx={{ pl: 2 }}>
                      <li><Link to={'/blogs?type=articles'}>Featured Content</Link></li>
                      <li><Link to={'/blogs?type=interviews'}>Conversations</Link></li>
                      <li><Link to={'/blogs?type=events'}>Events Buzz</Link></li>
                    </Box>
                  </div>
                {/* </Grid>
                <Grid xs={6} lg> */}
                  <div className="footer-links">
                    <h4 className="footer-heading"
                      id="category-d"
                    >
                      Support
                    </h4>
                    <Box component="ul" aria-labelledby="category-d" sx={{ pl: 2 }}>
                      <li><Link to="/contact-us">Write to Us</Link></li>
                      <li><Link to="/about-us">About Us</Link></li>
                    </Box>
                  </div>
                {/* </Grid>
              </Grid>
            </Grid>
          */}
          </div> 
          <div className="footer-copyright mobile">
                <p className="copyright-desc">© {new Date().getFullYear()} EQbits. All rights reserved</p>
          </div>
        {/* </Container> */}
      </footer>
    );
  }
}

export default Footer;
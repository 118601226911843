import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import about_banner from '../../images/home/about-ebits-bg.webp'
import { TruncateText } from '../../utils/utils'

function AboutEbits({paragraph}) {
  return (
    <Fragment>
        <div className='about-ebits-section'>
            <div className='about-ebits'>
                <h2 className='theme-title'>About <span>EQbits</span></h2>
                <p>
                {TruncateText(paragraph,300)}
                </p>
                <Link to='/about-us' className='theme-btn'>Find Out More</Link>
            </div>
            <div className='about-banner'>
                <img src={about_banner} alt='about-EQbits'/>
            </div>
        </div>
    </Fragment>
  )
}

export default AboutEbits
import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import GenerateImageUrl from '../../utils/utils';
import { GetFormatedDate } from "../../utils/utils";


function EventsCard(props) {
    return (
        <Fragment>
            {props.blogsData.map((val, key) => (
                <div className='event-col' key={key}>
                    <div className='event-card'>
                        <Link to={`/blogs/events/${val.slug}`}>
                        <div className='event-image'>
                            <img src={GenerateImageUrl(val.image, 0, 0, false)} alt={val.title} />
                            <h5 className='event-subtitle'>{val.subTitle}</h5>
                        </div>
                        </Link>
                        <div className='event-contents'>
                            <h4 className='event-title'><Link to={`/blogs/events/${val.slug}`}>{val.title}</Link></h4>
                            <ul className='event-meta-info'>
                                <li className='event-venue'>
                                    <p className='event-meta-title'>Venue</p>
                                    <div className='event-meta-desc'>
                                        <h6 className='event-location'>{val.venue}</h6>
                                        <p className='event-time'>{GetFormatedDate(val.publish_date)}  |  {`${val.event_time_start} To ${val.event_time_end}`}</p>
                                    </div>
                                </li>
                                <li className='event-agelimit'>
                                    <p className='event-meta-title'>Age Limit</p>
                                    <div className='event-meta-desc'>
                                        <h6>{val.suitableFor}</h6>
                                    </div>
                                </li>
                                <li className='event-fees'>
                                    <p className='event-meta-title'>Fees</p>
                                    <div className='event-meta-desc'>
                                        <h6 className='event-fees'>₹ {val.fees}/-</h6>
                                    </div>
                                </li>
                            </ul>
                            <div className='event-person'>
                                <div className='event-person-img'>
                                    <img src={GenerateImageUrl(val.organiser_image, 0, 0, false)} alt={val.organiser_name} />
                                </div>
                                <div className='event-person-content'>
                                    <h4 className='event-person-title'>{val.organiser_name}</h4>
                                    <p className='event-person-tag'>{val.organiser_tag}</p>
                                </div>
                            </div>
                            <Link to={`/blogs/events/${val.slug}`} className='event-read'>Read More</Link>
                        </div>
                    </div>
                </div>
            ))}
        </Fragment>

    )
}

export default EventsCard
import axios from "axios";
import IsAuthenticated, { GetToken, RemoveAllCookies } from "./cookies";

// create an axios instance
const service = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL, // url = base url + request url
  timeout: 30000, // request timeout
});

// request interceptor
service.interceptors.request.use(
  (config) => {
    if (IsAuthenticated()) {
      config.headers["Authorization"] = GetToken();
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// response interceptor
service.interceptors.response.use(
  (response) => {
    const res = response.data;

    if (response.status <= 200 && response.status >= 300) {
      if (
        response.status === 401
      ) {
        RemoveAllCookies();
      }
      return Promise.reject(new Error(res));
    } else {
      return response;
    }
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default service;

import React, { useEffect, useState } from 'react';
import { RadialBar, RadialBarChart, ResponsiveContainer } from 'recharts';
import { chartcolors } from '../../utils/constant';
function RadialbarChart(props) {
    const [activeLabel, setactiveLabel] = useState(0);
    const [data, setdata] = useState([]);
    useEffect(() => {
        let temp = [];
        props.data.map((val, key) => {
            temp.push({
                fill : chartcolors[key].hex, ...val
            })
        })
        setdata(temp);
    },[props.data])
    return (
        <>
            {data && <>
                <ResponsiveContainer style={{margin:'10px'}} width="100%" height={parseInt(props.height) || 350}>
                    <RadialBarChart width={"100%"} height={parseInt(props.height) || 350} data={data} startAngle={0} endAngle={225}
                        innerRadius="30%" outerRadius="100%" onClick={(e) => { e?.activeLabel !== undefined && setactiveLabel(e.activeLabel) }}>
                        <RadialBar minAngle={30} dataKey="x" clockWise />
                    </RadialBarChart>
                </ResponsiveContainer>
                <div className='chart-meta-info'>
                    <div className='chart-wedget' style={{ background: chartcolors[activeLabel].hex }}>
                        <h6 className='value'>{props.data[activeLabel].x}%</h6>
                        <label>{props.label}</label>
                    </div>
                    {/* <h6 className='about-chart-label'>{props.label}</h6> */}
                    <div className='about-meta-slider'>
                        <ul className='chart-data-list'>
                            {props.data.map((val, key) => {
                                return (
                                    <li onMouseEnter={() => setactiveLabel(key)} onClick={() => setactiveLabel(key)} key={key} className={`${activeLabel === val.slug ? 'active' : ''}`} style={{ background: `${activeLabel === key ? chartcolors[key].hex + '60' : ''}` }} data-color={chartcolors[key].hex}><span style={{ background: chartcolors[key].hex }}></span><p>{val.name}</p></li>
                                )
                            })}
                        </ul>
                    </div>
                </div>
            </>}
        </>
    )
}

export default RadialbarChart
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { slice } from "lodash";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const theme = createTheme({
  root: {
    fontFamily: ["Poppins"],
  },
  palette: {
    primary: {
      main: "#D21A4E",
      darker: "#D21A4E",
    },
  },
  MuiCheckbox: {
    styleOverrides: {
      root: {
        transition: "all .2s ease",
        color: "#fff",
        ".MuiButtonBase-root": {
          ".MuiSvgIcon-root": {
            color: "#fff",
          },
        },
        "&.Mui-checked": {
          ".MuiSvgIcon-root": {
            color: "#D21A4E",
          },
          ".MuiFormControlLabel-label": {
            color: "#FFF",
          },
        },

        ".MuiSvgIcon-root": {
          fontSize: "2.2rem",
        },

        ".MuiTouchRipple-root": {
          opacity: "0.3",
        },
      },
    },
  },
});

const Accordion = ({ header, content, onChange, filterData: initialFilterData  }) => {
  const [filterData, setFilterData] = useState(initialFilterData || []); // Initialize filterData with an empty array if it's initially undefined
  const [isOpen, setIsOpen] = useState(true);
  const [isCompleted, setIsCompleted] = useState(false);
  const [index, setIndex] = useState(5);
  const initialContent = slice(content, 0, index);
  const location = useLocation();

  const loadMore = () => {
    setIndex(index + 5);
    if (index + 5 >= content.length) {
      setIsCompleted(true);
    } else {
      setIsCompleted(false);
    }
  };
  
  useEffect(() => {
    if (index >= content.length) {
      setIsCompleted(true);
    }
  }, [index, content]);

  useEffect(() => {
    setFilterData(initialFilterData)
  }, [initialFilterData]);


  const toggle = (event, index) => {
    const selectedItem = content[index].name;
    const updatedFilterData = filterData.includes(selectedItem)
      ? filterData.filter((item) => item !== selectedItem)
      : [...filterData, selectedItem];
    onChange(updatedFilterData);
  };

  return (
    <div className="accordion">
      <div className="accordion-header" onClick={() => setIsOpen(!isOpen)}>
        {header} {isOpen ? <ExpandMoreIcon /> : <ExpandLessIcon />}
      </div>
      {isOpen && (
        <div className="accordion-content">
          <ThemeProvider theme={theme}>
            <FormGroup>
              {initialContent.map((item, key) => {
                  return (
                    <FormControlLabel
                      key={key}
                      control={
                        <Checkbox
                          onChange={(e) => toggle(e, key)}
                          name={`${header}[]`}
                          value={item.name}
                          checked={filterData?.includes(item.name)}
                        />
                      }
                      label={
                        item.hasOwnProperty("count")
                          ? `${item.name} (${item.count})`
                          : item.name
                      }
                    />
                  );
              })}
              {isCompleted === false ? (
                <button onClick={loadMore} type="button" className="btn--all">
                  View all
                </button>
              ) : (
                ""
              )}
            </FormGroup>
          </ThemeProvider>
        </div>
      )}
    </div>
  );
};

export default Accordion;

import React, { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import { GetHomeReportsData } from "../../api/home_api";
import { TruncateText } from "../../utils/utils";
import EQbitLineChart from '../Charts/LineChart';
import RadialbarChart from '../Charts/RadialBarChart';
import SpiderRadarChart from '../Charts/SpiderRadarChart';
import EQbitBarChart from '../Charts/barChart';
import { useMediaQuery } from '@mui/material';

function AboutHome(props) {
    const UrlMap = {
        "movie_reports": "/ott",
        "podcast_reports": "/podcast",
        "book_reports": "/books",
        "game_reports": "/games",
    }
    const [reportData, setReportData] = useState([]);
    const [firstReportUrl, setFirstReportUrl] = useState("/");
    const isSmallPhone  = useMediaQuery('(max-width:1000px)')
    useEffect(() => {
        GetHomeReportsData()
            .then((response) => {
                const data = response.data.result || [];
                setReportData(data);
                getFirstReportUrl(data);
            })
            .catch((error) => {
                console.error(error);
            });
    }, []);

    const getFirstReportUrl=(data)=>{
        // Assuming data is an object with properties like movie_reports, podcast_reports, etc.
        const reportType = Object.keys(data)[0]; // Adjust the index based on your actual data

        if (Object.keys(data).length > 0 && data[reportType].length > 0) {
            const firstReport = data[reportType][0];
            const firstReportUrl = `${UrlMap[reportType]}/info-graphics/${firstReport.slug}`; // Adjust this based on your data structure
            setFirstReportUrl(firstReportUrl);
            console.log('repost data', firstReportUrl);
        }
    }

    return (
        <Fragment>
            <div className='about-section'>
                <div className='about-info' onClick={()=> getFirstReportUrl(reportData)}>
                    <h2 className='about-title'>Lorem <span>Ipsum</span></h2>
                    <p>{props.paragraph}</p>
                    <Link to={firstReportUrl} className="theme-btn">Explore</Link>
                </div>
                <div className='about-info-chart'>
                    <Swiper
                        slidesPerView={"auto"}
                        spaceBetween={36}
                        autoplay={{
                            delay: 5000,
                        }}
                        modules={[Autoplay]}
                        className="about-chart-slider"
                    >
                        {Object.keys(reportData).map((key) => (
                            <div key={key}>
                                {reportData[key].map((val, idx) => (
                                    <SwiperSlide className='about-chart-card' key={key}>
                                                <Link to={`${UrlMap[key]}/info-graphics/${val.slug}`}><h4 className='about-chart-title'>{val.title}</h4></Link>
                                            {val.chart.type === "RadialBarChart" && (
                                                <RadialbarChart data={val.chart.data} label={val.chart.lebel} height={isSmallPhone?"250":"350"}/>
                                            )}
                                            {val.chart.type === "LineChart" && val.chart.data && (
                                                <EQbitLineChart data={val.chart.data}  height={isSmallPhone?"220":"300"}/>
                                            )}
                                            {val.chart.type === "BarChart" && val.chart.data && (
                                                <EQbitBarChart data={val.chart.data} label={"Popularity Score"}  height={isSmallPhone?"220":"300"}/>
                                            )}
                                            {val.chart.type === "SpiderRadarChart" && val.chart.data && (
                                                <SpiderRadarChart data={val.chart.data} label={""}  height={isSmallPhone?"220":"300"}/>
                                            )}

                                            <p className='about-chart-desc'><span>{TruncateText(val.summary, 250)}</span>
                                                &nbsp;<Link className={'read-more'} to={`${UrlMap[key]}/info-graphics/${val.slug}`}>
                                                    Read more
                                                </Link>
                                            </p>
                                    </SwiperSlide>
                                ))}
                            </div>
                        ))}
                    </Swiper>
                </div>
            </div>
        </Fragment>
    )
}

export default AboutHome
export default function GenerateImageUrl(
  path,
  width = 268,
  height = 335,
  shouldResize = true
) {
  if (path === undefined || path == null || path.length <= 0) {
    return "";
  }
  if (shouldResize) {
    return `https://ik.imagekit.io/eqbits/tr:w-${width},h-${height}${path.replace(
      "eqbits/",
      ""
    )}`;
  } else {
    return `https://ik.imagekit.io/eqbits/${path.replace("eqbits/", "")}`;
  }
}

export function GetDaysFromNow(date) {
  try {
    date = new Date(date);
  } catch (error) {
    return "";
  }

  date = parseInt(
    Math.abs(
      Math.ceil((date.getTime() - new Date().getTime()) / (1000 * 3600 * 24))
    )
  );
  if (date > 1) {
    date = `${date} Days ago`;
  } else {
    date = `${date} Day ago`;
  }
  return date;
}

export function GetPastDaysFromNow(date) {
  try {
    date = new Date(date);
  } catch (error) {
    return "";
  }

  const timeDifference = new Date().getTime() - date.getTime();
  const daysDifference = parseInt(Math.abs(timeDifference / (1000 * 3600 * 24)));

  if (daysDifference < 1) {
    const hoursDifference = parseInt(Math.abs(timeDifference / (1000 * 3600)));
    return hoursDifference > 1 ? `${hoursDifference} Hours ago` : `${hoursDifference} Hour ago`;
  } else if (daysDifference < 31) {
    return daysDifference > 1 ? `${daysDifference} Days ago` : `${daysDifference} Day ago`;
  } else if (daysDifference < 365) {
    const monthsDifference = parseInt(Math.abs(daysDifference / 31));
    return monthsDifference > 1 ? `${monthsDifference} Months ago` : `${monthsDifference} Month ago`;
  } else {
    const yearsDifference = parseInt(Math.abs(daysDifference / 365));
    return yearsDifference > 1 ? `${yearsDifference} Years ago` : `${yearsDifference} Year ago`;
  }
}



export function GetFormatedDate(date) {
  try {
    date = new Date(date);
  } catch (error) {
    return "";
  }

  const options = {
    weekday: "long",
    day: "numeric",
    month: "long",
    year: "numeric",
  };

  return date.toLocaleDateString("en-US", options);
}

export function TruncateText(text, maxLength) {
  if (!text) {
    return "";
  }
  if (text.length <= maxLength) {
    return text;
  }
  return `${text.substring(0, maxLength)}... `;
}
export function TruncateTextHtml(text, maxLength) {
  if (!text) {
    return "";
  }
  const parser = new DOMParser();
        const doc = parser.parseFromString(text, 'text/html');
        const textContent = doc.body.textContent;
  if (text.length <= maxLength) {
    return textContent;
  }
  return `${textContent.substring(0, maxLength)}... `;
}

export const GetApiRootPath = (rootPath, url) => {
  if (rootPath === "/podcast") {
    url = `/podcasts${url}`;
  } else if (rootPath === "/books") {
    url = `/books${url}`;
  } else if (rootPath === "/games") {
    url = `/games${url}`;
  }
  return url;
};

export const GetImageUrl = (data, photo_key) => {
  if (!Array.isArray(data)) {
    return "";
  }
  for (const element of data) {
    if (element.name === photo_key) {
      return element.photo_url;
    }
  }
};

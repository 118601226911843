import React from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";

import { FreeMode } from "swiper";
import StarIcon from '@mui/icons-material/Star';
import ShareIcon from '@mui/icons-material/Share';
import { Link } from 'react-router-dom';
import GenerateImageUrl from "../../utils/utils"
import { BrowserRouter as Router, useNavigate } from "react-router-dom";

function SimilarRecomended(props) {
    const navigate = useNavigate();

    const handleRedirect = (movieSlug) => {
        if (movieSlug) {
            navigate(`${props.rootPath}/details/${movieSlug}`);
        }
    };

    return (
        <div className='similar-recomended-section'>
            <div className='tag-title'>{props.title}</div>
            <Swiper
                slidesPerView={'auto'}
                // spaceBetween={54}
                freeMode={true}
                modules={[FreeMode]}
                breakpoints={{
                    1320: {
                        slidesPerView: 'auto',
                        spaceBetween: 38,
                    },
                    1024: {
                        slidesPerView: 'auto',
                        spaceBetween: 28,
                    },
                    768: {
                        slidesPerView: 'auto',
                        spaceBetween: 20,
                    },
                    320: {
                        slidesPerView: 'auto',
                        spaceBetween: 20,
                    }
                }}
                className="SimilarRecomendedSlider"
            >
                {props.moviesData.map((val, index) => (
                    <SwiperSlide key={index}>
                        <div className='item-card'>
                            <div className='item-image'>
                                <img src={GenerateImageUrl(val.image, 268, 335)} alt={val.image_alt} onClick={() => handleRedirect(val.slug)} />
                                <div className='item-meta'>
                                    {/* <button className='item-btn'><BookmarkIcon /></button> */}
                                    <button className='item-btn' onClick={() => props.handleShareBtnClick(`${props.rootPath}/details/${val.slug}`)}><ShareIcon /></button>
                                </div>
                            </div>
                            <div className='item-content'>
                                <h4 className='item-title text-white cursor-pointer' onClick={() => handleRedirect(val.slug)}>{props.rootPath==='/games'?val.name:val.title}</h4>
                                <div className='item-rating'><StarIcon /> {val.ebitsRatings}</div>
                            </div>
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    )
}

export default SimilarRecomended
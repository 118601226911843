import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { ListModuleData } from "../../api/module";
import GenerateImageUrl from "../../utils/utils";
import { useMediaQuery } from 'react-responsive'
import { useRef } from "react";
import { useDraggable } from "react-use-draggable-scroll";
var qs = require('qs');

function Index(props) {
    const [moduleData, setModuleData] = useState([]);
    const [selectedFilter, setSelectedFilter] = useState([props?.platformData?.[0]?.name]);
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' })
    const ref = useRef(); // We will use React useRef hook to reference the wrapping div:
    const { events } = useDraggable(ref); // Now we pass the reference to the useDraggable hook:
  
    useEffect(() => {
        setSelectedFilter([props?.platformData?.[0].name])
    }, [props.platformData]);


    useEffect(() => {
        let requestBody= {
                platforms: [...selectedFilter],
                offsetRange: { "offset": 0, "limit": 20 }
            };

        GetModuleData(requestBody)

    }, [selectedFilter]);

    const GetModuleData = (requestBody) => {
        ListModuleData(props.rootPath, requestBody)
            .then((response) => {
                let data = []
                if (props.rootPath === "/podcast") {
                    data = response.data.podcasts;
                } else if (props.rootPath === "/books") {
                    data = response.data.books;
                } else if (props.rootPath === "/games") {
                    data = response.data.games;
                } else if (props.rootPath === "/ott") {
                    data = response.data.movies;
                }

                setModuleData(data || []);
            })
            .catch((error) => {
                console.error(error);
            });
    }

    const movieFilterChange = (name) => {
        const isSelected = selectedFilter.includes(name);
        if (isSelected) {
            setSelectedFilter(selectedFilter?.filter((i) => i !== name));
        } else {
            setSelectedFilter([...selectedFilter, name]);

        }
    }

    if (!moduleData) {
        return <div>Loading...</div>;
    }

    return (
        <div className='explore-cooking-new-section'>
            <div className='title-section'>
                <div className='title'>
                    <h3>Explore whats cooking new and where</h3>
                </div>
                {isTabletOrMobile && <Link className='view-all-btn' to={{
                    pathname: `${props.rootPath}/content`,
                    search: qs.stringify({ platforms: selectedFilter }),
                }}>View All</Link>}
            </div>
            <div className='explore-filter-section'>
                <ul className='listing-fliter' 
                    {...events}
                    ref={ref} // add reference and events to the wrapping div 
                >
                    {props.platformData?.map((val, key) => {
                        return (
                            <li
                                className={`${selectedFilter.includes(val.name) ? 'selected' : ''}`}
                                key={key}
                                onClick={() => movieFilterChange(val.name)}
                            >{val.name}</li>
                        )
                    })}
                </ul>
                {!isTabletOrMobile && <Link className='view-all-btn' to={{
                    pathname: `${props.rootPath}/content`,
                    search: qs.stringify({ platforms: selectedFilter }),
                }}>View All</Link>}
            </div>
            <Swiper navigation={false} className="explore--slider" slidesPerView={'auto'} spaceBetween={18} loop={true}>
                {moduleData.map((val, key) => {
                    return (
                        <SwiperSlide key={key}>
                            <div className='explore-card' onClick={() => props.handleRedirect(val.slug)}>
                                <div className='explore-image'>
                                    <img src={GenerateImageUrl(val?.photos?.Cards_Explore_Whats_Cooking, 0, 0, false)} alt={''} />
                                </div>
                                <label className='tranding-postion'><span>{(parseInt(key) + 1) < 10 ? 0 + '' + (parseInt(key) + 1) : (parseInt(key) + 1)}</span> Trending</label>
                            </div>
                        </SwiperSlide>
                    )
                })}
            </Swiper>
        </div>
    )
}

export default Index
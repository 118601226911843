import React, { Fragment, useState, useEffect, useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Link } from "react-router-dom";
import { Autoplay } from "swiper";
import { GetReports } from "../../api/module";
import RadialbarChart from "../Charts/RadialBarChart";
import EQbitLineChart from "../Charts/LineChart";
import SpiderRadarChart from "../Charts/SpiderRadarChart";
import EQbitBarChart from "../Charts/barChart";
import { useMediaQuery } from "@mui/material";

function Index(props) {
  const [reportsData, setReportsData] = useState([]);
  const swiperRef = useRef(null);
  const isLaptop  = useMediaQuery('(max-width:1499px)')
  const isSmallLaptop  = useMediaQuery('(max-width:1280px)')
  const isTab  = useMediaQuery('(max-width:1024px)')
  const isPhone  = useMediaQuery('(max-width:768px)')
  const isSmallPhone  = useMediaQuery('(max-width:380px)')
  

  useEffect(() => {
    getReportsData();
  }, [props.rootPath]);

  const getReportsData = () => {
    GetReports(props.rootPath)
      .then((response) => {
        setReportsData(response.data.reports || []);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <Fragment>
      <div className={'info-graph-section'} onMouseEnter={() => swiperRef.current.swiper.autoplay.stop()}
      onMouseLeave={() => swiperRef.current.swiper.autoplay.start()}>
        <Swiper
          slidesPerView={'auto'}
          spaceBetween={24}
          autoHeight={true}
          pagination={{
            clickable: true
          }}
          autoplay={{
            delay: 10000
          }}
          freeMode={true}
          modules={[Autoplay]}
          ref={swiperRef}
          className="info-graph-slider"
        >
          {reportsData.map((item, index) => (
            <SwiperSlide key={index}>
              <div className={`info-graph-card ${item.chart.type === 'SpiderRadarChart'?'radar':''}`}>
                <div className={'info-graph-left'}>
                {!isPhone && <h2 className={'info-graph-title'}>{item.title}</h2>}
                <div className={'info-graph-content'}>
                    <span>{item.summary}</span>
                    <Link className={'btn-read-more'} to={`${props.rootPath}/info-graphics/${item.slug}`}>Read more</Link>
                  </div>
                </div>
                <div className={`info-graph-right ${item.chart.type === 'SpiderRadarChart'?'radar':''}`}>
                {isPhone && <h2 className={'info-graph-title'} style={{color:'white'}} >{item.title}</h2>}
                { item.chart.type === 'RadialBarChart' && <RadialbarChart data={item.chart.data} label={item.chart.label} height={isSmallPhone?"240":isTab?"250":isSmallLaptop?"250":isLaptop?"300":"400"} />}
                  { item.chart.type === 'BarChart' && <EQbitBarChart data={item.chart.data}  height={isSmallPhone?"240":isTab?"250":isSmallLaptop?"250":isLaptop?"300":"400"} />}
                  { item.chart.type === 'SpiderRadarChart' && <SpiderRadarChart data={item.chart.data}  height={isSmallPhone?"240":isTab?"250":isSmallLaptop?"250":isLaptop?"300":"400"}   outerRadius={isSmallPhone?"60":isPhone?"100":isTab?"70":isSmallLaptop?"80":isLaptop?"120":"160"} />}
                  { item.chart.type === 'LineChart' && <EQbitLineChart data={item.chart.data} height={isSmallPhone?"240":isTab?"250":isSmallLaptop?"250":isLaptop?"300":"400"} />}
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </Fragment>
  );
}

export default Index;
import React, { useState } from "react";
import banner from "../../images/auth-page-banner.webp";
import OtpInput from "react-otp-input";
import PopupMessage from "../../components/PopupMessage";
import { VerifyEmail, VerifyOTPEmail } from "../../api/auth";
import { useNavigate } from "react-router-dom";
import { useSignIn } from "react-auth-kit";

function OneTimePassword(props) {
  const navigate = useNavigate();
  const [otp, setOtp] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const queryParams = new URLSearchParams(window.location.search);
  const email = queryParams.get("email");
  const action = queryParams.get("action");
  const signIn = useSignIn();
  const [otpRequire, setOtpRequire] = useState(false)
  const [popUpData, setPopUpData] = useState({
    type:"error",
    message:"Error!",
    desc:"There is error while completing the action",
    buttonTitle:"Back",
  })

  
  const handleVerify = (event) => {
    event.preventDefault();
    if(!otp){
      setOtpRequire(true)
      return
    }

    let data = { email_address: decodeURIComponent(email), key: otp };
    if (action === "reset") {
      VerifyOTPEmail(data)
        .then((res) => {
          const currentDate = new Date();
          const futureDate = new Date(
            currentDate.getFullYear(),
            currentDate.getMonth() + 1,
            currentDate.getDate()
          );
  
          signIn({
            token: res.data.key,
            expiresIn: futureDate.getTime() - currentDate.getTime(),
            tokenType: "Token",
            authState: { name: res.data.user, email: res.data.email },
          });
          navigate(`/change-password`);
        })
        .catch((error) => {
          console.error(error);
          let message = error.response.data.detail;
          setPopUpData({
            type:"error",
            message:"Error!",
            desc: message,
            buttonTitle:"Close",
          }) 
          setShowPopup(true);
        });
    } else {
      VerifyEmail(data)
        .then((res) => {
          setPopUpData({
            type:"success",
            message:"Successful!",
            desc:"Your Account created Successfully.",
            buttonTitle:"Login",
          })
          setShowPopup(true);
        })
        .catch((error) => {
          let message = error.response.data.detail;
          setPopUpData({
            type:"error",
            message:"Error!",
            desc: message,
            buttonTitle:"Close",
          }) 
          console.log(error);
          setShowPopup(true);
        });
    }
  };

  const handleClose = () => {
    setShowPopup(false);
  };
  const backToLogin =()=>{
      navigate(`/login`);
      setShowPopup(false);
  }

  return (
    <section className="login-section">
      <div className="login-banner">
        <img className="login-image" src={banner} alt="login" />
      </div>
      <div className="login-form-container">
        <form className="login-form" onSubmit={handleVerify}>
          <div className="login-header">
            <h1 className="login-title">Enter OTP</h1>
            <p className="login-tagline">{`An Otp has been sent to ${email}`}</p>
          </div>
          <div className="form-input">
            <div className="from-group">
              <label htmlFor="">Enter OTP</label>
              <OtpInput
                containerStyle="otp-flex"
                inputStyle="otp-input"
                value={otp}
                onChange={setOtp}
                numInputs={5}
                renderSeparator={" "}
                renderInput={(props) => <input {...props} />}
              />
            </div>
            {otpRequire && <span className="error" style={{color:'#f80046'}}>Please Enter OTP</span>}

          </div>
          <button className="btn btn-primary" type="submit">
            Confirm
          </button>
        </form>
      </div>
      {showPopup && (
        <PopupMessage
          type={popUpData.type}
          message={popUpData.message}
          desc={popUpData.desc}
          buttonTitle={popUpData.buttonTitle}
          open={showPopup}
          handleClose={()=>setShowPopup(false)}
          handleSubmit={popUpData.buttonTitle === "Close"?handleClose:backToLogin}
        />
      )}{" "}
    </section>
  );
}

export default OneTimePassword;

import React, { useState, useEffect } from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import { Link, useNavigate } from 'react-router-dom';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import StarIcon from '@mui/icons-material/Star';
import { ListModuleData } from "../../api/module";
import GenerateImageUrl from "../../utils/utils"

// import required modules
import { Navigation } from "swiper";
import { useMediaQuery } from '@mui/material';
import VideoPlayer from '../VideoPlayer';
import PopupMessage from '../PopupMessage';
var qs = require('qs');

const BestInStory = (props) => {
    const [showSlider, setShowSlider] = useState(0);
    const [moviesData, setMoviesData] = useState([]);
    const isMobile  = useMediaQuery('(max-width:768px)')
    const navigate = useNavigate();
    const [videoUrl, setVideoUrl] = useState("");
    const [errorPopUp, seterrorPopUp] = useState(false);
    
    const handleClick = (movieSlug,key) => {
            setShowSlider(key)
    };
    useEffect(() => {
        setMoviesData([])

        let requestBody = {
            categories: ["Best in Story"],
            offsetRange: { "offset": 0, "limit": 20 }
        };

        ListModuleData(props.rootPath, requestBody)
            .then((response) => {
                let data = []
                if (props.rootPath === "/podcast") {
                    data = response.data.podcasts;
                } else if (props.rootPath === "/books") {
                    data = response.data.books;
                } else if (props.rootPath === "/games") {
                    data = response.data.games;
                } else if (props.rootPath === "/ott") {
                    data = response.data.movies;
                }
                setMoviesData(data || []);
            })
            .catch((error) => {
                console.error(error);
            });

    }, [props.rootPath]);

    if (!moviesData) {
        return <div>Loading...</div>;
    }
    const handleBtnAction = (trailers) => {
            if (trailers !== undefined && trailers.length > 0) {
                setVideoUrl(trailers[0])
            } else {
                console.log('No Trailer Available')
                seterrorPopUp(true)
            }
    }

    return (moviesData && moviesData.length > 0 && (
<>
        <div className='best-story-section'>
            <div className='best-story-container'>
                <div className='title-section'>
                    <h2 className='title'>{props.title}</h2>
                    <Link className='view-all-btn' to={{
                    pathname: `${props.rootPath}/content`,
                    search: qs.stringify({"categories": ["Best In Story"]}),
                }}>View All</Link>
                </div>
            </div>
            <Swiper
                slidesPerView={"auto"}
                spaceBetween={24}
                // autoplay={{
                //     delay: 5000,
                //     disableOnInteraction: false,
                //     pauseOnMouseEnter: true,
                // }}
                breakpoints={{ 320: { spaceBetween: 18, navigation: false }, 768: { spaceBetween: 18, navigation: false }, 1024: { spaceBetween: 24 } }}
                grabCursor={true}
                navigation={true}
                modules={[Autoplay, Navigation]}
                className="best-story-slider"
            >
                {moviesData.map((val, key) => {
                    return (
                        <SwiperSlide onClick={() => {handleClick(val.slug,key)}} key={key}>
                            <div className={`best-story-card ${showSlider === key ? 'active' : ''}`}>
                                <div className='best-story-img'>
                                    <img className='static' src={GenerateImageUrl(val?.photos?.Cards_Best_In_Story_Default, 0, 0, false)} alt={val.Title} loading="lazy" />
                                    <img className='gif-img' src={GenerateImageUrl(val?.photos?.Cards_Best_In_Story_On_Hover, 0, 0, false)} loading="lazy" alt={val.Title} key={key} />
                                    <div class="swiper-lazy-preloader"></div>
                                </div>
                                <div className='best-story-content'>
                                    <div className='best-story-rating'><StarIcon /> {val.ebitsRating}</div>
                                    <h4 className='best-story-title'>{val.Title}</h4>
                                    {val?.categories?.length !==0 &&<ul className='best-story-categories'>
                                        { val?.categories?.map((category, key) => {
                                            return (
                                                <li key={key}>{category}</li>
                                            )
                                        })}
                                    </ul>}
                                    <div className='btn-group'>
                                        <button className='btn btn-play' onClick={()=>{handleBtnAction(val.trailers)}}><PlayCircleIcon /> Play Trailer</button>
                                        <Link className='btn btn-blur' to={`${props.rootPath}/details/${val.slug}`}>View Details</Link>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                    )
                })}
            </Swiper>
        </div>
        {videoUrl && <VideoPlayer videoUrl={videoUrl} closeModal={() => setVideoUrl("")} />}
        {errorPopUp && <PopupMessage 
        type="error"
        message="No Trailer Available" 
        desc="Please wait for the trailer to be released" 
        buttonTitle="Okay"
        open={errorPopUp} 
        handleClose={() => seterrorPopUp(false)} />}
</>
    )
    )
}

export default BestInStory
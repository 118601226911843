import React, { Fragment, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { GetBlogsData } from "../../api/blog"
import EventSlider from '../EventSlider'
import Tabs from '../Tab/Tabs'



function Index(props) {
    const [blogsData, setBlogsData] = useState([]);
    const [blogTab, setBlogTab] = useState("articles");

    const tabLabelMap = {
        "Featured Content": "articles",
        "Events Buzz": "events",
        "Conversations": "interviews",
    }
    useEffect(() => {
        getBlogsData("articles");
    }, [props.rootPath])

    const onClickTab = (blog) => {
        var newValue = tabLabelMap[blog]
        setBlogTab(newValue)
        setBlogsData([])
        getBlogsData(newValue);
    };

    const getBlogsData = (blog) => {
        GetBlogsData(blog)
            .then((response) => {
                setBlogsData(response.data.result || []);
            })
            .catch((error) => {
                console.error(error);
            });
    }

    return (
        <Fragment>
            <div className='bloging-section'>
                <div className='title-section'>
                    <div className='title'>
                        <h3>Engage & Explore</h3>
                    </div>
                    <Link className='view-all-btn' to={`/blogs`} >View All</Link>
                </div>
                <Tabs onClickTab={onClickTab} >
                    <div label="Featured Content">
                        <EventSlider label="articles" blogsData={blogsData} blogTab={blogTab} setBlogsData={setBlogsData} handleShareBtnClick={props.handleShareBtnClick} />
                    </div>
                    <div label="Events Buzz" >
                        <EventSlider label="events" blogsData={blogsData} blogTab={blogTab} setBlogsData={setBlogsData} handleShareBtnClick={props.handleShareBtnClick} />
                    </div>
                    <div label="Conversations" >
                        <EventSlider label="interviews" blogsData={blogsData} blogTab={blogTab} setBlogsData={setBlogsData} handleShareBtnClick={props.handleShareBtnClick} />
                    </div>
                </Tabs>
            </div>
        </Fragment>
    )
}

export default Index
import React from 'react'
import successIcon from '../../images/icons/yes.svg';
import oppsIcon from '../../images/icons/caution.svg';
import errorIcon from '../../images/icons/cross.svg';
import { Box, Modal } from '@mui/material';
function TermsPopUP(props) {
    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        width:"100%",
        transform: "translate(-50%, -50%)",
        // minWidth: "500px",
        boxShadow: 24,
        p: 0,
      };
      const onSubmit=()=>{
        if(props.handleSubmit){
            props.handleSubmit();
        }   
        props.handleClose();
      }
    return (
        <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
            <div className='popup-backdrop'>
                <div className='popup-message-box'>
                    <div className='popup-content terms-and-policy'>
                            <h4 className='popup-desc heading'>Terms and Conditions of Use</h4>
                            <p className='popup-desc content'>
                            Last Updated: [22nd March 2024]<br/>
                            These terms and conditions govern your use of the EQbits website (the "Website") operated by Eqbits (“Company"). <br/>
                            By accessing or using the Website, you agree to be bound by these terms and conditions. If you disagree with any part of these terms, you may not access the Website.<br/>
                            <ol>
                                <li>Acceptance of Terms
                            By accessing or using the Website, you agree to comply with and be bound by these terms and conditions. If you do not agree to these terms, you are prohibited from using the Website.
                            </li>
                                <li>User Responsibilities
                            <br/>* You agree to use the Website only for lawful purposes and in accordance with these terms and conditions.
                            <br/>* You are responsible for maintaining the confidentiality of any account credentials used to access the Website.
                            <br/>* You agree not to engage in any activity that interferes with or disrupts the operation of the Website.
                            </li>
                                <li>Intellectual Property
                            <br/>* The Website and its original content, features, and functionality are owned by the Company and are protected by international copyright, trademark, patent, trade secret, and other intellectual property or proprietary rights laws.
                            <br/>* You may not modify, reproduce, distribute, transmit, display, publish, license, create derivative works from, or sell any information, software, products, or services obtained from the Website.
                            </li>
                                <li>Privacy Policy
                            <br/>* Your use of the Website is subject to our Privacy Policy, which explains how we collect, use, and disclose your personal information. By using the Website, you consent to the collection and use of your information as described in the Privacy Policy.
                            </li>
                                <li>Disclaimer of Warranties
                            <br/>* The Website is provided on an "as-is" and "as-available" basis, without any warranties of any kind, either express or implied.
                            <br/>* The Company does not warrant that the Website will be uninterrupted, error-free, secure, or free from viruses or other harmful components.
                            </li>
                                <li>Limitation of Liability
                            <br/>* In no event shall the Company, its directors, officers, employees, or agents be liable for any indirect, incidental, special, consequential, or punitive damages arising out of or relating to your use of the Website.
                            </li>
                                <li>Indemnification
                            <br/>* You agree to indemnify and hold harmless the Company, its affiliates, and their respective directors, officers, employees, and agents from and against any and all claims, liabilities, damages, losses, costs, expenses, or fees (including reasonable attorneys' fees) arising out of or relating to your use of the Website or violation of these terms and conditions.
                            </li>
                                <li>Governing Law
                            <br/>* These terms and conditions shall be governed by and construed in accordance with the laws of the Government Of Inidia, without regard to its conflict of law provisions.
                            </li>
                                <li>Changes to Terms
                            <br/>* The Company reserves the right to modify or update these terms and conditions at any time. Any changes will be effective immediately upon posting to the Website. Your continued use of the Website after any such changes constitutes acceptance of the modified terms and conditions.
                            </li>
                                <li>Contact Information
                            <br/>* If you have any questions about these terms and conditions, please contact us at support@eqbits.in.
                            </li>
                            </ol>
                            </p>
                        <button className='popup-btn' onClick={onSubmit} type='button'>ok</button>
                    </div>
                </div>
            </div>
        </Box>
      </Modal>
    )
}

export default TermsPopUP
import React, { useState, useEffect } from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCoverflow } from 'swiper';
import { Link } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import { GradientCircularProgress } from "react-circular-gradient-progress";
// icons
import BookmarkIcon from '@mui/icons-material/Bookmark';
import ShareIcon from '@mui/icons-material/Share';
// sample data
import { ListModuleData } from "../../api/module";
import GenerateImageUrl, {TruncateText} from "../../utils/utils"
import { useMediaQuery } from 'react-responsive';

function PlatformButtonComponant(props) {
    if (!props?.value || props.value.length <= 0) {
        return
    }
    let data = props.value[0]
    let buttonTtile = `Watch on ${data.name}`
    switch (props.module) {
        case '/games':
            buttonTtile = `Play on ${data.platform}`
            break;
        case '/podcast':
            buttonTtile = `Listen on ${data.platform}`
            break;
        case '/books':
            buttonTtile = `Read on ${data.platform}`
            break;
        default:
            buttonTtile = `Watch on ${data.platform}`
            break;
    }
    return <Link className='theme-btn-v2' to={data.platform_url} target="_blank" rel="noopener noreferrer">{buttonTtile}</Link>;
}


function Index(props) {
    const [moduleData, setModuleData] = useState([]);
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 999px)' })
    
    useEffect(() => {
        let requestBody = {
            categories: props.selectedFilterCategory && props.selectedFilterCategory.length > 0 ? props.selectedFilterCategory : ["Ebits_Treasure"],
            offsetRange: { "offset": 0, "limit": 20 }
        };
        ListModuleData(props.rootPath, requestBody)
            .then((response) => {
                let data = []
                if (props.rootPath === "/podcast") {
                    data = response.data.podcasts;
                } else if (props.rootPath === "/books") {
                    data = response.data.books;
                } else if (props.rootPath === "/games") {
                    data = response.data.games;
                } else if (props.rootPath === "/ott") {
                    data = response.data.movies;
                }

                setModuleData(data || []);
            })
            .catch((error) => {
                console.error(error);
            });


    }, [props.selectedFilterCategory, props.rootPath]);


    return (moduleData && moduleData.length > 0 && (
        <Swiper navigation={false}
            className="single-3d-slider"
            effect={'coverflow'}
            grabCursor={true}
            centeredSlides={true}
            loop={true}
            slidesPerView={'auto'}
            coverflowEffect={{
                rotate: 0,
                stretch: 0,
                depth: 200,
                modifier: 1.5,
                slideShadows: true,
            }}
            slideToClickedSlide={true}
            breakpoints={{
                // 320: {
                //     coverflowEffect: {
                //         rotate: 0,
                //         stretch: 0,
                //         depth: 0,
                //         modifier: 0,
                //         slideShadows: false,
                //     }, centeredSlides: false
                // }, 
                1024: { spaceBetween: 24 }
            }}
            modules={[EffectCoverflow]}>
            {moduleData.map((val, key) => {
                return (
                    <SwiperSlide key={key}>
                        <div className='single-3d-card' onClick={()=>{console.log(val)}}>
                            <div className='single-3d-image'>
                                <img src={GenerateImageUrl(isTabletOrMobile? val?.photos?.Cards_Explore_Whats_Cooking:val?.photos?.Cards_Ebits_Treasure, null, null, true)} alt={val.Title} />
                                <div className='single-3d-option'>
                                    <Link className='share-link' onClick={() => {props.handleShareBtnClick(`${props.rootPath}/details/${val.slug}`); console.log('ok')}}><ShareIcon /></Link>
                                    {/* <Link className='bookmark-link'><BookmarkIcon /></Link> */}
                                </div>
                            </div>
                            <div className='single-3d-info' onClick={() => props.handleRedirect(val.slug)}>
                                <div className='signle-3d-title-sec'>
                                    <h4 className='single-3d-title'>{val.Title?.split("-")[0]} <span className='year'>{val.ReleaseDate?.split("-")[0]}</span></h4>
                                    
                                </div>
                                <ul className='single-3d-categaries'>
                                    {val.genres && val.genres.slice(0,3).map((val, key) => {
                                        return (<li key={key}>{val}</li>)
                                    })}
                                </ul>
                                <div className='single-3d-contnet'>
                                                    {isTabletOrMobile &&
                                                    <>{val.Directors && (<h6 className='single-3d-meta'><span>Director : </span>{val.Directors}</h6>)}
                                                    {val.Actors && (<h6 className='single-3d-meta'><span>Actors : </span>{val.Actors}</h6>)}
                                                    <Link to={`${props.rootPath}/details/${val.slug}`} className='btn'>View details</Link>
                                                    </>
                                                    }
                                {!isTabletOrMobile &&    <div>
                                    <p className='single-3d-desc'>{TruncateText(val.description,250)}</p>
                                    {val.Directors && (<h6 className='single-3d-meta'><span>Director : </span>{val.Directors}</h6>)}
                                    {val.Actors && (<h6 className='single-3d-meta'><span>Actors : </span>{val.Actors}</h6>)}

                                            <div className='single-3d-rating'>
                                                <GradientCircularProgress
                                                    animate={true}
                                                    startColor="#FE316C"
                                                    middleColor="#ED2860"
                                                    endColor="#A5002F"
                                                    emptyColor="rgba(255, 255, 255, 0.5)"
                                                    size={75}
                                                    progress={val.ebitsRating * 10}
                                                />
                                                <span className='main-graph-rating'>{val.ebitsRating}</span>
                                                <h4 className='main-graph-title'>EQbits Ratings</h4>
                                            </div>
                                    </div>}
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>
                )
            })}
        </Swiper>
    )
    )
}

export default Index
import React from 'react'
import successIcon from '../../images/icons/yes.svg';
import oppsIcon from '../../images/icons/caution.svg';
import errorIcon from '../../images/icons/cross.svg';
import { Box, Modal } from '@mui/material';
function PrivacyPopUP(props) {
    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        width:"100%",
        transform: "translate(-50%, -50%)",
        // minWidth: "500px",
        boxShadow: 24,
        p: 0,
      };
      const onSubmit=()=>{
        if(props.handleSubmit){
            props.handleSubmit();
        }   
        props.handleClose();
      }
    return (
        <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
            <div className='popup-backdrop'>
                <div className='popup-message-box'>
                    <div className='popup-content terms-and-policy'>
                            <h4 className='popup-desc heading'>Privacy Policy</h4>
                            <p className='popup-desc content'>
                                Last Updated: [22nd March 2024]<br/>
                                EQbits ("Company," "we," or "us") operates the EQbits website (the "Website"). <br/>
                                This Privacy Policy informs you of our policies regarding the collection, use, and disclosure of personal data when you use our Website and the choices you have associated with that data.<br/>
                                <ol>
                                    <li>Information Collection and Use<br/>
                                * We collect several different types of information for various purposes to provide and improve our Website.<br/>
                                * Types of data collected may include personal information such as name, email address, and contact information, as well as usage data like IP address, browser type, and device information.
                                </li>
                                    <li>Use of Data<br/>
                                    * We use the collected data for various purposes, including:<br/>
                                    * To provide and maintain our Website;<br/>
                                    * To notify you about changes to our Website;<br/>
                                    * To provide customer support;<br/>
                                    * To gather analysis or valuable information so that we can improve our Website;<br/>
                                    * To monitor the usage of our Website;<br/>
                                    * To detect, prevent, and address technical issues.
                                </li>
                                    <li>Data Sharing and Disclosure<br/>
                                * We may share your personal information with service providers or third-party companies that perform services on our behalf.<br/>
                                * We may disclose your personal information if required to do so by law or in response to valid requests by public authorities.
                                </li>
                                    <li>Data Retention<br/>
                                * We will retain your personal data only for as long as is necessary for the purposes set out in this Privacy Policy.
                                </li>
                                    <li>Data Security<br/>
                                * The security of your data is important to us, but please remember that no method of transmission over the internet or method of electronic storage is 100% secure. While we strive to use available and acceptable means to protect your Personal Information, we cannot guarantee its absolute security.
                                </li>
                                </ol>
                                </p>
                        <button className='popup-btn' onClick={onSubmit} type='button'>ok</button>
                    </div>
                </div>
            </div>
        </Box>
      </Modal>
    )
}

export default PrivacyPopUP
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
// icons
import GenerateImageUrl from "../../utils/utils"
import StarIcon from '@mui/icons-material/Star';

function CollectionList(props) {
    const [rootPath, setRootPath] = useState('/ott');

    useEffect(() => {
        if (props.type === 'book'){
            setRootPath('/books');    
        }else if(props.type === 'podcast'){
            setRootPath('/podcast');
        }else if(props.type === 'game'){
            setRootPath('/games');
        }else if(props.type === 'movie'){
            setRootPath('/ott');
        }else if(props.rootPath){
            setRootPath(props.rootPath);
        }
    }, [props.rootPath,props.type])
      
    
    if (!props.show)
        return null;
    return (
        <div className="collection-list-wrapper" onClick={props.handleClose}>
            <div className='collection-list-card' onClick={(event)=>{event.stopPropagation();}}>
                <div className='collection-title'>
                    <h2 className='title'>{props.title}</h2>
                    <Link className='link' to={{pathname: `${rootPath}/collections/${props.collectionSlug}`,
                        }}>View All
                    </Link>
                </div>
                <ul className='collection-list'>
                    {props.list.map((item, key) => {
                        return <Card item={item} key={key} rootPath={rootPath}  />
                    })}
                </ul>
            </div>
        </div>
    )
}
function Card({item, key, rootPath}) {
    const [slug, setSlug] = useState('/');
    useEffect(() => {
        if (rootPath === '/books'){
            setSlug(item.book_slug);    
        }else if(rootPath === '/podcast'){
            setSlug(item.podcast_slug);
        }else if(rootPath === '/gamse'){
            setSlug(item.games_slug);
        }else if(rootPath === '/ott'){
            setSlug(item.movie_slug);
        }
    }, [rootPath])
    
    const navigate = useNavigate();

    const handleRedirect = (slug) => {
        if(slug){
            navigate(`${rootPath}/details/${slug}`);
        }else{
            alert("Details not available")
        }
    };
    return (
        <li className='list-item' key={key} onClick={() => handleRedirect(slug)}>
            <div className='item-img'>
                <img src={GenerateImageUrl(item.image, 94, 118, false)} alt={item.name} />
            </div>
            <div className='item-centent'>
                <button className='item-title' style={{
                    background:'none',
                    outline:'none',
                    border:'none',
                    textAlign:'left'
                }} 
                      onClick={() => handleRedirect(slug)} 
                      disabled={!slug || slug.trim() === ''} 
                    >{item.name}
                </button>
                <p className='item-rating'><StarIcon /> <span>{item.ebitsRatings}</span></p>
            </div>
        </li>
    )
}




export default CollectionList
import React, { useState } from "react";
import {
    Label,
    Line,
    LineChart,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis
} from "recharts";
import { chartcolors } from '../../utils/constant';
import { useMediaQuery } from "@mui/material";


export default function EQbitLineChart(props) {
    const [activeLabel, setactiveLabel] = useState(0);
    const isTab  = useMediaQuery('(max-width:1024px)')
    
    let labels = []
    for (let item in props.data[0]) {
        if (item !== "name") {
            labels.push(item)
        }
    }
    
    const CustomizedAxisTick = (props) => {
        const { x, y, payload } = props;
          const truncateLabel = (label, maxLength) => {
            return label.length > maxLength ? label.substring(0, maxLength) + '..' : label;
          };
        
          const truncatedValue = truncateLabel(payload.value, 6);
        return (
          <g transform={`translate(${x},${y})`} width={10}>
            <text className="xAxis-Label" x={10} y={25} dy={-20} dx={-20} width={4} fontSize={isTab?9:12} textAnchor="end" fill="#fff" transform="rotate(-45)">
            {truncatedValue}
            </text>
          </g>
        );
      };
    // Generate an array of random colors for each line
    const lineColors = props.data.map((_, key) => chartcolors[key].hex);
        let tempdata = [
            {
                name:"A",
                rating:5.5,
                story:6.6,
                direction:8
            },
            {
                name:"B shape",
                rating:4.5,
                story:9.6,
                direction:4
            },
            {
                name:"C Shape",
                rating:4.5,
                story:4.6,
                direction:7
            },
            {
                name:"A",
                rating:5.5,
                story:6.6,
                direction:8
            },
            {
                name:"B shape",
                rating:4.5,
                story:9.6,
                direction:4
            },
            {
                name:"C Shape",
                rating:4.5,
                story:4.6,
                direction:7
            },
            {
                name:"A",
                rating:5.5,
                story:6.6,
                direction:8
            },
            {
                name:"B shape",
                rating:4.5,
                story:9.6,
                direction:4
            },
            {
                name:"C Shape",
                rating:4.5,
                story:4.6,
                direction:7
            },
        ]
    return (
        <div className="line-chart">
            <div className="line-chart-container" style={{ height: props.height + 'px' || "400px", width:"100%"}}>
                <ResponsiveContainer>
                    <LineChart width={"100%"} margin={{left:0, right:20}} height={parseInt(props.height) || 300} data={props.data}>
                        {/* <CartesianGrid strokeDasharray="3 3" /> */}
                        <XAxis
                            dataKey="name"
                            // padding={{ left: 50, right: 50 }}
                            tick={<CustomizedAxisTick />}
                            height={80} // Adjust the height of the X-axis to accommodate the rotated labels
                            fill="#fff"
                            interval={0}
                            >
                            <Label fill="#fff" fontSize="12px" />
                        </XAxis>
                        <YAxis fill="#fff"></YAxis>
                        <Tooltip />
                        {labels.map((label, index) => (
                            <Line fill="#fff" type="monotone" dataKey={label} stroke={lineColors[index]} strokeWidth={activeLabel === label ? 3 : 1} key={index} />
                        ))}
                    </LineChart>
                </ResponsiveContainer>
            </div>
            <div className='about-meta-slider'>
                <ul className='chart-data-list'>
                    {labels.map((val, key) => {
                        return (
                            <li
                                onMouseEnter={() => setactiveLabel(val)}
                                onClick={() => setactiveLabel(val)}
                                key={key}
                                className={`${activeLabel === val ? 'active' : ''}`}
                                style={{ background: `${activeLabel === val ? lineColors[key] + '60' : ''}` }}
                                data-color={lineColors[key]}>
                                <span style={{ background: lineColors[key] }}></span><p>{val}</p>
                            </li>
                        )
                    })}
                </ul>
            </div>
        </div>
    );
}

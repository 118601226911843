import qs from 'qs';
import React, { useState, useEffect } from 'react';
import logo from '../../images/icons/logo-white.svg';
import { BookIcon, GameIcon, MovieIcon, PodCastIcon } from '../../images/icons/Icon';
import AddIcon from '@mui/icons-material/Add';
import { Link, useLocation } from 'react-router-dom';

const SideBar = ({ isTabletOrMobile, handleMenu, open }) => {
    const [activeLink, setActiveLink] = useState('/');
    const [activeLinkMobile, setActiveLinkMobile] = useState('/')
    const location = useLocation();
    const [showList, setshowList] = useState(false);


    useEffect(() => {
        updateActiveLink();

        // Clean up the effect when component unmounts
        return () => {
            // any cleanup code goes here
        };
    }, [location.pathname]); // Re-run effect when the pathname changes

    const updateActiveLink = () => {
        const { pathname } = location;

        if (pathname !== undefined) {
            let activeUrls = pathname.split('/');
            try {
                activeUrls = activeUrls[1];
                setActiveLink(`/${activeUrls}`);
            } catch (error) {
                setActiveLink(pathname);
            }
        }
    };

    const handleMenuClose = () => {
        handleMenu();
        handleMobileMenu();
        setshowList(false)
    };

    const handleClick = (link) => {
        if (!isTabletOrMobile) {
            window.scrollTo(0, 0);
            setActiveLink(link);
        }
    };

    const handleMobileMenu = (menu) => {

        if (activeLinkMobile !== menu) {
            setActiveLinkMobile(menu);
            setActiveLink(menu)
        } else {
            setActiveLinkMobile('/');
        }
    };

    return (
        <div className={`ebits-side-bar ${open ? 'show' : 'hide'}`}>
            <div className='ebits-side-logo'>
                <img className='logo' src={logo} alt='EQBITS' />
            </div>
            <ul className={`ebits-sidebar-menu`}>
                    <li className={`menu-item ${activeLink === '/ott' ? 'is-active' : ''} ${activeLinkMobile==='/ott'?'mobile':''}`}  
                        onClick={() => handleClick('/ott')}
                        onMouseMove={()=>{setshowList(true)}}
                        >
                        <div className='menu-item-main'>
                            <Link  onClick={()=>{handleMenuClose()}} className='menu-button' to={'/ott'}>
                                <MovieIcon />
                                <span>OTT</span>
                            </Link>
                            {isTabletOrMobile === true && <button className='mobile-menu-button' onClick={() => handleMobileMenu("/ott")}><AddIcon /></button>}
                        </div>
                        <div className={`menu-list-box ${showList?'':'hide'}`}>
                            <div className='menu-title'>
                                Movies and Series
                            </div>
                            <ul className='sidebar-menu-list'>
                                <li><Link  onClick={()=>{handleMenuClose()}} to={{
                                        pathname: `/ott/content`,
                                        search: qs.stringify({"categories": ["New Releases"]}),
                                    }}>New Realeses</Link></li>
                                {/* <li><Link  onClick={()=>{handleMenuClose()}} to={'/ott/top-rated'}>Top Rated</Link></li> */}
                                <li><Link  onClick={()=>{handleMenuClose()}} to={{
                                        pathname: `/ott/content`,
                                        search: qs.stringify({"categories": ["Popular"]}),
                                    }}>Most Popular</Link></li>
                                <li><Link  onClick={()=>{handleMenuClose()}} to={{
                                        pathname: `/ott/content`,
                                        search: qs.stringify({"categories": ["Recommended"]}),
                                    }}>Recommended</Link></li>
                                <li><Link  onClick={()=>{handleMenuClose()}} to={{
                                        pathname: `/ott/content`,
                                        search: qs.stringify({"genres": ["Crime", "Thriller", "Drama"]}),
                                    }}>Browse by Genre</Link></li>
                                {/* <li><Link>Browse by Cast</Link></li> */}
                                <li><Link  onClick={()=>{handleMenuClose()}} to={{
                                        pathname: `/ott/content`,
                                        search: qs.stringify({"moods": ["Inspiring", "Touching", "Heart-Breaking"]}),
                                    }}>Whats your mood</Link></li>
                            </ul>
                            <div className='divider'></div>
                            <ul className='sidebar-menu-list'>
                                {/* <li><Link>Old Classics</Link></li> */}
                                <li><Link  onClick={()=>{handleMenuClose()}} to={{
                                        pathname: `/ott/content`,
                                        search: qs.stringify({"categories": ["Hidden Treasure"]}),
                                    }}>Hidden Treasures</Link></li>
                                <li><Link  onClick={()=>{handleMenuClose()}} to={{
                                        pathname: `/ott/content`,
                                        search: qs.stringify({"categories": ["Kids Corner"]}),
                                    }}>Kids Corner</Link></li>
                                <li><Link  onClick={()=>{handleMenuClose()}} to={{ 
                                        pathname: `/ott/content`,
                                        search: qs.stringify({"platforms": ["Disney+Hotstar", "Hotstar", "Netflix"]}),
                                    }}>Streaming Platforms</Link></li>
                                {/* <li><Link  onClick={()=>{handleMenuClose()}} to={'/blogs'}>News & Buzz</Link></li> */}
                                <li><Link  onClick={()=>{handleMenuClose()}} to={'/about-us'}>About EQbits</Link></li>
                            </ul>
                        </div>
                    </li>
                    <li className={`menu-item ${activeLink === '/books' ? 'is-active' : ''} ${activeLinkMobile==='/books'?'mobile':''}`} 
                        onClick={() => handleClick('/books')}
                        onMouseMove={()=>{setshowList(true)}}
                        >
                        <div className='menu-item-main'>
                            <Link  onClick={()=>{handleMenuClose()}} className='menu-button' to={'/books'}>
                                <BookIcon />
                                <span>Books</span>
                            </Link>
                            {isTabletOrMobile === true && <button className='mobile-menu-button' onClick={() => handleMobileMenu("/books")}><AddIcon /></button>}
                        </div>
                        <div className={`menu-list-box ${showList?'':'hide'}`}>
                            <div className='menu-title'>
                                Books
                            </div>
                            <ul className='sidebar-menu-list'>
                                <li><Link  onClick={()=>{handleMenuClose()}} to={{
                                        pathname: `/books/content`,
                                        search: qs.stringify({"categories": ["New Releases"]}),
                                    }}>New Realeses</Link></li>
                                {/* <li><Link  onClick={()=>{handleMenuClose()}} to={'/ott/top-rated'}>Top Rated</Link></li> */}
                                <li><Link  onClick={()=>{handleMenuClose()}} to={{
                                        pathname: `/books/content`,
                                        search: qs.stringify({"categories": ["Popular"]}),
                                    }}>Most Popular</Link></li>
                                <li><Link  onClick={()=>{handleMenuClose()}} to={{
                                        pathname: `/books/content`,
                                        search: qs.stringify({"categories": ["Recommended"]}),
                                    }}>Recommended</Link></li>
                                <li><Link  onClick={()=>{handleMenuClose()}} to={{
                                        pathname: `/books/content`,
                                        search: qs.stringify({"genres": ["Fantasy", "Fiction", "Motivational"]}),
                                    }}>Browse by Genre</Link></li>
                                {/* <li><Link>Browse by Cast</Link></li> */}
                                <li><Link  onClick={()=>{handleMenuClose()}} to={{
                                        pathname: `/books/content`,
                                        search: qs.stringify({"moods": ["Adventurous", "Inspiring", "Romantic"]}),
                                    }}>Whats your mood</Link></li>
                            </ul>
                            <div className='divider'></div>
                            <ul className='sidebar-menu-list'>
                                {/* <li><Link>Old Classics</Link></li> */}
                                <li><Link  onClick={()=>{handleMenuClose()}} to={{
                                        pathname: `/books/content`,
                                        search: qs.stringify({"categories": ["Hidden Treasure"]}),
                                    }}>Hidden Treasures</Link></li>
                                <li><Link  onClick={()=>{handleMenuClose()}} to={{
                                        pathname: `/books/content`,
                                        search: qs.stringify({"categories": ["Kids Corner"]}),
                                    }}>Kids Corner</Link></li>
                                <li><Link  onClick={()=>{handleMenuClose()}} to={{
                                        pathname: `/books/content`,
                                        search: qs.stringify({"platforms": ["Audible", "Blankest", "Blankist"]}),
                                    }}>Streaming Platforms</Link></li>
                                {/* <li><Link  onClick={()=>{handleMenuClose()}} to={'/blogs'}>News & Buzz</Link></li> */}
                                <li><Link  onClick={()=>{handleMenuClose()}} to={'/about-us'}>About EQbits</Link></li>
                            </ul>
                        </div>
                    </li>
                    <li className={`menu-item ${activeLink === '/podcast' ? 'is-active' : ''} ${activeLinkMobile==='/podcast'?'mobile':''}`} 
                        onClick={() => handleClick('/podcast')}
                        onMouseMove={()=>{setshowList(true)}}
                        >
                        <div className='menu-item-main'>
                            <Link  onClick={()=>{handleMenuClose()}} className='menu-button' to={'/podcast'}>
                                <PodCastIcon />
                                <span>Podcast</span>
                            </Link>
                            {isTabletOrMobile === true && <button className='mobile-menu-button' onClick={() => handleMobileMenu("/podcast")}><AddIcon /></button>}
                        </div>
                        <div className={`menu-list-box ${showList?'':'hide'}`}>
                            <div className='menu-title'>
                                Podcast
                            </div>
                            <ul className='sidebar-menu-list'>
                                <li><Link  onClick={()=>{handleMenuClose()}} to={{
                                        pathname: `/podcast/content`,
                                        search: qs.stringify({"categories": ["New Releases"]}),
                                    }}>New Realeses</Link></li>
                                {/* <li><Link  onClick={()=>{handleMenuClose()}} to={'/podcast/top-rated'}>Top Rated</Link></li> */}
                                <li><Link  onClick={()=>{handleMenuClose()}} to={{
                                        pathname: `/podcast/content`,
                                        search: qs.stringify({"categories": ["Popular"]}),
                                    }}>Most Popular</Link></li>
                                <li><Link  onClick={()=>{handleMenuClose()}} to={{
                                        pathname: `/podcast/content`,
                                        search: qs.stringify({"categories": ["Recommended"]}),
                                    }}>Recommended</Link></li>
                                <li><Link  onClick={()=>{handleMenuClose()}} to={{
                                        pathname: `/podcast/content`,
                                        search: qs.stringify({"genres": ["Comedy", "Performing Arts", "True Crime"]}),
                                    }}>Browse by Genre</Link></li>
                                {/* <li><Link>Browse by Cast</Link></li> */}
                                <li><Link  onClick={()=>{handleMenuClose()}} to={{
                                        pathname: `/podcast/content`,
                                        search: qs.stringify({"moods": ["Comedy", "Crime", "informative"]}),
                                    }}>Whats your mood</Link></li>
                            </ul>
                            <div className='divider'></div>
                            <ul className='sidebar-menu-list'>
                                {/* <li><Link>Old Classics</Link></li> */}
                                <li><Link  onClick={()=>{handleMenuClose()}} to={{
                                        pathname: `/podcast/content`,
                                        search: qs.stringify({"categories": ["Hidden Treasure"]}),
                                    }}>Hidden Treasures</Link></li>
                                <li><Link  onClick={()=>{handleMenuClose()}} to={{
                                        pathname: `/podcast/content`,
                                        search: qs.stringify({"categories": ["Kids Corner"]}),
                                    }}>Kids Corner</Link></li>
                                <li><Link  onClick={()=>{handleMenuClose()}} to={{
                                        pathname: `/podcast/content`,
                                        search: qs.stringify({"platforms": ["Acast", "Apple Podcast", "Audible"]}),
                                    }}>Streaming Platforms</Link></li>
                                {/* <li><Link  onClick={()=>{handleMenuClose()}} to={'/blogs'}>News & Buzz</Link></li> */}
                                <li><Link  onClick={()=>{handleMenuClose()}} to={'/about-us'}>About EQbits</Link></li>
                            </ul>
                        </div>
                    </li>
                    <li className={`menu-item ${activeLink === '/games' ? 'is-active' : ''} ${activeLinkMobile==='/games'?'mobile':''}`} 
                        onClick={() => handleClick('/games')}
                        onMouseMove={()=>{setshowList(true)}}
                        >
                        <div className='menu-item-main'>
                            <Link  onClick={()=>{handleMenuClose()}} className='menu-button' to={'/games'}>
                                <GameIcon />
                                <span>Games</span>
                            </Link>
                            {isTabletOrMobile === true && <button className='mobile-menu-button' onClick={() => handleMobileMenu("/games")}><AddIcon /></button>}
                        </div>
                        <div className={`menu-list-box ${showList?'':'hide'}`}>
                            <div className='menu-title'>
                                Games
                            </div>
                            <ul className='sidebar-menu-list'>
                                <li><Link  onClick={()=>{handleMenuClose()}} to={{
                                        pathname: `/games/content`,
                                        search: qs.stringify({"categories": ["New Releases"]}),
                                    }}>New Realeses</Link></li>
                                {/* <li><Link  onClick={()=>{handleMenuClose()}} to={'/games/top-rated'}>Top Rated</Link></li> */}
                                <li><Link  onClick={()=>{handleMenuClose()}} to={{
                                        pathname: `/games/content`,
                                        search: qs.stringify({"categories": ["Popular"]}),
                                    }}>Most Popular</Link></li>
                                <li><Link  onClick={()=>{handleMenuClose()}} to={{
                                        pathname: `/games/content`,
                                        search: qs.stringify({"categories": ["Recommended"]}),
                                    }}>Recommended</Link></li>
                                <li><Link  onClick={()=>{handleMenuClose()}} to={{
                                        pathname: `/games/content`,
                                        search: qs.stringify({"genres": ["Adventure", "Puzzle", "Comedy"]}),
                                    }}>Browse by Genre</Link></li>
                                {/* <li><Link>Browse by Cast</Link></li> */}
                                <li><Link  onClick={()=>{handleMenuClose()}} to={{
                                        pathname: `/games/content`,
                                        search: qs.stringify({"moods": ["Fun", "City_Building", "Thrilling"]}),
                                    }}>Whats your mood</Link></li>
                            </ul>
                            <div className='divider'></div>
                            <ul className='sidebar-menu-list'>
                                {/* <li><Link>Old Classics</Link></li> */}
                                <li><Link  onClick={()=>{handleMenuClose()}} to={{
                                        pathname: `/games/content`,
                                        search: qs.stringify({"categories": ["Hidden Treasure"]}),
                                    }}>Hidden Treasures</Link></li>
                                <li><Link  onClick={()=>{handleMenuClose()}} to={{
                                        pathname: `/games/content`,
                                        search: qs.stringify({"categories": ["Kids Corner"]}),
                                    }}>Kids Corner</Link></li>
                                <li><Link  onClick={()=>{handleMenuClose()}} to={{
                                        pathname: `/games/content`,
                                        search: qs.stringify({"platforms": ["Android", "Gamevice", "HarmonyOS"]}),
                                    }}>Streaming Platforms</Link></li>
                                {/* <li><Link  onClick={()=>{handleMenuClose()}} to={'/blogs'}>News & Buzz</Link></li> */}
                                <li><Link  onClick={()=>{handleMenuClose()}} to={'/about-us'}>About EQbits</Link></li>
                            </ul>
                        </div>
                    </li>
                </ul>
        </div>
    );
};

export default SideBar;

import Slider from '@mui/material/Slider';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { GradientCircularProgress } from "react-circular-gradient-progress";
// icons
import AppsIcon from '@mui/icons-material/Apps';
import ShareIcon from '@mui/icons-material/Share';
import StarIcon from '@mui/icons-material/Star';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { GetCertificate, GetGeners, GetLabels, GetLanguage, GetMoods, GetPlatform } from "../../api/master";
import { ListModuleData } from "../../api/module";
import ShareDialog from "../../components/ShareModel";
import ListIcon from '../../images/icons/list-icon.svg';
import TimeIcon from '../../images/icons/timer';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import { RouteUrls } from "../../utils/constant";
import GenerateImageUrl, { TruncateText } from "../../utils/utils";
import Accordion from '../Accordion/Accordion';
import { useMediaQuery } from 'react-responsive'
import ClearIcon from '@mui/icons-material/Clear';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
var qs = require('qs');

const theme = createTheme({
    palette: {
        primary: {
            main: '#D21A4E',
            darker: '#D21A4E',
        }
    },
    typography: {
        fontFamily: [
            'Poppins'
        ]
    }
});

function GridListing(props) {
    const { slug: urlSlug } = useParams();
    const [ebits_rating, setEbitsRating] = useState([0, 10]);
    const [critic_rating, setCriticRating] = useState([0, 10]);
    const [shareDialogOpen, setShareDialogOpen] = React.useState(false);
    const [sharelink, setSharelink] = useState("");
    const [sortBy, setSortBy] = useState('')
    const [genersData, setGenersData] = useState([]);
    const [platformData, setPlatformData] = useState([]);
    const [languageData, setLanguageData] = useState([]);
    const [certificateData, setCertificateData] = useState([]);
    const [labelsData, setLabelsData] = useState([]);
    const [moodsData, setMoodsData] = useState([]);
    const [moviesData, setMoviesData] = useState([]);
    const [filterData, setFiterData] = useState({});
    const [totalEntries, setTotalEntries] = useState(0);
    const dataLimit = 10;
    const location = useLocation();
    const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });
    const [url, seturl] = useState(queryParams)    
    const [urlData, setUrlData] = useState(queryParams)
    const simpleQuery = new URLSearchParams(location.search);
    const layoutQuery = simpleQuery.get("layout");  
    const [layout, setlayout] = useState(layoutQuery?layoutQuery:'grid'); // grid / list
    const [showFilter, setShowFilter] = useState(false);
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 992px)' })
    
    useEffect(() => {
        GetGeners(props.rootPath)
            .then((response) => {
                if (props.rootPath === "/ott") {
                    setGenersData(response.data.geners || []);
                } else {
                    setGenersData(response.data.genres || []);
                }
            })
            .catch((error) => {
                console.error(error);
            });
        GetLabels(props.rootPath)
            .then((response) => {
                setLabelsData(response.data.categories || []);
            })
            .catch((error) => {
                console.error(error);
            });
        GetMoods(props.rootPath)
            .then((response) => {
                setMoodsData(response.data.moods || []);
            })
            .catch((error) => {
                console.error(error);
            });

        GetPlatform(props.rootPath)
            .then((response) => {
                setPlatformData(response.data.platforms || []);
            })
            .catch((error) => {
                console.error(error);
            });

        GetLanguage(props.rootPath)
            .then((response) => {
                setLanguageData(response.data.languages || []);
            })
            .catch((error) => {
                console.error(error);
            });

        GetCertificate(props.rootPath)
            .then((response) => {
                setCertificateData(response.data.certifications || []);
            })
            .catch((error) => {
                console.error(error);
            });
    }, [location.search, location]);

    useEffect(() => {
        window.scrollTo(0, 0);
        setMoviesData([])
        if (urlData?.ebitsRatingRange) {
            setEbitsRating([urlData.ebitsRatingRange.low, urlData.ebitsRatingRange.high])
        }
        if (urlData?.criticsRatingRange) {
            setCriticRating([urlData.criticsRatingRange.low, urlData.criticsRatingRange.high])
        }
        urlData.offsetRange = { "offset": 0, "limit": dataLimit }
        const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });
        // Initialize filterData with offsetRange
        const initialFilterData = {
            offsetRange: { offset: 0, limit: dataLimit },
            ...queryParams // Include any other query parameters as needed
        };
        setFiterData(initialFilterData)
        setUrlData(queryParams);
        seturl(queryParams)       
    }, [location]);

    useEffect(() => {
        getModuleData();
    }, [filterData]);

    const getNextModuleData = () => {
        if (!filterData || !filterData.offsetRange) {
            return; // Return if filterData or offsetRange is undefined or null
        }
        let filter = { ...filterData }
        if (Object?.keys(filter).length <= 0 || Object?.keys(filter?.offsetRange) <= 0) {
            return
        }

        if ((filterData.offsetRange.offset) + dataLimit <= totalEntries + dataLimit) {
            filter.offsetRange.offset = filterData.offsetRange.offset + dataLimit;
        }
        getModuleData(filter)
    }
    const getModuleData = () => {
        // if (Object?.keys(filterData) <= 0) {
        //     return
        // }
        ListModuleData(props.rootPath, filterData)
        .then((response) => {
                setTotalEntries(response.data.totalEntries || 0)
                let data
                if (props.rootPath === "/podcast") {
                    data = response.data.podcasts || [];
                } else if (props.rootPath === "/books") {
                    data = response.data.books || [];
                } else if (props.rootPath === "/games") {
                    data = response.data.games || [];
                } else if (props.rootPath === "/ott") {
                    data = response.data.movies || [];
                }
                setMoviesData([...moviesData, ...data]);
            })
            .catch((error) => {
                console.error(error);
            });
    }

    const navigate = useNavigate();

    const handleRedirect = (movieSlug) => {
        navigate(`${props.rootPath}/details/${movieSlug}`);
    };

    const handleEbitsChange = (e, value) => {
        const newFilterData = {
            ...filterData,
            "ebitsRatingRange": { "low": value[0], "high": value[1] },
            offsetRange: { "offset": 0, "limit": dataLimit }
        };
        setFiterData(newFilterData)
        setMoviesData([])
    }

    const handleCriticChange = (e, value) => {
        const newFilterData = {
            ...filterData,
            "criticsRatingRange": { "low": value[0], "high": value[1] },
            offsetRange: { "offset": 0, "limit": dataLimit }
        };
        setFiterData(newFilterData)
        setMoviesData([])
    }

    const handleFilter = (key, data) => {
        if ((data?.length <= 0 && !filterData[key])) {
            return
        }
        const newFilterData = {
            ...filterData,
            offsetRange: { "offset": 0, "limit": dataLimit }
        };
        newFilterData[key] = data
        setFiterData(newFilterData)
        setMoviesData([])
    }

    const handleMovieDataSort = (e) => {
        const sortBy = e.target.value;
        setSortBy(sortBy)
        
        const sorted = [...moviesData].sort((a, b) => {
            if (a[sortBy] < b[sortBy]) {
                return 1;
            }
            if (a[sortBy] > b[sortBy]) {
                return -1;
            }
            // If values are equal, you can add a secondary sorting criterion
            // For example, sorting by movie ID or any other unique identifier
            if (a.id < b.id) {
                return 1;
            }
            if (a.id > b.id) {
                return -1;
            }

            return 0;   
            });
            setMoviesData(sorted);
    };

    const handleShareBtnClick = (path) => {
        const url = `${window.location.protocol}//${window.location.host}${path}`;
        setShareDialogOpen(true);
        setSharelink(url);
    };

    const handleFilterbtn = () => {
        setShowFilter(false)
    }
    
    return (
        <Fragment>
            <div className='grid-list-section'>
                <div className={`sidebar-filter ${isTabletOrMobile && 'is-mobile'} ${showFilter && 'active'}`}>
                    <h4 className='filter-label'>Filter {isTabletOrMobile && <button className='filter-close' onClick={() => handleFilterbtn()}><ClearIcon /></button>}</h4>
                    <div className="accordion-list">
                        {labelsData.length && <Accordion header="Labels" content={labelsData} onChange={data => {
                            handleFilter("categories", data);
                        }} setFiterData={setFiterData} filterData={filterData?.categories} />}
                        {genersData.length && <Accordion header="Genre" content={genersData} onChange={data => {
                            handleFilter("genres", data);
                        }} setFiterData={setFiterData} filterData={filterData?.genres} />}
                        {moodsData.length && <Accordion header="Moods" content={moodsData} onChange={data => {
                            handleFilter("moods", data);
                        }} setFiterData={setFiterData} filterData={filterData?.moods} />}
                        {platformData.length && <Accordion header="Platforms" content={platformData} onChange={data => {
                            handleFilter("platforms", data);
                        }} setFiterData={setFiterData} filterData={filterData?.platforms} />}
                        {languageData.length && props.rootPath !== "/games" && <Accordion header="Languages" content={languageData} onChange={data => {
                            handleFilter("languages", data);
                        }} setFiterData={setFiterData} filterData={filterData?.languages} />}
                        {certificateData.length && props.rootPath === "/ott" && <Accordion header="Certificates" content={certificateData} onChange={data => {
                            handleFilter("certificates", data);
                        }} setFiterData={setFiterData} filterData={filterData?.certificates} />}
                    </div>
                    {isTabletOrMobile && <button className='btn-filter' onClick={() => handleFilterbtn()}>Apply Filters</button>}
                </div>
                <div className={`grid-listing ${isTabletOrMobile && 'is-mobile'}`}>
                    <ThemeProvider theme={theme}>
                        <div className='other-filters-grid'>
                            <div className='other-filters'>
                                {isTabletOrMobile && <button className='filter-btn' onClick={() => setShowFilter(true)}>Filter <svg focusable="false" aria-hidden="true" viewBox="0 0 24 24"><path d="M7 10l5 5 5-5z"></path></svg></button>}
                                <div className='filter-select'>
                                <FormControl fullWidth>
                                    <InputLabel id="sort-by-label">Sort By</InputLabel>
                                    <Select
                                        labelId="sort-by-label"
                                        id="sort-by"
                                        value={sortBy}
                                        className='select-sortBy'
                                        label="Age"
                                        onChange={handleMovieDataSort}
                                        style={{color:'#fff', fontSize:'14px',}}
                                    >
                                        <MenuItem value="ReleaseDate">Release date</MenuItem>
                                        <MenuItem value="ebitsRating">EQbits Ratings</MenuItem>
                                        <MenuItem value="criticsRating">Critics Ratings</MenuItem>
                                    </Select>
                                    </FormControl>
                                    {/* <select className='custom-select' onChange={(e) => handleMovieDataSort(e.target.value)}>
                                        <option>Sort By</option>
                                        <option value="ReleaseDate">Release date</option>
                                        <option value="ebitsRating">EQbits Ratings</option>
                                        <option value="criticsRating">Critics Ratings</option>
                                    </select> */}
                                    {/* <svg focusable="false" aria-hidden="true" viewBox="0 0 24 24"><path d="M7 10l5 5 5-5z"></path></svg> */}
                                </div>
                                {!isTabletOrMobile && 
                                    <div className="ratings">
                                        <div className='rating-box'>
                                            <label>EQbits Ratings</label>
                                            <Slider
                                                getAriaLabel={() => 'EQbits Ratings'}
                                                value={ebits_rating}
                                                onChangeCommitted={handleEbitsChange}
                                                onChange={(event, value) => setEbitsRating(value)}
                                                step={0.1}
                                                valueLabelDisplay="auto"
                                                min={0}
                                                max={10}
                                            />
                                        </div>
                                        <div className='rating-box'>
                                            <label>Critic Ratings</label>
                                            <Slider
                                                getAriaLabel={() => 'Critic Ratings'}
                                                value={critic_rating}
                                                onChangeCommitted={handleCriticChange}
                                                onChange={(event, value) => setCriticRating(value)}
                                                step={0.1}
                                                valueLabelDisplay="auto"
                                                min={0}
                                                max={10}
                                            />
                                        </div>
                                        </div>
                                }
                            </div>
                            <div className='other-filters'>
                                <div className='grid-layout-option'>
                                    <button onClick={() => setlayout('grid')} className={`btn-icon ${layout === 'grid' ? 'active' : ''}`}><AppsIcon /></button>
                                    <button onClick={() => setlayout('list')} className={`btn-icon ${layout === 'list' ? 'active' : ''}`}><img src={ListIcon} alt='list' /></button>
                                </div>
                            </div>
                        </div>
                        <div className='pagging-list'>
                            <div className='pagging-detail'>Showing {moviesData.length} results</div>
                        </div>
                        <InfiniteScroll
                            dataLength={moviesData.length}
                            next={getNextModuleData}
                            hasMore={true}
                            loader={<h4>Loading...</h4>}
                        >
                            {layout === 'grid' ?
                                <div className='grid-card'>
                                    {moviesData.map((val, key) => (
                                        <div className='item-card' key={key}>
                                            <div className='item-image'>
                                                <img src={GenerateImageUrl(val?.photos?.Cards_Listing_Page_Grid, 268, 335)} alt={val.image_alt} onClick={() => handleRedirect(val.slug)} />
                                                <div className='item-meta'>
                                                    <button className='item-btn' onClick={() => handleShareBtnClick(`${props.rootPath}/details/${val.slug}`)}><ShareIcon /></button>
                                                </div>
                                            </div>
                                            <div className='item-content'>
                                                <h4 className='item-title'><Link to={`${props.rootPath}/details/${val.slug}`}>{TruncateText(val.Title,30)}</Link></h4>
                                                <div className='item-rating'><StarIcon /> {val.ebitsRating}</div>
                                                </div>
                                        </div>
                                    ))}
                                </div> : 
                                <div className='list-card'>
                                    {moviesData.map((val, key) => (
                                        <ListCard handleRedirect={handleRedirect} props={props} key={key} val={val} handleShareBtnClick={handleShareBtnClick} isTabletOrMobile={isTabletOrMobile} />
                                    ))}
                                </div>}
                        </InfiniteScroll>
                      
                    </ThemeProvider>
                </div>
            </div>
            <ShareDialog
                selectedValue={sharelink}
                open={shareDialogOpen}
                onClose={() => {
                    setShareDialogOpen(false);
                    setSharelink("");
                }}
            />
        </Fragment>
    )
}
const ListCard=({val, key, props, handleRedirect, isTabletOrMobile, handleShareBtnClick})=>{
        const [isOverflow, setIsOverflow] = useState();
        const [readMore, setReadMore] = useState(false)
        const paragraphRef = useRef(null);

        useEffect(() => {
        const isParagraphOverflowed = () => {
        if (paragraphRef.current) {
            setIsOverflow(paragraphRef.current.scrollHeight > paragraphRef.current.clientHeight);
        }
        };
        isParagraphOverflowed();
        }, [val.description]);
          
        return <div className='list-item'>
        <div className='list-img'>
            <img src={GenerateImageUrl(val?.photos?.Cards_Listing_Page_List, 268, 335)} alt={val.image_alt} onClick={() => handleRedirect(val.slug)} />
        </div>
        <div className='list-content'>
            <h4 className='item-title'><Link to={`${props.rootPath}/details/${val.slug}`}>{val.Title}</Link> <span>({val?.ReleaseDate && val?.ReleaseDate?.split("-")[0]})</span></h4>
            {
                props.rootPath ==="/ott"?
                val.duration && <h6 className='item-timer'><TimeIcon /> <span>{val.duration} {val.isSeries==1?'episodes':'minutes'}</span></h6>:
                props.rootPath ==="/books"?
                val.Pages && <h6 className='item-timer'><AutoStoriesIcon /> <span>{val.Pages} Pages</span></h6>:
                props.rootPath ==="/podcast"?
                val.duration && <h6 className='item-timer'><TimeIcon /> <span>{val.duration} mins</span></h6>:<></>
                
            }
            <div className="item-desc-wrapper">
            <p ref={paragraphRef} className={`item-desc ${readMore===key?'':'truncate'}`} title={val.description}>{val.description}</p>
            {val.description && isOverflow && <span className='read-more' onClick={()=>{setReadMore(readMore===key?null:key)}}> {isOverflow}{readMore===key?'Read Less': 'Read More'}</span>}
            </div>
            <div >
                {props.rootPath === "/ott" &&
                    <div className='item-others'>
                        <div className='item-highlight' title={val.Directors}><span>Directors :</span> {val.Directors?.split(',').slice(0, 2).join(',')}</div>
                        <div className='item-highlight' title={val.Actors}><span>Actors :</span> {val.Actors?.split(',').slice(0, 2).join(',')} </div>
                    </div>
                }
                {props.rootPath === "/games" &&
                    <div className='item-others'>
                        <div className='item-highlight' title={val.Developer}><span>Developer :</span> {val.Developer?.split(',').slice(0, 2).join(',')}</div>
                        <div className='item-highlight' title={val.Provider}><span>Provider :</span> {val.Provider?.split(',').slice(0, 2).join(',')} </div>
                    </div>
                }
                {props.rootPath === "/books" &&
                    <div className='item-others'>
                        <div className='item-highlight' title={val.Author}><span>Author :</span> {val.Author?.split(',').slice(0, 2).join(',')}</div>
                    </div>
                }
                {props.rootPath === "/podcast" &&
                    <div className='item-others'>
                        <div className='item-highlight' title={val.Podcaster}><span>Podcaster :</span> {val.Podcaster?.split(',').slice(0, 2).join(',')}</div>
                    </div>
                }
            </div>
            {isTabletOrMobile && <button className='item-btn' onClick={() => handleShareBtnClick(`${props.rootPath}/details/${val.slug}`)}><ShareIcon /> {isTabletOrMobile && ' Share'}</button>}
        </div>
        <div className='list-meta'>
            {!isTabletOrMobile && <div className='list-action'>
                <button className='item-btn' onClick={() => handleShareBtnClick(`${props.rootPath}/details/${val.slug}`)}><ShareIcon /></button>
            </div>}
            <div className='list-rating'>
                <div className='rating-graph'>
                    <div className='graph'>
                        <GradientCircularProgress
                            animate={true}
                            startColor="#FE316C"
                            middleColor="#ED2860"
                            endColor="#A5002F"
                            emptyColor="rgba(255, 255, 255, 0.5)"
                            size={30}
                            progress={val.ebitsRating * 10}
                        />
                    </div>
                    <h6 className='rate'>{val.ebitsRating}</h6>
                    <p className='rate-title'>EQbits Ratings</p>
                </div>
                <div className='rating-graph'>
                    <div className='graph'>
                        <GradientCircularProgress
                            animate={true}
                            startColor="#FE316C"
                            middleColor="#ED2860"
                            endColor="#A5002F"
                            emptyColor="rgba(255, 255, 255, 0.5)"
                            size={30}
                            progress={val.criticsRating * 10}
                        />
                    </div>
                    <h6 className='rate'>{val.criticsRating}</h6>
                    <p className='rate-title'>Critic Ratings</p>
                </div>
            </div>
        </div>
    </div>
    }

export default GridListing
import * as React from 'react';
import PropTypes from 'prop-types';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Grid from '@mui/material/Grid';

import {
    EmailShareButton,
    FacebookShareButton,
    FacebookMessengerShareButton,
    LinkedinShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    FacebookIcon,
    WhatsappIcon,
    FacebookMessengerIcon,
    LinkedinIcon,
    TwitterIcon,
    EmailIcon,
} from "react-share";


function ShareDialog(props) {
    const { onClose, selectedValue, open } = props;
    const handleClose = () => {
        onClose();
    };

    return (
        <Dialog onClose={handleClose} open={open} className='share-dialog'>
            <DialogTitle className='share-title'>Share Link via</DialogTitle>
            <button className='share-btn-close' onClick={handleClose}>
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                    <rect width="30" height="30" rx="15" fill="#111A29" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M20.8925 8.30249C20.7057 8.11523 20.452 8.01 20.1875 8.01C19.923 8.01 19.6693 8.11523 19.4825 8.30249L14.5925 13.1825L9.7025 8.29249C9.51567 8.10523 9.26202 8 8.9975 8C8.73298 8 8.47933 8.10523 8.2925 8.29249C7.9025 8.68249 7.9025 9.31249 8.2925 9.70249L13.1825 14.5925L8.2925 19.4825C7.9025 19.8725 7.9025 20.5025 8.2925 20.8925C8.6825 21.2825 9.3125 21.2825 9.7025 20.8925L14.5925 16.0025L19.4825 20.8925C19.8725 21.2825 20.5025 21.2825 20.8925 20.8925C21.2825 20.5025 21.2825 19.8725 20.8925 19.4825L16.0025 14.5925L20.8925 9.70249C21.2725 9.32249 21.2725 8.68249 20.8925 8.30249Z" fill="#C3CAD9" />
                </svg>
            </button>
            <Grid container spacing={0} className='share-content'>
                <Grid item xs>
                    <FacebookShareButton url={selectedValue} title='Share on Facebook'><FacebookIcon size={44} round={true} /></FacebookShareButton>
                </Grid>
                <Grid item xs>
                    <FacebookMessengerShareButton url={selectedValue}><FacebookMessengerIcon size={44} round={true} /></FacebookMessengerShareButton>
                </Grid>
                <Grid item xs>
                    <WhatsappShareButton url={selectedValue}><WhatsappIcon size={44} round={true} /></WhatsappShareButton>
                </Grid>
                <Grid item xs>
                    <EmailShareButton url={selectedValue}><EmailIcon size={44} round={true} /></EmailShareButton>
                </Grid>
                <Grid item xs>
                    <LinkedinShareButton url={selectedValue}><LinkedinIcon size={44} round={true} /></LinkedinShareButton>
                </Grid>
                <Grid item xs>
                    <TwitterShareButton url={selectedValue}><TwitterIcon size={44} round={true} /></TwitterShareButton>
                </Grid>
            </Grid>
            <DialogTitle className='share-title'>Or copy link</DialogTitle>
            <div className='share-link-input'>
                <input className='share-input' value={selectedValue} readOnly />
                <CopyToClipboard text={selectedValue} onCopy={() => handleClose()}>
                    <button type='button' className='share-btn'>Copy</button>
                </CopyToClipboard>
            </div>
        </Dialog>
    );
}

ShareDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    selectedValue: PropTypes.string.isRequired,
};

export default ShareDialog;
import React from 'react'
import successIcon from '../../images/icons/yes.svg';
import oppsIcon from '../../images/icons/caution.svg';
import errorIcon from '../../images/icons/cross.svg';
import { Box, Modal } from '@mui/material';
function PopupMessage(props) {
    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        width:"100%",
        transform: "translate(-50%, -50%)",
        // minWidth: "500px",
        boxShadow: 24,
        p: 0,
      };
      const onSubmit=()=>{
        if(props.handleSubmit){
            props.handleSubmit();
        }   
        props.handleClose();
      }
    return (
        <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
            <div className='popup-backdrop'>
                <div className='popup-message-box'>
                    <div className='popup-icon'>
                        {props.type === 'success' &&
                            <img src={successIcon} alt='success' />
                        }
                        {props.type === 'opps' &&
                            <img src={oppsIcon} alt='Opps' />
                        }
                        {props.type === 'error' &&
                            <img src={errorIcon} alt='Error' onClick={()=>{props.handleClose()}} />
                        }
                        <h2 className='popup-msg'>{props.message}</h2>
                    </div>
                    <div className='popup-content'>
                        {props.desc.constructor.name == "Object" ? (
                            <h4 className='popup-desc'>There is error while completing the action</h4>
                        ): (
                            <h4 className='popup-desc'>{props.desc}</h4>
                        )
                        }
                        <button className='popup-btn' onClick={onSubmit} type='button'>{props.buttonTitle}</button>
                    </div>
                </div>
            </div>
        </Box>
      </Modal>
    )
}

export default PopupMessage
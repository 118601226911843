import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import HeroBanner from "../components/HeroBanner";
import Navbar from "../components/Navbar";

import Grid from "@mui/material/Grid";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import PropTypes from "prop-types";

import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CircularProgress from '@mui/material/CircularProgress';
import StarIcon from "@mui/icons-material/Star";
import StarRateIcon from "@mui/icons-material/StarRate";
import ThumbDownOffAltIcon from "@mui/icons-material/ThumbDownOffAlt";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import Rating from "@mui/material/Rating";
import { useParams } from "react-router-dom";
import {
  AddComment,
  AddCommentsDisLikes,
  AddCommentsLikes,
  GetModuleDetail,
  GetSimilarByGenre,
} from "../api/module";
import ReviewCardSlider from "../components/ReviewCardSlider";
import SimilarRecomended from "../components/SimilarAndRecomended";
import { useNavigate } from "react-router-dom";
import Footer from "../components/Footer";
import ShareDialog from "../components/ShareModel";
import GenerateImageUrl, { GetPastDaysFromNow, TruncateText } from "../utils/utils";
import { useLocation } from 'react-router-dom';
import { useAuthUser, useIsAuthenticated } from "react-auth-kit";
import PopupMessage from "../components/PopupMessage";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  width:"100%",
  transform: "translate(-50%, -50%)",
  maxWidth: "500px",
  boxShadow: 24,
p: 0,
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div className="tab-content">{children}</div>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function OttDetail(props) {
  const { slug: movieSlug } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [value, setValue] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [openRating, setOpenRating] = React.useState(false);
  const [reviewTextLength, setReviewTextLength] = useState(250);
  const [storyLineTextLength, setStoryLineTextLength] = useState(250);
  const [sortBy, setSortBy] = useState('')
  const handleReview = () => setOpen(!open);
  const handleRating = () => setOpenRating(!openRating);
  const [user_rating, setUserRating] = useState(5.0);
  const [user_ratingHover, setUserRatingHover] = useState();
  const [showuser_ratingHover, setshowuser_ratingHover] = useState(false)
  const [moviesData, setMoviesData] = useState({});
  const [genrerMoviesData, setGenrerMoviesData] = useState([]);
  const [recommendedMoviesData, setRecommendedMoviesData] = useState([]);
  const [shareDialogOpen, setShareDialogOpen] = React.useState(false);
  const [sharelink, setSharelink] = useState("");
  const isAuthenticated = useIsAuthenticated();
  const auth = useAuthUser();
  const [reviewInput, setReviewInput] = useState("");
const [showPopup, setshowPopup] = useState(false)
const [popUp, setPopUp] = useState({
  type:"error",
  message:"Authentication Failed!",
  desc:"Please log in to your account.",
  handleLogin:true
})
  const handleSubmit = (event) => {
    event.preventDefault();
    if (!isAuthenticated || !auth() ){
       setshowPopup(true)
       setOpen(false);
      setReviewInput("")
      setOpenRating(false);
      setPopUp({
        type:"error",
        message:"Authentication Failed!",
        desc:"Please log in to your account.",
        handleLogin:true
      })
      return
    }
    const data = new FormData(event.target); // get the form data
    const formDataObj = Object.fromEntries(data.entries());
    if (formDataObj?.review && formDataObj.review.length <= 0) {
      return;
    }
    let requestData = {
      slug: moviesData.slug,
      author: auth().name,
      ratings: formDataObj.ratings,
    };
    if (formDataObj?.review) {
      requestData.title = "Test";
      requestData.review = formDataObj.review;
    }

    event.target.reset();

    AddComment(props.rootPath, requestData)
      .then((response) => {
        if (response.status === 200) {
          setPopUp({
            type:"success",
            message:"Success!",
            desc:"Your Review Has been added succesfully.",
            handleLogin:false
          })
          setshowPopup(true)
          GetMoviesData(movieSlug);
          setOpen(false);
          setReviewInput("")
          setOpenRating(false);
        }
      })
      .catch((error) => {
        console.error(error);
        setPopUp({
          type:"error",
          message:"Something Went Wrong!",
          desc:error,
          handleLogin:false
        })
        
        setOpen(false);
        setReviewInput("")
        setOpenRating(false);
        setshowPopup(true)
      });
  };

  const GetMoviesData = (movieSlug) => {
    setMoviesData({});
    GetModuleDetail(props.rootPath, movieSlug)
      .then((response) => {
        setMoviesData(response.data || {});
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const UpdateCommentsLikes = (index, commentSlug) => {
    if (!isAuthenticated || !auth()) {
      setshowPopup(true);
      return
    }
    moviesData.userReviews[index].likes =
      moviesData.userReviews[index].likes + 1;
    let newMoviesData = { ...moviesData };
    
    AddCommentsLikes(props.rootPath, { slug: commentSlug })
    .then((response) => {
        setMoviesData(newMoviesData);
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          console.log(error)
        }
        console.error(error);
      });
  };

  const UpdateCommentsDisLikes = (index, commentSlug) => {
    if (!isAuthenticated || !auth()) {
      setshowPopup(true);
      return
    }
    moviesData.userReviews[index].dislikes =
      moviesData.userReviews[index].dislikes + 1;
    let newMoviesData = { ...moviesData };
    setMoviesData(newMoviesData);

    AddCommentsDisLikes(props.rootPath, { slug: commentSlug })
      .then((response) => {})
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    GetMoviesData(movieSlug);

    GetSimilarByGenre(props.rootPath, movieSlug)
      .then((response) => {
        setGenrerMoviesData(response || []);
      })
      .catch((error) => {
        console.error(error);
      });
      GetSimilarByGenre(props.rootPath, movieSlug)
      .then((response) => {
        setRecommendedMoviesData(response || []);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [movieSlug, props.rootPath]);


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleReviewDataSort = (field, sortBy) => {
    setSortBy(sortBy);
    if(sortBy==='ratings'){

    }
    const sorted = [...moviesData[field]].sort((a, b) => {
      if (a[sortBy] < b[sortBy]) {
        return 1;
      }
      if (a[sortBy] > b[sortBy]) {
        return -1;
      }
      return 0;
    });
    let sortedReviewData = { ...moviesData };
    sortedReviewData[field] = sorted;
    setMoviesData(sortedReviewData);
  };

  const handleShareBtnClick = (path) => {
    const url = `${window.location.protocol}//${window.location.host}${path}`;
    setShareDialogOpen(true);
    setSharelink(url);
  };

  return (
    Object.keys(moviesData).length > 0 ? (
      <Fragment>
        <Navbar />
        <section className="ott-detail-section">
          <HeroBanner
            handleReview={() => handleReview()}
            handleRating={() => handleRating()}
            moviesData={moviesData}
            rootPath={props.rootPath}
            handleShareBtnClick={handleShareBtnClick}
          />
          <div className="ott-content">
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="OTT Content Tabs"
            >
              <Tab label="Overview" {...a11yProps(0)} />
              <Tab label="Reviews & Ratings" {...a11yProps(1)} />
              {/* <Tab label="Trailers & Photos" {...a11yProps(2)} /> */}
            </Tabs>
            <TabPanel className="tab-container" value={value} index={0}>
              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                <Grid item={true} lg={6} xs={12}>
                  <h4 className="tab-content-head">Overview</h4>
                  {(moviesData.overview.realeaseDate || moviesData.overview.releaseDate || moviesData.overview.publishDate) &&
                  
                  <div className="tab-list-content">
                    <p className="title">{props.rootPath !== "/books" ? "Release Date :" : "Publish Date :"}</p>
                    <p className="content">
                      {moviesData.overview.realeaseDate || moviesData.overview.releaseDate || moviesData.overview.publishDate}
                    </p>
                  </div>
                  }
                  {props.rootPath !== "/games" && (
                        moviesData.overview.language.length >0 && 
                    <div className="tab-list-content">
                      <p className="title">Language :</p>
                      <p className="content">
                        {moviesData.overview.language.join(" | ")}
                      </p>
                    </div>
                        
                  )}
                  {moviesData.genres.length>0 && <div className="tab-list-content">
                    <p className="title">Genre :</p>
                    <p className="content">{moviesData.genres.join(" | ")}</p>
                  </div>}
                  {moviesData?.overview?.author && <div className="tab-list-content">
                    <p className="title">Author :</p>
                    <p className="content">{moviesData.overview.author}</p>
                  </div>}
                  {moviesData?.pages && <div className="tab-list-content">
                    <p className="title">No of Pages :</p>
                    <p className="content">{moviesData.pages}</p>
                  </div>}
                  {moviesData?.mode && <div className="tab-list-content">
                    <p className="title">Mode :</p>
                    <p className="content">{moviesData.mode}</p>
                  </div>}
                  {moviesData?.overview?.podcaster && <div className="tab-list-content">
                    <p className="title">Host :</p>
                    <p className="content">{moviesData.overview?.podcaster}</p>
                  </div>}
                  {moviesData.overview.awards.length>0 &&<div className="tab-list-content">
                    <p className="title">Awards :</p>
                    <div className="">
                    {moviesData.overview.awards.map((item, index) => (
                      <p className="content award-list" key={index}>
                        {item.awardName} {item.awardFor ? " - " : ""}{" "}
                        {item.awardFor}
                        {index < moviesData.overview.awards.length -1 ? " ," : ""}
                      </p>
                    ))}
                    </div>
                  </div>}
                  {moviesData.certifications.length>0 && <div className="tab-list-content">
                    <p className="title">Censor Certification :</p>
                    <p className="content">
                      {moviesData.certifications.join(" | ")}
                    </p>
                  </div>}
                  {moviesData.overview.storyline &&<div className="tab-list-content">
                    <p className="title">Storyline :</p>
                    <p className="content-desc">
                      {TruncateText(
                        moviesData.overview.storyline,
                        storyLineTextLength
                      ) + " "}
                      {moviesData.overview.storyline &&
                        moviesData.overview.storyline.length > 250 &&
                        storyLineTextLength <= 250 && (
                          <button
                            className="read-more"
                            onClick={() => setStoryLineTextLength(50000)}
                          >
                            Read Detail Description
                          </button>
                        )}
                      {moviesData.overview.storyline &&
                        moviesData.overview.storyline.length > 250 &&
                        storyLineTextLength === 50000 && (
                          <button
                            className="read-more"
                            onClick={() => setStoryLineTextLength(250)}
                          >
                            Read less
                          </button>
                        )}
                    </p>
                  </div>}
                </Grid>
                <Grid item md={6} xs={12}>
                  <h4 className="tab-content-head">
                    {props.rootPath === "/ott" ? "Where to Watch" : null}
                    {props.rootPath === "/books" ? "Where to Read" : null}
                    {props.rootPath === "/podcast" ? "Where to Listen" : null}
                    {props.rootPath === "/games" ? "Where to Play" : null}
                  </h4>
                  <div className="tab-logo-list">
                    {
                      moviesData.overview.platforms.length>0?
                      moviesData.overview.platforms.map((item, index) => (
                        <>
                         {
                          item.platform_url && <div className="logo-box" key={index}>
                          <Link
                            to={item?.platform_url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              src={GenerateImageUrl(item?.url, 260, 92)}
                              alt={item?.name}
                            />
                          </Link>
                        </div>
                         }
                        </>
                      )):
                      <p className="platform-placeholder">No Platforms listed</p>
                    }
                  </div>
                  <h4 className="tab-content-head">EQbits Review</h4>
                  <div className="tab-paragraph-content">
                    {moviesData.overview.ebitsReview ?TruncateText(moviesData.overview.ebitsReview,reviewTextLength) + " ": 'Review Not Available'}
                    {moviesData.overview.ebitsReview && moviesData.overview.ebitsReview.length > 250 && reviewTextLength <= 250 && (
                        <a onClick={() => setReviewTextLength(50000)}>
                          Read More
                        </a>
                      )}
                    {moviesData.overview.ebitsReview &&
                      moviesData.overview.ebitsReview.length > 250 &&
                      reviewTextLength === 50000 && (
                        <a onClick={() => setReviewTextLength(250)}>
                          Read less
                        </a>
                      )}
                  </div>
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel className="tab-container" value={value} index={1}>
              <div className="review-ration-section">
                {moviesData.criticReviews.length>0 &&<div className="critic-review">
                  <div className="review-title-section">
                    <div className="tag-title">
                      Critic Reviews ({moviesData.criticReviews.length})
                    </div>
                    <div className="filter-select">
                    <FormControl fullWidth className="custom-select">
                                    <InputLabel className="select-sort-label" id="sort-by-label">Sort By</InputLabel>
                                    <Select
                                        labelId="sort-by-label"
                                        id="sort-by"
                                        value={sortBy}
                                        className='select-sortBy'
                                        label="Age"
                                        onChange={(e) =>
                                          handleReviewDataSort("criticReviews", e.target.value)
                                        }
                                        style={{color:'#fff', fontSize:'14px',}}
                                    >
                                        <MenuItem value="dateTime">Commented date</MenuItem>
                                        <MenuItem value="ratings">Ratings</MenuItem>
                                    </Select>
                                    </FormControl>
                      {/* <select className="custom-select" 
                      onClick={(e) =>
                            handleReviewDataSort("criticReviews", e.target.value)
                          }>
                        <option>Sort By</option>
                        <option value="dateTime">Commented date</option>
                        <option value="ratings">Ratings</option>
                      </select>
                      <svg
                        focusable="false"
                        aria-hidden="true"
                        viewBox="0 0 24 24"
                      >
                        <path d="M7 10l5 5 5-5z"></path>
                      </svg> */}
                    </div>
                  </div>
                  <ReviewCardSlider data={moviesData.criticReviews} />
                </div>}
                <div className="users-reviews">
                  <div className="review-title-section">
                    <div className="tag-title">
                      User Reviews ({moviesData.userReviews.length})
                    </div>
                    <div className="filter-select">
                        <FormControl fullWidth className="custom-select">
                        <InputLabel id="sort-by-label">Sort By</InputLabel>
                        <Select
                            labelId="sort-by-label"
                            id="sort-by"
                            // value={sortBy}
                            className='select-sortBy'
                            label="Age"
                            onChange={(e) =>
                              handleReviewDataSort("userReviews", e.target.value)
                            }
                            style={{color:'#fff', fontSize:'14px',}}
                        >
                            <MenuItem value="dateTime">Commented date</MenuItem>
                            <MenuItem value="ratings">Ratings</MenuItem>
                        </Select>
                        </FormControl>
                    </div>
                  </div>
                  <div className="review-form-card">
                    <form onSubmit={handleSubmit}>
                      <div className="rating-input">
                        <h6>Give Your Review</h6>
                        <Rating
                          name="ratings"
                          precision={0.1}
                          defaultValue={7}
                          max={10}

                          emptyIcon={
                            <StarIcon
                              style={{ opacity: 0.55 }}
                              fontSize="inherit"
                            />
                          }
                        />
                      </div>
                      <div className="message-input">
                        <textarea
                          placeholder="Write your review here...."
                          name="review"
                          value={reviewInput}
                          onChange={(e) => { setReviewInput(e.target.value) }}
                        ></textarea>
                      </div>
                      <button className="theme-btn" style={{ cursor: `${reviewInput ? 'pointer' : ''}`, opacity: `${reviewInput ? 1 : 0.5}` }} disabled={!reviewInput}>Submit</button>
                    </form>
                  </div>
                  {moviesData.userReviews.length>0&&<div className="review-list-container">
                    {moviesData.userReviews.map((item, index) => (
                      <div className="user-review-card" key={index}>
                        <div className="review-card-head">
                          <div className="review-meta">
                            {/* <img
                              src={GenerateImageUrl(item.image, 0, 0, false)}
                              alt=""
                            /> */}
                            <h4 className="review-user">{item.authorName}</h4>
                            <div className="review-rate">
                              <StarRateIcon />
                              <span>{item.ratings}</span>
                            </div>
                          </div>
                          <time className="review-time">
                            {GetPastDaysFromNow(item.dateTime)}
                          </time>
                        </div>
                        <div className="review-content">{item.review}</div>
                        {/* <div className="review-action">
                          <button
                            className="action-btn review-like"
                            onClick={() =>
                              UpdateCommentsLikes(index, item.slug)
                            }
                          >
                            <ThumbUpOffAltIcon
                              style={{ fill: "#fff", height: "20px" }}
                            />
                            <span>{item.likes}</span>
                          </button>
                          <button
                            className="action-btn review-like"
                            onClick={() =>
                              UpdateCommentsDisLikes(index, item.slug)
                            }
                          >
                            <ThumbDownOffAltIcon
                              style={{ fill: "#fff", height: "20px" }}
                            />
                            <span>{item.dislikes}</span>
                          </button>
                        </div> */}
                      </div>
                    ))}
                  </div>}
                </div>
              </div>
            </TabPanel>
            <div className="similar-section">
              {genrerMoviesData && genrerMoviesData.length > 0 && (
                <SimilarRecomended
                  title="Similar by Genre"
                  moviesData={genrerMoviesData}
                  rootPath={props.rootPath}
                  handleShareBtnClick={handleShareBtnClick}
                />
              )}
            </div>
            <div className="recomended-section">
              {recommendedMoviesData && recommendedMoviesData.length > 0 && (
                <SimilarRecomended
                  title="Recommended for you"
                  moviesData={recommendedMoviesData}
                  rootPath={props.rootPath}
                  handleShareBtnClick={handleShareBtnClick}
                />
              )}
            </div>
          </div>
        </section>
        <Modal
          open={open}
          onClose={handleReview}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style} className="review-popup">
            <div className="review-form-card">
              <form onSubmit={handleSubmit}>
                <div className="rating-input">
                  <h6>Give Your Review</h6>
                  <Rating
                    name="ratings"
                    precision={0.1}
                    defaultValue={5}
                    max={10}
                    emptyIcon={
                      <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
                    }
                  />
                </div>
                <div className="message-input">
                  <textarea
                    placeholder="Write your review here...."
                    name="review"
                    value={reviewInput}
                    onChange={(e) => { setReviewInput(e.target.value) }}
                  ></textarea>
                </div>
                <button className="theme-btn" style={{ cursor: `${reviewInput ? 'pointer' : ''}`, opacity: `${reviewInput ? 1 : 0.5}` }} disabled={!reviewInput}>Submit</button>
              </form>
            </div>
          </Box>
        </Modal>
        <Modal
          open={openRating}
          onClose={handleRating}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="user-rating-modal"
        >
          <Box sx={style}>
            <div className="rating-card">
              <div className="rating-icon">
                <svg
                  width="93"
                  height="88"
                  viewBox="0 0 93 88"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M44.5979 3.09017C45.1966 1.24755 47.8034 1.24755 48.4021 3.09017L57.1114 29.8946C57.6469 31.5427 59.1827 32.6586 60.9156 32.6586H89.0995C91.037 32.6586 91.8425 35.1378 90.2751 36.2766L67.4739 52.8427C66.0719 53.8613 65.4853 55.6667 66.0208 57.3148L74.7301 84.1193C75.3288 85.9619 73.2198 87.4942 71.6524 86.3554L48.8511 69.7893C47.4492 68.7707 45.5508 68.7707 44.1489 69.7893L21.3476 86.3554C19.7802 87.4942 17.6712 85.9619 18.2699 84.1193L26.9792 57.3148C27.5147 55.6667 26.9281 53.8613 25.5261 52.8427L2.7249 36.2766C1.15747 35.1378 1.96302 32.6586 3.90047 32.6586H32.0844C33.8173 32.6586 35.3531 31.5427 35.8886 29.8946L44.5979 3.09017Z"
                    fill="#FFB800"
                    stroke="#FFB800"
                    stroke-width="2"
                  />
                  <text x="35" y="55" class="small" fill="white">
                    {showuser_ratingHover?user_ratingHover:user_rating || 0}
                  </text>
                </svg>
                <h6>Rate This</h6>
              </div>
              <div className="rating-content">
                <form onSubmit={handleSubmit}>
                  <h4 className="rating-movie">{moviesData.title}</h4>
                  <div className="rating-input">
                    <Rating
                      name="ratings"
                      precision={0.1}
                      defaultValue={user_rating || 0}
                      max={10}
                      style={{flexWrap:'wrap', justifyContent:'center'}}
                      // onChange={(e) =>
                      //   setUserRating(parseFloat(e.target.value).toFixed(1))
                      // }
                      onChange={(event, newValue) => {
                        setUserRating(newValue);
                      }}
                      onChangeActive={(event, newHover) => {
                        if(newHover==-1){
                        }else{
                          setUserRatingHover(newHover);
                        }
                      }}
                      onMouseOver={()=>{setshowuser_ratingHover(true)}}
                      onMouseLeave={()=>{setshowuser_ratingHover(false)}}
                      size="large"
                      emptyIcon={
                        <StarIcon
                          style={{ opacity: 0.55 }}
                          fontSize="inherit"
                        />
                      }
                    />
                  </div>
                  <button className="theme-btn">Submit Ratings</button>
                </form>
              </div>
            </div>
          </Box>
        </Modal>
        <ShareDialog
          selectedValue={sharelink}
          open={shareDialogOpen}
          onClose={() => {
            setShareDialogOpen(false);
            setSharelink("");
          }}
        />
        {showPopup && (
        <PopupMessage
          type={popUp.type}
          message={popUp.message}
          desc={popUp.desc}
          buttonTitle={popUp.handleLogin?"Log In":"Ok"}
          handleClose={()=> {setshowPopup(false)}}
          open={showPopup}
          handleSubmit={popUp.handleLogin?()=> {navigate(`/login?next=${location.pathname}`)}:()=>{setshowPopup(false)}}
        />
      )}
        <Footer />
      </Fragment>
    ):
    <Box className="Mui_loading" sx={{ display: 'flex' }}>
      <CircularProgress />
    </Box>
  );
}

export default OttDetail;

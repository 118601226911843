import React, { Component, Fragment } from "react";
import GridListing from "../components/GridListing";
import ListingBanner from "../components/ListingBanner";
import Navbar from '../components/Navbar';
import Footer from "../components/Footer";

export class OttListing extends Component {
  render() {
    const { props } = this;
    return (
      <Fragment>
        <Navbar />
        <div className="ott-listing-section">
          <ListingBanner
            title={props.title}
            subtitle={props.subtitle}
            rootPath={props.rootPath}
          />
          <GridListing rootPath={props.rootPath} />
        </div>
        <br />
        <Footer />
      </Fragment>
    );
  }
}

export default OttListing;

import { methodEnum } from "../utils/constant";
import request from "../utils/request";
import { GetApiRootPath } from "../utils/utils";

const GenersUrl = "/genres/";
const platformsUrl = "/platforms/";
const languageUrl = "/languages/";
const certificateUrl = "/certificates/";
const labelsUrl = "/labels/";
const moodsUrl = "/moods/";

const GetGeners = async (rootPath) => {
  return await request({
    url: GetApiRootPath(rootPath, GenersUrl),
    method: methodEnum.GET,
  });
};

const GetPlatform = async (rootPath) => {
  return await request({
    url: GetApiRootPath(rootPath, platformsUrl),
    method: methodEnum.GET,
  });
};

const GetLanguage = async (rootPath) => {
  return await request({
    url: GetApiRootPath(rootPath, languageUrl),
    method: methodEnum.GET,
  });
};

const GetCertificate = async (rootPath) => {
  return await request({
    url: GetApiRootPath(rootPath, certificateUrl),
    method: methodEnum.GET,
  });
};

const GetLabels = async (rootPath) => {
  return await request({
    url: GetApiRootPath(rootPath, labelsUrl),
    method: methodEnum.GET,
  });
};

const GetMoods = async (rootPath) => {
  return await request({
    url: GetApiRootPath(rootPath, moodsUrl),
    method: methodEnum.GET,
  });
};

export {
  GetGeners,
  GetPlatform,
  GetLanguage,
  GetCertificate,
  GetLabels,
  GetMoods,
};

import React from 'react'
import { useMediaQuery } from '@mui/material';
import GenerateImageUrl from '../../utils/utils';
import { Link } from 'react-router-dom';

export default function CollectionSliderCard({item,rootpath}) {
    const isMobile  = useMediaQuery('(max-width:768px)')
    const UrlMap = {
        "movie": "/ott",
        "podcast": "/podcast",
        "book": "/books",
        "game": "/games",
      }
  return (
    <>
        {/* <Link to={`${rootpath || UrlMap[item.type]}/collections/${item.slug}`}> */}
            <img className='img' src={GenerateImageUrl(isMobile?item.home_collection_responsive_image:item.home_collection_banner_image, 0, 0, false)} alt={item.name} />
            <div className='collection-title'><Link to={`${rootpath || UrlMap[item.type]}/collections/${item.slug}`}>{item.name}</Link></div>
        {/* </Link> */}
    </>
  )
}

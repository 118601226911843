import React from 'react'
import placeholderImage from '../../images/profile-image.jpg'
function BlogBanner(props) {
    return (
        <div className='blog-banner-container'>
            <div className='banner-image'>
                <img className='banner-img' src={props.banner} alt={props.title} />
            </div>
            {(props.bannerType === "articles" || props.bannerType === "interviews") &&
                <div className='banner-title'>
                    {props.title && <h1 className='main-title'>{props.title}</h1>}
                    <h6 className='tag-line'>{props.tagline}</h6>
                </div>
            }
            {props.bannerType === "events" &&
                <div className='banner-title-sec'>
                    <div className='title-top'>
                        <h1 className='main-title'>{props.title}</h1>
                        <h6 className='tag-line'>{props.tagline} | {props.venue}</h6>
                    </div>
                    <div className='banner-info'>
                        <div className='info-left'>
                            <div className='info-img'>
                                <img src={props.infoImg?props.infoImg:placeholderImage} alt={props.infoTitle} />
                            </div>
                            <div className='info-content'>
                                <h4 className='info-title'>{props.infoTitle}</h4>
                                <p className='info-tag'>{props.infoTag}</p>
                            </div>
                        </div>
                    </div>
                    <div className='banner-event'>
                            {props.eventList !== undefined && props.eventList.map((val, key) => (
                        <ul className='event-list'>
                                <li className='event-list-card' key={key}>
                                    <h4 className='event-title'>{val.title}</h4>
                                    <p className='envet-date'>{val.date}</p>
                                </li>
                        </ul>
                            ))}
                    </div>
                </div>
            }
        </div>
    )
}

export default BlogBanner
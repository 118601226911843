// import PropTypes from 'prop-types'
import React, { Fragment, useEffect, useState } from "react";
import AboutEbits from "../components/AboutEbits";
import AboutHome from "../components/AboutHome";
import Collections from "../components/Collections";
import Navbar from '../components/Navbar';
import NewNotable from "../components/NewNotable";
import FullWdSlider from "../components/Slider/FullWdSlider";
import InfoGraphCard from "../components/InfoGraphCard";
// api sample data
import {
  GetHomeCollectionsData,
  GetHomePostsContentsData,
} from "../api/home_api";
import { ListModuleData } from "../api/module";
import GridCollection from "../components/GridCollection";
import EventsArticlesInterview from "../components/EventsArticlesInterview";
import game from '../images/about/img5.gif';
import book from '../images/about/img8.gif';
import podcast from '../images/about/img4.gif';
import gamesThumb from "../images/ott_image/game-thum.png";
import ott from '../images/about/img3.gif';
import ottThumb from "../images/ott_image/ott-thumb-crop.png";
import prodcastThumb from "../images/ott_image/prodcasts-thum.png";
import booksThumb from '../images/ott_image/books-thumb.png';
import Footer from "../components/Footer";
import ShareDialog from "../components/ShareModel";
import CollectionList from "../components/Collections/CollectionList";
import OutsideClickHandler from "react-outside-click-handler";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const [collentionsData, setCollentionsData] = useState([]);
  const [moviesData, setMoviesData] = useState([]);
  const [podcastsData, setPodcastsData] = useState([]);
  const [booksData, setBooksData] = useState([]);
  const [gamesData, setGamesData] = useState([]);
  const [homeContent, setHomeContentData] = useState({});
  const [shareDialogOpen, setShareDialogOpen] = useState(false);
  const [sharelink, setSharelink] = useState("");
  const [collentionsListData, setCollentionsListData] = useState({});
  const [showCollection, setShowCollection] = useState(false);
  const UrlMap = {
    "movie": "/ott",
    "podcast": "/podcast",
    "book": "/books",
    "game": "/games",
  }
  useEffect(() => {
    GetHomePostsContentsData()
      .then((response) => {
        let data = {};
        for (let idx in (response.data.result || [])) {
          let item = response.data.result[idx];
          data[item.type_name?.split(" ").join("_")] = {
            description: item.description,
          };
        }
        console.clear()
        console.log('data',data)
        setHomeContentData(data);
      })
      .catch((error) => {
        console.error(error);
      });

    GetHomeCollectionsData()
      .then((response) => {
        let data = [];
        for (let key in (response.data.result) || []) {
          data.push(...response.data.result[key]);
        }
        setCollentionsData(data);
      })
      .catch((error) => {
        console.error(error);
      });

    // GetBlogsData("articles")
    //   .then((response) => {
    //     setArticlesData(response.data.result || []);
    //   })
    //   .catch((error) => {
    //     console.error(error);
    //   });
  }, []);

  useEffect(() => {
    GetModuleData("/ott");
    GetModuleData("/books");
    GetModuleData("/games");
    GetModuleData("/podcast");
  }, []);

  const GetModuleData = (rootPath) => {
    let requestBody = {
      categories: ["Popular"],
      offsetRange: { offset: 0, limit: 20 },
    };
    ListModuleData(rootPath, requestBody)
      .then((response) => {
        if (rootPath === "/podcast") {
          setPodcastsData(response.data.podcasts || []);
        } else if (rootPath === "/books") {
          setBooksData(response.data.books || []);
        } else if (rootPath === "/games") {
          setGamesData(response.data.games || []);
        } else if (rootPath === "/ott") {
          setMoviesData(response.data.movies || []);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleShareBtnClick = (path) => {
    const url = `${window.location.protocol}//${window.location.host}${path}`;
    setShareDialogOpen(true);
    setSharelink(url);
  };
  const navigate = useNavigate();

  const handleRedirect = (movieSlug) => {
    console.log('item', UrlMap[collentionsListData.type])
    navigate(`${UrlMap[collentionsListData.type]}/details/${movieSlug}`);
  };
  const handleShowCollection = (index) => {
    console.log()
    if (collentionsListData === collentionsData[index]) {
      setShowCollection(!showCollection);
    } else {
      setShowCollection(true);
    }
    setCollentionsListData(collentionsData[index]);
  };  
  
  console.log(homeContent)
  return (
    <Fragment>
      <Navbar/>
      <section className="home-section">
        <FullWdSlider />
        {/* <OutsideClickHandler className="outside"
          onOutsideClick={() => {
            setShowCollection(false);
          }}
        > */}
          <CollectionList
            show={showCollection}
            title={collentionsListData.name}
            collectionSlug={collentionsListData.slug}
            list={collentionsListData.entries}
            handleRedirect={handleRedirect}
            type={collentionsListData.type}
            handleClose={()=>{setShowCollection(false)}}
            // rootPath={props.rootPath}
          />
        {/* </OutsideClickHandler> */}
        {collentionsData.length > 0 && (
          <Collections
            handleShowCollection={(index) => handleShowCollection(index)}
            collentionsData={collentionsData}
            // rootPath={props.rootPath}
          />
        )}
        <EventsArticlesInterview rootPath={''} handleShareBtnClick={handleShareBtnClick}/>
        <AboutHome paragraph={homeContent?.Reports?.description} />
        <AboutEbits paragraph={homeContent?.About_EQbits?.description} />
        <NewNotable paragraph={homeContent?.New_And_Notables?.description} />
        {/* <InfoGraphCard rootPath={'/ott'} /> */}
        <GridCollection
          data={moviesData}
          rootPath={"/ott"}
          thumbImg={ottThumb}
          banner={ott}
          title="OTT Platforms"
          // hide={1}
          paragraph={homeContent?.Movie?.description}
        />
        <GridCollection
          data={podcastsData}
          rootPath={"/podcast"}
          thumbImg={prodcastThumb}
          banner={podcast}
          title="The Podcasts"
          paragraph={homeContent?.Podcast?.description}
        />
        <GridCollection
          data={gamesData}
          rootPath={"/games"}
          thumbImg={gamesThumb}
          banner={game}
          title="The Games"
          paragraph={homeContent?.Game?.description}
        />
        <GridCollection
          data={booksData}
          rootPath={"/books"}
          thumbImg={booksThumb}
          banner={book}
          title="The Books"
          paragraph={homeContent?.Book?.description}
        />
        {/* <HomeBlogs
          articlesData={articlesData}
          rootPath={"/home"}
          setArticlesData={setArticlesData}
          handleShareBtnClick={handleShareBtnClick}
        /> */}
        <ShareDialog
          selectedValue={sharelink}
          open={shareDialogOpen}
          onClose={() => {
            setShareDialogOpen(false);
            setSharelink("");
          }}
        />
      </section>
      <Footer />
    </Fragment>
  );
};

export default Home;

import { methodEnum } from "../utils/constant";
import request from "../utils/request";
import { GetApiRootPath } from "../utils/utils";

const ReportsUrl = "/reports/";
const CollectionsUrl = "/collections/";
const SimilarGenerUrl = "/similarbygenres/";
const CommentUrl = "/addusercomment/";
const CommentLikeUrl = "/addusercommentlikes/";
const CommentDisLikeUrl = "/addusercommentdislikes/";
const WriteToUsUrl = "/send_mail_to/";

const ListModuleData = async (rootPath, data) => {
  return await request({
    url: GetApiRootPath(rootPath, rootPath === "/ott" ? "/movies/" : "/"),
    method: methodEnum.POST,
    data,
  });
};

const GetModuleDetail = async (rootPath, slug) => {
  const response = await request({
    url: `${GetApiRootPath(
      rootPath,
      rootPath === "/ott" ? "/movies" : ""
    )}/${slug}`,
    method: methodEnum.GET,
  });

  if (response.status === 200) {
    if (rootPath === "podcast") {
      return response.data.podcast || {};
    } else if (rootPath === "books") {
      return response.data.books || {};
    } else if (rootPath === "games") {
      return response.data.games || {};
    } else if (rootPath === "ott") {
      return response.data.movies || {};
    }
    return response;
  } else {
    throw response;
  }
};

const ListCollentions = async (rootPath) => {
  return await request({
    url: GetApiRootPath(rootPath, CollectionsUrl),
    method: methodEnum.GET,
  });
};

const GetCollentionsDetails = async (rootPath, slug) => {
  return await request({
    url: `${GetApiRootPath(rootPath, CollectionsUrl)}${slug}`,
    method: methodEnum.GET,
  });
};

const GetSimilarByGenre = async (rootPath, slug) => {
  var response = await request({
    url: `${GetApiRootPath(rootPath, SimilarGenerUrl)}${slug}`,
    method: methodEnum.GET,
  });

  if (response.status === 200) {
    if (rootPath === "/podcast") {
      return response.data.podcasts;
    } else if (rootPath === "/books") {
      return response.data.books;
    } else if (rootPath === "/games") {
      return response.data.games;
    } else if (rootPath === "/ott") {
      return response.data.movies;
    }
    return response;
  } else {
    throw response;
  }
};

const AddComment = async (rootPath, data) => {
  return await request({
    url: GetApiRootPath(rootPath, CommentUrl),
    method: methodEnum.POST,
    data,
  });
};

const AddCommentsLikes = async (rootPath, data) => {
  return await request({
    url: GetApiRootPath(rootPath, CommentLikeUrl),
    method: methodEnum.POST,
    data,
  });
};

const AddCommentsDisLikes = async (rootPath, data) => {
  return await request({
    url: GetApiRootPath(rootPath, CommentDisLikeUrl),
    method: methodEnum.POST,
    data,
  });
};

const GetReports = async (rootPath) => {
  return await request({
    url: GetApiRootPath(rootPath, ReportsUrl),
    method: methodEnum.GET,
  });
};

const GetReportDetails = async (rootPath, slug) => {
  return await request({
    url: `${GetApiRootPath(rootPath, ReportsUrl)}${slug}`,
    method: methodEnum.GET,
  });
};
const writeToUs = async (rootPath, data) => {
  return await request({
    url: GetApiRootPath(rootPath, WriteToUsUrl),
    method: methodEnum.POST,
    data,
  });
};

export {
  ListModuleData,
  writeToUs,
  GetModuleDetail,
  GetSimilarByGenre,
  AddComment,
  ListCollentions,
  AddCommentsLikes,
  AddCommentsDisLikes,
  GetCollentionsDetails,
  GetReports,
  GetReportDetails,
};

import React, { Fragment, useEffect, useState } from "react";
import Navbar from '../components/Navbar';
import OutsideClickHandler from "react-outside-click-handler";
import BestInStory from "../components/BestInStory";
import Collections from "../components/Collections/index";
import Ebits from "../components/Ebits-Treasure";
import EventsArticlesInterview from "../components/EventsArticlesInterview";
import ExploreNew from "../components/ExploreNew";
import BestInGraphics from "../components/BestInGraphics";
import InfoGraphOTT from "../components/InfoGraphOTT";
import ListingSlider from "../components/ListingSlider";

import UpcomingMovies from "../components/UpcomingMovies/index";
//  sample data
import CollectionList from "../components/Collections/CollectionList";
import Footer from "../components/Footer";
import MoodSlider from "../components/MoodSlider";
import ShareDialog from "../components/ShareModel";

import { useNavigate } from "react-router-dom";
import {
  GetGeners,
  GetLanguage,
  GetMoods,
  GetPlatform,
  GetLabels,
} from "../api/master";
import { ListCollentions } from "../api/module";

function Ott(props) {
  const [labelsData, setLabelsData] = useState([]);
  const [genersData, setGenersData] = useState([]);
  const [platformData, setPlatformData] = useState([]);
  const [languageData, setLanguageData] = useState([]);
  const [collentionsData, setCollentionsData] = useState([]);
  const [collentionsListData, setCollentionsListData] = useState({});
  const [moodsData, setMoodsData] = useState([]);
  const [shareDialogOpen, setShareDialogOpen] = React.useState(false);
  const [sharelink, setSharelink] = useState("");
  useEffect(() => {
    getGenersData();
    getPlatformsData();
    getLanguageData();
    getMoodsData();
    getLabelData();
    listCollentionsData();
  }, [props.rootPath]);

  const getGenersData = () => {
    GetGeners(props.rootPath)
      .then((response) => {
        if (props.rootPath === "/ott") {
          setGenersData(response.data.geners || []);
        } else {
          setGenersData(response.data.genres || []);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const getLabelData = () => {
    GetLabels(props.rootPath)
      .then((response) => {
        setLabelsData(response.data.categories || []);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getPlatformsData = () => {
    GetPlatform(props.rootPath)
      .then((response) => {
        setPlatformData(response.data.platforms || []);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getLanguageData = () => {
    GetLanguage(props.rootPath)
      .then((response) => {
        setLanguageData(response.data.languages || []);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getMoodsData = () => {
    GetMoods(props.rootPath)
      .then((response) => {
        setMoodsData(response.data.moods || []);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const listCollentionsData = () => {
    ListCollentions(props.rootPath)
      .then((response) => {
        setCollentionsData(response.data.collections || []);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const [showCollection, setShowCollection] = useState(false);
  const handleShowCollection = (index) => {
    if (collentionsListData === collentionsData[index]) {
      setShowCollection(!showCollection);
    } else {
      setShowCollection(true);
    }
    setCollentionsListData(collentionsData[index]);
  };

  const navigate = useNavigate();

  const handleRedirect = (movieSlug) => {
    navigate(`${props.rootPath}/details/${movieSlug}`);
  };

  const handleShareBtnClick = (path) => {
    const url = `${window.location.protocol}//${window.location.host}${path}`;
    setShareDialogOpen(true);
    setSharelink(url);
  };

  return (
    <Fragment>
      <Navbar />
      <section className="ott-section">
        {/* <OutsideClickHandler
          onOutsideClick={() => {
            setShowCollection(false);
          }}
        > */}
          <CollectionList
            show={showCollection}
            title={collentionsListData.name}
            collectionSlug={collentionsListData.slug}
            list={collentionsListData.entries}
            handleRedirect={handleRedirect}
            rootPath={props.rootPath}
            handleClose={()=>{setShowCollection(false)}}
          />
        {/* </OutsideClickHandler> */}
        {collentionsData.length > 0 && (
          <Collections
            handleShowCollection={(index) => handleShowCollection(index)}
            collentionsData={collentionsData}
            rootPath={props.rootPath}
          />
        )}
        <InfoGraphOTT rootPath={props.rootPath} />
        {genersData.length > 0 && (
          <ListingSlider
            genersData={genersData}
            languageData={languageData}
            platformData={platformData}
            handleRedirect={handleRedirect}
            rootPath={props.rootPath}
            handleShareBtnClick={handleShareBtnClick}
          />
        )}
        {["/games"].includes(props.rootPath) && (
          <BestInGraphics
            handleRedirect={handleRedirect}
            rootPath={props.rootPath}
          />
        )}
        <EventsArticlesInterview rootPath={props.rootPath} handleShareBtnClick={handleShareBtnClick}/>
        {moodsData && moodsData.length > 0 && (
          <MoodSlider moodsData={moodsData} rootPath={props.rootPath} />
        )}
        <Ebits
          rootPath={props.rootPath}
          labelsData={labelsData}
          handleRedirect={handleRedirect} handleShareBtnClick={handleShareBtnClick}
        />
        {platformData.length > 0 && (
          <ExploreNew
            platformData={platformData}
            handleRedirect={handleRedirect}
            rootPath={props.rootPath}
          />
        )}
        {["/ott"].includes(props.rootPath) && (
          <UpcomingMovies
            rootPath={props.rootPath}
            handleRedirect={handleRedirect}
          />
        )}
        <BestInStory
          title={"Best in Story"}
          handleRedirect={handleRedirect}
          rootPath={props.rootPath}
        />
      </section>
      <Footer />
      <ShareDialog
        selectedValue={sharelink}
        open={shareDialogOpen}
        onClose={() => {
          setShareDialogOpen(false);
          setSharelink("");
        }}
      />
    </Fragment>
  );
}

export default Ott;

import React, { Fragment, useState, useEffect } from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import PropTypes from 'prop-types';
// import SwipeableViews from 'react-swipeable-views';
import Slider from '@mui/material/Slider';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Navigation } from "swiper";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import { createTheme, ThemeProvider } from '@mui/material/styles';
// icons
import StarIcon from '@mui/icons-material/Star';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import ShareIcon from '@mui/icons-material/Share';
import { Link } from 'react-router-dom';
import GenerateImageUrl, { TruncateText } from "../../utils/utils"
import { ListModuleData } from "../../api/module"
import { useMediaQuery } from 'react-responsive'
import { FilterIcon } from '../../images/icons/Icon'
import ClearIcon from '@mui/icons-material/Clear';
import Accordion from '../Accordion/Accordion';
import MultipleSelectCheckmarks from '../Accordion/MultipleSelectCheckmarks';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useRef } from "react";
import { useDraggable } from "react-use-draggable-scroll";
var qs = require('qs');

const theme = createTheme({
    palette: {
        primary: {
            main: '#D21A4E',
            darker: '#D21A4E',
        }
    },
    typography: {
        fontFamily: [
            'Poppins'
        ]
    }
});


function TabPanel(props) {
    const { children, value, index, ...other } = props;
    
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 0 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}
function ScrollFilter({ genersData, filter_genres, handleGenres }) {
    const ref = useRef(); // We will use React useRef hook to reference the wrapping div:
    const { events } = useDraggable(ref); // Now we pass the reference to the useDraggable hook:
  
    return (<ul className='genres-fliter'
                {...events}
                ref={ref} // add reference and events to the wrapping div 
            >{genersData.map((val, key) => {
        return (
            val.name && <li className={`${filter_genres.includes(val.name) ? 'selected' : ''}`} key={key} onClick={handleGenres} >{val.name}</li>
        )
    })}</ul>)
}
function ListingSlider(props) {
    
    const [filter_genres, setFiterGenres] = useState([])
    const [movieFilter, setMovieFilter] = useState({genres: [props.genersData[0].name,props.genersData[1].name,props.genersData[2].name],})
    const [tabValue, setTabValue] = React.useState(0);
    const [sortBy, setSortBy] = useState('')
    const [ebits_rating, setEbitsRating] = useState([0, 10]);
    const [critic_rating, setCriticRating] = useState([0, 10]);
    const [moduleData, setModuleData] = useState([]);
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 992px)' })
    const [openFilter, setOpenFilter] = useState(false);
    const platformList = props.platformData.map((val) => val.name);
    const languagesList = props.languageData.map((val) => val.name);
    const tabContents = [
        "Trending Now",
        "Popular",
        "Recommended",
        "Newly Added"
    ];

    useEffect(() => {
        setFiterGenres([props.genersData[0].name,props.genersData[1].name,props.genersData[2].name])
        const newFilterData = {
            categories: ["Trending Now"],
            genres: [props.genersData[0].name,props.genersData[1].name,props.genersData[2].name],
            offsetRange: { "offset": 0, "limit": 20 }
        };
        setMovieFilter(newFilterData)
    }, [props.genersData]);

    useEffect(() => {
        let requestBody = movieFilter;
        if (Object.keys(requestBody).length === 0) {
            requestBody = {
                categories: ["Trending Now"],
                genres: [props.genersData[0].name,props.genersData[1].name,props.genersData[2].name],
                offsetRange: { "offset": 0, "limit": 20 }
            };
            setMovieFilter(requestBody)
        }
        ListModuleData(props.rootPath, requestBody)
            .then((response) => {
                let data = []
                if (props.rootPath === "/podcast") {
                    data = response.data.podcasts;
                } else if (props.rootPath === "/books") {
                    data = response.data.books;
                } else if (props.rootPath === "/games") {
                    data = response.data.games;
                } else if (props.rootPath === "/ott") {
                    data = response.data.movies;
                }

                setModuleData(data || []);
            })
            .catch((error) => {
                console.error(error);
            });
    }, [movieFilter, props.rootPath]);

    
    const tabChange = (event, newValue) => {
        setModuleData([])
        setTabValue(newValue);

        setEbitsRating([0, 10]);
        setCriticRating([0, 10]);

        const newFilterData = {
            "categories": [event.target.textContent],
            offsetRange: { "offset": 0, "limit": 20 }
        };

        newFilterData["genres"] = [...filter_genres]
        setMovieFilter(newFilterData)
    };
    const handleChangeIndex = (index) => {
        setTabValue(index);
    };

    const handleGenres = (e) => {

        const newFilterData = {
            ...movieFilter,
            "categories": movieFilter.categories ? movieFilter.categories : [tabContents[0]],
        };

        const genre = e.target.textContent;
        if (!filter_genres.includes(genre)) {
            // Add the new genre to the state
            setFiterGenres([...filter_genres, genre]);
            newFilterData["genres"] = [...filter_genres, genre]
        } else {
            // Remove the genre from the state if it already exists
            setFiterGenres(filter_genres.filter(item => item !== genre));
            newFilterData["genres"] = filter_genres.filter(item => item !== genre)
        }

        setMovieFilter(newFilterData)

    }

    const handleMovieDataSort = (e) => {
        const sortBy = e.target.value;
        console.log(sortBy)
        setSortBy(sortBy)
        const sorted = [...moduleData].sort((a, b) => {
            if (a[sortBy] < b[sortBy]) {
                return 1;
            }
            if (a[sortBy] > b[sortBy]) {
                return -1;
            }
            // If values are equal, you can add a secondary sorting criterion
            // For example, sorting by movie ID or any other unique identifier
            if (a.id < b.id) {
                return 1;
            }
            if (a.id > b.id) {
                return -1;
            }

            return 0;   
            });
            setModuleData(sorted);
    };


    const handleEbitsChange = (event, value) => {
        const newFilterData = {
            ...movieFilter,
            "categories": movieFilter.categories ? movieFilter.categories : [tabContents[0]],
            "ebitsRatingRange": { "low": value[0], "high": value[1] }
        };
        setMovieFilter(newFilterData)
    }

    const handleCriticChange = (event, value) => {
        const newFilterData = {
            ...movieFilter,
            "categories": movieFilter.categories ? movieFilter.categories : [tabContents[0]],
            "criticsRatingRange": { "low": value[0], "high": value[1] }
        };
        setMovieFilter(newFilterData)

    }

    const movieFilterChange = (name, value) => {
        const newFilterData = {
            ...movieFilter,
            "categories": movieFilter.categories ? movieFilter.categories : [tabContents[0]],
        };
        if (value.length > 0) {
            newFilterData[name] = value
            // newFilterData[event.target.name] = [event.target.value]
        } else {
            delete newFilterData[name]
        }
        console.log(newFilterData)
        setMovieFilter(newFilterData)
    }



    return (
        <Fragment>
            <div className='listing-slider-section'>
                <ThemeProvider theme={theme}>
                    <Box sx={{ width: '100%' }}>
                        <Tabs value={tabValue} onChange={tabChange} textColor="primary" variant="scrollable" scrollButtons="auto" className="listing-tab-section">
                            <Tab value={0} label="Trending Now" />
                            <Tab {...a11yProps(0)} label="Popular" />
                            <Tab value={2} label="Recommended" />
                            <Tab value={3} label="Newly Added" />
                        </Tabs>
                        {/* <SwipeableViews
                            index={tabValue}
                            onChangeIndex={handleChangeIndex}
                            disabled={false}
                        > */}
                        {tabContents.map((_, index) => (
                            <div>
                                <TabPanel value={tabValue} index={index} dir={theme.direction}>
                                    {isTabletOrMobile === true ?
                                        <div className='mobile-filter-bar'>
                                        <button className='filter-btn' onClick={() => setOpenFilter(true)}><FilterIcon /></button>
                                        <ScrollFilter genersData={props.genersData} filter_genres={filter_genres} handleGenres={handleGenres} />
                                        </div>
                                        :
                                        <ScrollFilter genersData={props.genersData} filter_genres={filter_genres} handleGenres={handleGenres} />}
                                    <div className={`other-filters-grid ${isTabletOrMobile === true ? 'mobile-filter-modal' : ''} ${openFilter === true ? 'open' : ''}`}>
                                        {isTabletOrMobile === true && <div className='filter-modal-title'>Filters <button className='filter-btn' onClick={() => setOpenFilter(false)}><ClearIcon /></button></div>}
                                        <div className='other-filters'>
                                            {/* <div className='filter-select'>
                                                <select className='custom-select' name='platforms' onChange={movieFilterChange}>
                                                    <option value="">
                                                        {props.rootPath === '/games' ? 'Playing On' : 'Streaming On'}
                                                    </option>
                                                    {props.platformData.map((val, _) => (
                                                        <option key={val.name} value={val.name}>
                                                            {val.name}
                                                        </option>
                                                    ))}
                                                </select>
                                                <svg focusable="false" aria-hidden="true" viewBox="0 0 24 24"><path d="M7 10l5 5 5-5z"></path></svg>
                                            </div>
                                            <div className='filter-select'>
                                                <select className='custom-select' name='languages' onChange={movieFilterChange} value={movieFilter.languages} >
                                                    <option value="">Languages</option>
                                                    {props.languageData.map((val, _) => (
                                                        <option key={val.name} value={val.name}>
                                                            {val.name}
                                                        </option>
                                                    ))}
                                                </select>
                                                <svg focusable="false" aria-hidden="true" viewBox="0 0 24 24"><path d="M7 10l5 5 5-5z"></path></svg>
                                            </div> */}
                                            <MultipleSelectCheckmarks 
                                                label={props.rootPath === '/games' ? 'Playing On' : 'Streaming On'} 
                                                data={platformList} 
                                                onChange={(value)=>{movieFilterChange("platforms",value)}} />
                                            <MultipleSelectCheckmarks 
                                                label={"Languages"} 
                                                data={languagesList} 
                                                onChange={(value)=>{movieFilterChange("languages",value)}}
                                                 />
                                        </div>
                                        <div className='other-filters'>
                                            <div className='rating-box'>
                                                <label>EQbits Ratings</label>
                                                <Slider
                                                    getAriaLabel={() => 'EQbits Ratings'}
                                                    value={ebits_rating}
                                                    onChangeCommitted={handleEbitsChange}
                                                    onChange={(event, value) => setEbitsRating(value)}
                                                    step={0.1}
                                                    valueLabelDisplay="auto"
                                                    min={0}
                                                    max={10}
                                                />
                                            </div>
                                            <div className='rating-box'>
                                                <label>Critic Ratings</label>
                                                <Slider
                                                    getAriaLabel={() => 'Critic Ratings'}
                                                    value={critic_rating}
                                                    name='criticsRatingRange'
                                                    onChangeCommitted={handleCriticChange}
                                                    onChange={(event, value) => setCriticRating(value)}
                                                    step={0.1}
                                                    valueLabelDisplay="auto"
                                                    min={0}
                                                    max={10}
                                                />
                                            </div>
                                            <div className='filter-select'>
                                            <FormControl fullWidth>
                                            <InputLabel id="sort-by-label">Sort By</InputLabel>
                                            <Select
                                                labelId="sort-by-label"
                                                id="sort-by"
                                                value={sortBy}
                                                className='select-sortBy'
                                                label="Age"
                                                onChange={handleMovieDataSort}
                                                style={{color:'#fff', fontSize:'14px',}}
                                            >
                                                <MenuItem value="ReleaseDate">Release date</MenuItem>
                                                <MenuItem value="ebitsRating">EQbits Ratings</MenuItem>
                                                <MenuItem value="criticsRating">Critics Ratings</MenuItem>
                                            </Select>
                                            </FormControl>
                                                {/* <select className='custom-select' onChange={(e) => handleMovieDataSort(e.target.value)}>
                                                    <option>Sort By</option>
                                                    <option value="ReleaseDate">Release date</option>
                                                    <option value="ebitsRating">EQbits Ratings</option>
                                                    <option value="criticsRating" >Critics Ratings</option>
                                                </select>
                                                <svg focusable="false" aria-hidden="true" viewBox="0 0 24 24"><path d="M7 10l5 5 5-5z"></path></svg> */}
                                            </div>
                                        </div>
                                    </div>
                                    <Swiper navigation={true} modules={[Navigation]} className="listing-grid-slider" slidesPerView={'auto'} spaceBetween={35} loop={false} breakpoints={{ 320: { spaceBetween: 18, navigation: false }, 768: { spaceBetween: 18, navigation: false }, 1024: { spaceBetween: 24 } }}>
                                        {moduleData.map((val, key) => {
                                            return (
                                                <SwiperSlide key={key}>
                                                    <div className='movie-card'>
                                                        <div className='movie-image' >
                                                            <img src={GenerateImageUrl(val?.photos?.Cards_Trending_Now, 268, 335)} alt={val.image_alt}/>
                                                            <div className='movie-option'>
                                                                <div className='share-link' onClick={() => props.handleShareBtnClick(`${props.rootPath}/details/${val.slug}`)}><ShareIcon /></div>
                                                                {/* <div className='bookmark-link'><BookmarkIcon /></div> */}
                                                            </div>
                                                        </div>
                                                        <div className='movie-info' onClick={() => props.handleRedirect(val.slug)}>
                                                            <h4 className='movie-title'><Link to={`${props.rootPath}/details/${val.slug}`}>{TruncateText(val.Title,30)}</Link></h4>
                                                            <div className='movie-rating'><StarIcon sx={{ color: '#FFB800' }} /> <span>{val.ebitsRating}</span></div>
                                                        </div>
                                                    </div>
                                                </SwiperSlide>
                                            )
                                        })}
                                    </Swiper>
                                    <Link className='view-all-btn' to={{
                                        pathname: `${props.rootPath}/content`,
                                        search: qs.stringify(movieFilter),
                                    }}>View All</Link>
                                </TabPanel>
                            </div>
                        ))}
                        {/* </SwipeableViews> */}
                    </Box>
                </ThemeProvider>
            </div>
        </Fragment>
    )
}

export default ListingSlider
const methodEnum = {
  POST: "POST",
  GET: "GET",
};

const RouteUrls = {
  "new-realeses": "New Releases",
  "recommended": "Recommended",
  "most-popular": "Popular",
  "best-in-stories": "Best In Story",
  "new-and-notable": "New And Notable",
  "top-rated": "Trending Now",
  "browse-by-genre": "Popular",
  "whats-your-mood": "Popular",
  "browse-by-cast": "Popular",
};

const colorCode = [
  "linear-gradient(180deg, rgba(83, 218, 218, 0.21) 0.01%, rgba(64, 234, 234, 0.986185) 91.87%, #3EEBEB 100%)",
  "linear-gradient(180deg, rgba(83, 145, 218, 0.42) 0%, #563EEB 100%)",
  "linear-gradient(180deg, rgba(215, 83, 218, 0.35) 0%, #EB3EE4 100%)",
  "linear-gradient(180deg, rgba(102, 218, 83, 0.66) 0%, rgba(102, 218, 83, 0.23) 0.01%, #3EEB50 100%)",
  "linear-gradient(180deg, rgba(83, 129, 218, 0.3) 0%, #3E98EB 100%)",
  "linear-gradient(180deg, rgba(218, 132, 83, 0.27) 0%, #EB723E 100%)",
];

const chartcolors = [
  { "name": "Purple-Pink", "hex": "#DC92FF" },
  { "name": "Green", "hex": "#4CA86B" },
  { "name": "Orange", "hex": "#D79C80" },
  { "name": "Blue", "hex": "#71B5F3" },
  { "name": "Teal", "hex": "#75C9BF" },
  { "name": "Magenta", "hex": "#FF00FF" },
  { "name": "PeachPuff", "hex": "#FFDAB9" },
  { "name": "Orange", "hex": "#FFA500" },
  { "name": "Gold", "hex": "#FFD700" },
  { "name": "MediumOrchid", "hex": "#BA55D3" },
  { "name": "Coral", "hex": "#FF7F50" },
  { "name": "Tomato", "hex": "#FF6347" },
  { "name": "MediumPurple", "hex": "#9370DB" },
  { "name": "DarkSalmon", "hex": "#E9967A" },
  { "name": "LightSalmon", "hex": "#FFA07A" },
  { "name": "BlueViolet", "hex": "#8A2BE2" },
  { "name": "DarkViolet", "hex": "#9400D3" },
  { "name": "DarkOrchid", "hex": "#9932CC" },
  { "name": "DarkMagenta", "hex": "#8B008B" },
  { "name": "Purple", "hex": "#800080" },
  { "name": "Indigo ", "hex": "#4B0082" },
  { "name": "PaleGoldenrod", "hex": "#EEE8AA" },
  { "name": "IndianRed", "hex": "#CD5C5C" },
  { "name": "LightCoral", "hex": "#F08080" },
  { "name": "Salmon", "hex": "#FA8072" },
  { "name": "DarkSalmon", "hex": "#E9967A" },
  { "name": "LightSalmon", "hex": "#FFA07A" },
  { "name": "Crimson", "hex": "#DC143C" },
  { "name": "Red", "hex": "#FF0000" },
  { "name": "FireBrick", "hex": "#B22222" },
  { "name": "DarkRed", "hex": "#8B0000" },
  { "name": "Pink", "hex": "#FFC0CB" },
  { "name": "LightPink", "hex": "#FFB6C1" },
  { "name": "HotPink", "hex": "#FF69B4" },
  { "name": "DeepPink", "hex": "#FF1493" },
  { "name": "MediumVioletRed", "hex": "#C71585" },
  { "name": "PaleVioletRed", "hex": "#DB7093" },
  { "name": "Coral", "hex": "#FF7F50" },
  { "name": "Tomato", "hex": "#FF6347" },
  { "name": "OrangeRed", "hex": "#FF4500" },
  { "name": "DarkOrange", "hex": "#FF8C00" },
  { "name": "Orange", "hex": "#FFA500" },
  { "name": "Gold", "hex": "#FFD700" },
  { "name": "Yellow", "hex": "#FFFF00" },
  { "name": "LightYellow", "hex": "#FFFFE0" },
  { "name": "LemonChiffon", "hex": "#FFFACD" },
  { "name": "LightGoldenrodYellow", "hex": "#FAFAD2" },
  { "name": "PapayaWhip", "hex": "#FFEFD5" },
  { "name": "Moccasin", "hex": "#FFE4B5" },
  { "name": "PeachPuff", "hex": "#FFDAB9" },
  { "name": "PaleGoldenrod", "hex": "#EEE8AA" },
  { "name": "Khaki", "hex": "#F0E68C" },
  { "name": "DarkKhaki", "hex": "#BDB76B" },
  { "name": "Lavender", "hex": "#E6E6FA" },
  { "name": "Thistle", "hex": "#D8BFD8" },
  { "name": "Plum", "hex": "#DDA0DD" },
  { "name": "Violet", "hex": "#EE82EE" },
  { "name": "Orchid", "hex": "#DA70D6" },
  { "name": "Fuchsia", "hex": "#FF00FF" },
]
export { methodEnum, RouteUrls, colorCode, chartcolors };

import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import React, { useState } from "react";
import { useAuthUser } from "react-auth-kit";
import { useNavigate } from "react-router-dom";
import { ChangePassword } from "../../api/auth";
import PopupMessage from "../../components/PopupMessage";
import banner from "../../images/auth-page-banner.webp";
import { useForm } from "react-hook-form";
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

function NewPassword() {
  const navigate = useNavigate();
  const auth = useAuthUser();
  const [showPopup, setShowPopup] = useState(false);
  const [popUpData, setPopUpData] = useState({
    type:"error",
    message:"Error!",
    desc:"There is error while completing the action",
    buttonTitle:"Back to Home",
  });
  const [passwordType, setPasswordType] = useState("password");
  const [confirmPasswordType, setConfirmPasswordType] = useState("password");
  const schema = yup.object().shape({
    password: yup.string().required('Password is required').min(8, 'Password must be at least 8 characters'),
    confirmPassword: yup.string().required('Confirm Password is required').oneOf([yup.ref('password'), null], 'Passwords must match')
  });
  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(schema),
  });
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };
  const toggleConfirmPassword = () => {
    if (confirmPasswordType === "password") {
      setConfirmPasswordType("text");
      return;
    }
    setConfirmPasswordType("password");
  };

  const handleChangePassword = async (data) => {
    try {
      await ChangePassword({ email: auth().email, new_password: data.password });
      setPopUpData({
        type:"success",
        message:"Success!",
        desc: "Password reset successfully.",
        buttonTitle:"back To Home",
      }) 
      setShowPopup(true);
      setTimeout(() => {
        navigate("/");
      }, 1000);
    } catch (error) {
      console.error(error);
      if (error?.response?.status === 400) {
        for (const key in error.response?.data || []) {
          const errorMessages = error.response.data[key];
          setPopUpData({
            type:"error",
            message:"Error!",
            desc: errorMessages,
            buttonTitle:"Close",
          }) 
        }
      } else {
        setPopUpData({
          type:"error",
          message:"Error!",
          desc: "There is error while completing the action",
          buttonTitle:"Close",
        }) 
      }
      setShowPopup(true);
    }
  };

  const backToHome = () => {
    setShowPopup(false);
    navigate(`/login`);

  };

  return (
    <section className="login-section">
      <div className="login-banner">
        <img className="login-image" src={banner} alt="login" />
      </div>
      <div className="login-form-container">
        <form className="login-form" onSubmit={handleSubmit(handleChangePassword)}>
          <div className="login-header">
            <h1 className="login-title">Set New Password</h1>
          </div>
          <div className="form-input">
            <div className="from-group">
              <label htmlFor="">Password</label>
              <div className="input-with-btn">
                <input type={passwordType} {...register("password")}/>
                <span type="button" className="btn" onClick={togglePassword}>
                  {passwordType === "password" ? (
                    <VisibilityOffOutlinedIcon />
                  ) : (
                    <VisibilityOutlinedIcon />
                  )}
                </span>
              </div>
              {errors.password && <span className="error">{errors.password.message}</span>}
            </div>
            <div className="from-group">
              <label htmlFor="">Confirm Password</label>
              <div className="input-with-btn">
                <input type={confirmPasswordType} {...register("confirmPassword")}/>
                <span
                  type="button"
                  className="btn"
                  onClick={toggleConfirmPassword}
                >
                  {confirmPasswordType === "password" ? (
                    <VisibilityOffOutlinedIcon />
                  ) : (
                    <VisibilityOutlinedIcon />
                  )}
                </span>
              </div>
              {errors.confirmPassword && <span className="error">{errors.confirmPassword.message}</span>}
            </div>
          </div>
          <button className="btn btn-primary" type="submit">
            Confirm
          </button>
        </form>
      </div>
      {showPopup && (
        <PopupMessage
          type={popUpData.type}
          message={popUpData.message}
          desc={popUpData.desc}
          buttonTitle={popUpData.buttonTitle}
          open={showPopup}
          handleClose={()=>setShowPopup(false)}
          handleSubmit={popUpData.buttonTitle === "Close"?()=>setShowPopup(false):backToHome}
        />
      )}
    </section>
  );
}

export default NewPassword;

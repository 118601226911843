import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import React, { Fragment, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import GenerateImageUrl, { TruncateText, TruncateTextHtml } from '../../utils/utils';
import { AddBlogsLike } from "../../api/blog"
import { GetFormatedDate } from "../../utils/utils";
import PopupMessage from '../PopupMessage';
import { useAuthUser,    useIsAuthenticated } from "react-auth-kit";

function ArticlesCard(props) {
    const [expandedSections, setExpandedSections] = useState(new Array(props.blogsData.length).fill(false));
    const navigate = useNavigate();
    const [showAuthError, setShowAuthError] = useState(false)
    const isAuthenticated = useIsAuthenticated();
    const auth = useAuthUser();
    const location = useLocation();
    const [likedComments, setLikedComments] = useState([]);
    

    const updateLikes = (index, commentSlug) => {
        if (!isAuthenticated || !auth()) {
            setShowAuthError(true);
            return
          }
        let newData = [...props.blogsData];
        if (newData[index].user_liked=='true') {
            newData[index].user_liked = 'false'
            newData[index].likes -= 1
            // Remove the commentSlug from likedComments
            setLikedComments((prevLikedComments) =>
            prevLikedComments?.filter((slug) => slug !== commentSlug));
        }else{
            newData[index].likes += 1
            newData[index].user_liked = 'true'
            setLikedComments([...likedComments, commentSlug]);
          }
          AddBlogsLike("articles", { slug: commentSlug })
          .then((response) => {
              props.setBlogsData(newData);
             })
            .catch((error) => {
                alert(error);
            });
    };

    const handleReadMoreClick = (sectionIndex) => {
        const expandedCopy = [...expandedSections];
        expandedCopy[sectionIndex] = !expandedCopy[sectionIndex];
        setExpandedSections(expandedCopy);
    };

    return (
        <Fragment>
            {props.blogsData.map((val, key) => (
                <div className='event-col' key={key}>
                    <div className='event-card'>
                        <div className='event-img-weapper'>
                            <div className='event-img'>
                                <img src={GenerateImageUrl(val.image, 0, 0, false)} alt='' />
                            </div>
                            <div className='event-meta'>
                                <span className={"event-date"}>{GetFormatedDate(val.publish_date)}</span>
                                <div className='event-social'>
                                    <span className={"event-likes"}><FavoriteIcon style={{fill:`${val.user_liked=='true'?'#D21A4E':'#fff'}`}}  onClick={() => updateLikes(key, val.slug)} /> {val.likes}</span>
                                    <a className={"event-share"} href='#!' onClick={() => props.handleShareBtnClick(`/blogs/articles/${val.slug}`)}><ShareIcon /></a>
                                </div>
                            </div>
                        </div>
                        <div className='event-content'>
                            <h4 className='event-title'><Link to={`/blogs/articles/${val.slug}`}>{val.title}</Link></h4>
                            <p className={`event-desc ${expandedSections[key]?'expended':''}`}>
                                {TruncateTextHtml(
                                    val.description,
                                    expandedSections[key] ? 50000 : 200
                                ) + " "}
                                {val.description &&
                                    val.description.length > 200 &&
                                    !expandedSections[key] && (
                                        <Link className='event-read' onClick={() => handleReadMoreClick(key)}>
                                            Read more
                                        </Link>
                                    )}
                                {val.description &&
                                    val.description.length > 200 &&
                                    expandedSections[key] && (
                                        <Link className='event-read' onClick={() => handleReadMoreClick(key)}>
                                            Read less
                                        </Link>
                                    )}
                            </p>
                        </div>
                    </div>
                </div>
            ))}
            {showAuthError && (
                <PopupMessage
                type="error"
                message="Authentication Failed!"
                desc={"Please log in to your account."}
                buttonTitle={"Log In"}
                handleClose={()=> {setShowAuthError(false)}}
                open={showAuthError}
                handleSubmit={()=> {navigate(`/login?next=${location.pathname}`)}}
                />
            )}
        </Fragment>
    )
}

export default ArticlesCard
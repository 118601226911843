import React, { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CollectionCard from "../components/CollectionCard";
import FlatBanner from "../components/FlatBanner";
import Navbar from '../components/Navbar';
import BnrImg1 from "../images/banners/overview-banner-img.webp";

import { useParams } from "react-router-dom";
import { GetReportDetails } from "../api/module";
import EQbitLineChart from "../components/Charts/LineChart";
import RadialbarChart from "../components/Charts/RadialBarChart";
import SpiderRadarChart from "../components/Charts/SpiderRadarChart";
import EQbitBarChart from "../components/Charts/barChart";
import Footer from "../components/Footer";
import ShareDialog from "../components/ShareModel";
import { useMediaQuery } from "@mui/material";

function OttInfographics(props) {
  const isSmall  = useMediaQuery('(max-width:380px)')
  const { slug } = useParams();
  const [shareDialogOpen, setShareDialogOpen] = useState(false);
  const [sharelink, setSharelink] = useState("");
  const [reportsData, setReportsData] = useState({});

  const getReportsData = () => {
    if (Object.keys(reportsData).length > 0) {
      return;
    }
    GetReportDetails(props.rootPath, slug)
      .then((response) => {
        setReportsData(response.data.report || {});
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    getReportsData();
  }, [slug]);

  const navigate = useNavigate();

  const handleRedirect = (movieSlug) => {
    navigate(`${props.rootPath}/details/${movieSlug}`);
  };

  const handleShareBtnClick = (path) => {
    const url = `${window.location.protocol}//${window.location.host}${path}`;
    setShareDialogOpen(true);
    setSharelink(url);
  };

  return (
    reportsData &&
    Object.keys(reportsData).length > 0 && (
      <Fragment>
        <Navbar />
        <section className="ott-collection-section">
          <FlatBanner
            title={reportsData?.title}
            image={reportsData?.image_url}
            img_alt={reportsData?.title}
          />
          <div className="collection-info-graph">
            <div className="collection-info-left">
              {reportsData?.chart?.type === "RadialBarChart" && (
                <div className={"chart-inner"}>
                  <RadialbarChart
                    data={reportsData?.chart?.data}
                    label={reportsData?.chart?.label}
                    height="350"
                  />
                </div>
              )}
              {reportsData?.chart?.type === "LineChart" &&
                reportsData?.chart?.data && (
                  <EQbitLineChart
                    data={reportsData?.chart?.data}
                    height="300"
                  />
                )}
              {reportsData?.chart?.type === "BarChart" &&
                reportsData?.chart?.data && (
                  <EQbitBarChart
                    data={reportsData?.chart?.data}
                    label={"Popularity Score"}
                    height="300"
                  />
                )}
              {reportsData?.chart?.type === "SpiderRadarChart" &&
                reportsData?.chart?.data && (
                  <div className={"chart-inner"}>
                  <SpiderRadarChart
                    data={reportsData?.chart?.data}
                    label={""}
                    height={isSmall?'220':'320'}
                    size={isSmall?80:120}
                  />
                  </div>
                )}
            </div>
            <div className="collection-info-right">
              {/* <h2 className='collection-info-title'>Viewer’s Take On Popular Shows Of June 2021</h2> */}
              <div className="collection-info-desc">
                <p>{reportsData?.summary}</p>
              </div>
            </div>
          </div>
          <div className="collection-items">
            {reportsData?.entries?.map((data, index) => (
              <CollectionCard
                rootPath={props.rootPath}
                key={index}
                data={data}
                handleRedirect={handleRedirect}
                handleShareBtnClick={handleShareBtnClick}
              />
            ))}
          </div>
        </section>
        <br />
        <ShareDialog
          selectedValue={sharelink}
          open={shareDialogOpen}
          onClose={() => {
            setShareDialogOpen(false);
            setSharelink("");
          }}
        />
        <Footer />
      </Fragment>
    )
  );
}

export default OttInfographics;

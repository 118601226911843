import { methodEnum } from "../utils/constant";
import request from "../utils/request";

const signUpUrl = "/auth/registration/";
const signInUrl = "/auth/login/";
const signOutUrl = "/auth/logout/";
const passwordResettUrl = "/auth/password/reset/";
const verifyEmailUrl = "/auth/registration/verify-email/";
const verifyGoogleLoginUrl = "/auth/google/";
const confirmOTPUrl = "/auth/registration/cofirm-otp/";
const changePassUrl = "/auth/password/reset/confirm/";

const Signup = async (data) => {
  return await request({
    url: `${signUpUrl}`,
    method: methodEnum.POST,
    data,
  });
};

const SignIn = async (data) => {
  return await request({
    url: `${signInUrl}`,
    method: methodEnum.POST,
    data,
  });
};

const SignOut = async () => {
  return await request({
    url: `${signOutUrl}`,
    method: methodEnum.GET,
  });
};

const PasswordReset = async (data) => {
  return await request({
    url: `${passwordResettUrl}`,
    method: methodEnum.POST,
    data,
  });
};

const VerifyEmail = async (data) => {
  return await request({
    url: `${verifyEmailUrl}`,
    method: methodEnum.POST,
    data,
  });
};

const VerifyOTPEmail = async (data) => {
  return await request({
    url: `${confirmOTPUrl}`,
    method: methodEnum.POST,
    data,
  });
};

const ChangePassword = async (data) => {
  return await request({
    url: `${changePassUrl}`,
    method: methodEnum.POST,
    data,
  });
};

const VerifyGoogleLogin = async (data) => {
    return await request({
      url: `${verifyGoogleLoginUrl}`,
      method: methodEnum.POST,
      data,
    });
  };

export { PasswordReset, SignIn, SignOut, Signup, VerifyEmail, VerifyGoogleLogin, VerifyOTPEmail, ChangePassword};


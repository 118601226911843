import qs from 'qs';
import React, { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Autoplay } from "swiper";
import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";
import { GetHomePostsData } from "../../api/home_api";
import { colorCode } from "../../utils/constant";
import GenerateImageUrl from "../../utils/utils";

function NewNotable(props) {
    const [postData, setPostData] = useState({});

    const UrlMap = {
        "movies": "/ott",
        "podcasts": "/podcast",
        "books": "/books",
        "games": "/games",
    }

    useEffect(() => {
        GetHomePostsData("New And Notable")
            .then((response) => {
                setPostData(response.data.result || {});
            })
            .catch((error) => {
                console.error(error);
            });
    }, []);

    return (
        <Fragment>
  <div className='new-notable-section'>
    <div className='new-notable-slider'>
      <Swiper
        slidesPerView={'auto'}
        spaceBetween={24}
        rewind={true}
        autoplay={{
          delay: 2000,
        }}
        modules={[Autoplay]}
        className="new-notable-swiper"
      >
        {Object.keys(postData).map((key, keyIdx) => (
          postData[key].map((val, index) => (
            <SwiperSlide className='new-notable-slide' key={`${key}_${index}`} onClick={() => console.log(postData)}>
              <div className='new-notable-slider-card'>
                <Link to={`${UrlMap[key]}/details/${val.slug}`}>
                <div className='new-notable-img'>
                  <img src={GenerateImageUrl(val?.photos?.Cards_Trending_Now_On_Hover, 0, 0, false)} alt='img1' />
                  <div className='new-notable-progress'
                    style={{
                      height: `${val.ebitsRating * 10}%`,
                      background: colorCode[(keyIdx + index) % colorCode.length]
                    }}>
                    <h6 className='pregress-value'>{val.ebitsRating}</h6>
                    <p className='pregress-title'>EQbits Rating</p>
                  </div>
                </div>
                </Link>
                <h4 className='new-notable-title'>{val.Title}</h4>
              </div>
            </SwiperSlide>
          ))
        ))}
      </Swiper>
    </div>
    <div className='new-notable-card'>
      <div className='new-notable-content'>
        <h2 className='theme-title'>New & <span>Notable</span></h2>
        <p>{props.paragraph}</p>
        <Link className='theme-btn' to={{
                                        pathname: `/books/content`,
                                        search: qs.stringify({"categories": ["New And Notable"]}),
                                    }} >Explore</Link>
      </div>
    </div>
  </div>
</Fragment>

    )
}

export default NewNotable
import React from 'react'

function FacebookIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
            <g clip-path="url(#clip0_2207_4242)">
                <path d="M24.5 12C24.5 17.9897 20.1116 22.9542 14.375 23.8542V15.4688H17.1711L17.7031 12H14.375V9.74906C14.375 8.79984 14.84 7.875 16.3306 7.875H17.8438V4.92188C17.8438 4.92188 16.4703 4.6875 15.1573 4.6875C12.4166 4.6875 10.625 6.34875 10.625 9.35625V12H7.57812V15.4688H10.625V23.8542C4.88844 22.9542 0.5 17.9897 0.5 12C0.5 5.37281 5.87281 0 12.5 0C19.1272 0 24.5 5.37281 24.5 12Z" fill="#1877F2" />
                <path d="M17.1711 15.4688L17.7031 12H14.375V9.74902C14.375 8.80003 14.8399 7.875 16.3306 7.875H17.8438V4.92188C17.8438 4.92188 16.4705 4.6875 15.1576 4.6875C12.4165 4.6875 10.625 6.34875 10.625 9.35625V12H7.57812V15.4688H10.625V23.8542C11.236 23.95 11.8621 24 12.5 24C13.1379 24 13.764 23.95 14.375 23.8542V15.4688H17.1711Z" fill="white" />
            </g>
            <defs>
                <clipPath id="clip0_2207_4242">
                    <rect width="24" height="24" fill="white" transform="translate(0.5)" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default FacebookIcon
import React, { Fragment, useRef, useState } from "react";
import ReadMoreReact from 'read-more-react';
import StarRateIcon from '@mui/icons-material/StarRate';
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { GetPastDaysFromNow} from "../../utils/utils"

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
// import "swiper/css/pagination";

// import required modules
import { Autoplay, Navigation } from "swiper";

export default function ReviewCardSlider(props) {
    const [isReadMore, setIsReadMore] = useState(null);
    return (
        <Fragment>
            <Swiper
                slidesPerView={"auto"}
                spaceBetween={36}
                autoplay={{
                  delay: 5000,
                  pauseOnMouseEnter: true
                }}
                navigation={true}
                modules={[Navigation, Autoplay]}
                breakpoints={{
                    340: {
                        slidesPerView: 1,
                        spaceBetween: 20,
                    },
                    768: {
                        slidesPerView: 2,
                        spaceBetween: 20,
                    },
                    1024: {
                        slidesPerView: 3,
                        spaceBetween: 20,
                    },
                    1300: {
                        slidesPerView: 4,
                        spaceBetween: 30,
                    },
                    1900: {
                        slidesPerView: 4,
                        spaceBetween: 48,
                    },
                    2000: {
                        slidesPerView: 5,
                        spaceBetween: 58,
                    },
                }}
                className="reviews-card-slider">
                {props.data.map((item, index) => (
                    <SwiperSlide key={index}>
                        <div className="review-card">
                            <div className="review-top-bar">
                                <div className="review-by">
                                    {/* <img className="review-by-logo" src={item.image} alt={item.user} /> */}
                                    <div className="review-by-title">
                                        <h4 className="review-user">{item.publication}</h4>
                                        <p className="review-sub-user">{item.authorName}</p>
                                    </div>
                                </div>
                                <div className="review-rate">
                                    <StarRateIcon />
                                    <span>{item.ratings}</span>
                                </div>
                            </div>
                            <div className="review-content">
                                <h4 className="review-title">Title: {item.title}</h4>
                                <div className="review-desc">
                                    {/* <ReadMoreReact className="review-desc" text={item.review}
                                        min={170}
                                        ideal={172}
                                        max={172}
                                        readMoreText="...Read More"/> */}
                                    <p onClick={()=>{setIsReadMore(null)}} className={`${isReadMore === index?'readmore':''}`}>{item.review}</p>
                                    <span className="readmore-btn" onClick={()=>{setIsReadMore(isReadMore===index?null:index)}}>{isReadMore === index?'Read less':'Read more'}</span>
                                </div>
                                <time>{GetPastDaysFromNow(item.dateTime)}</time>
                            </div>
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
        </Fragment>
    );
}

import React, { useState } from 'react'
import TDSlider from '../TDSlider'
import { Link } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
var qs = require('qs');

function Index(props) {
  const [selectedFilter, setSelectedFilter] = useState([]);
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 992px)' })
  const isDesktop = useMediaQuery({ query: '(min-width: 993px)' })
  const handleFilterChange = (name) => {
    const isSelected = selectedFilter.includes(name);
    if (isSelected) {
      setSelectedFilter(selectedFilter?.filter((i) => i !== name));
    } else {
      setSelectedFilter([...selectedFilter, name]);

    }
  }

  const handleShareBtnClick = (path) => {
    props.handleShareBtnClick(path)
  }

  return (
    <div className='ebits-treasure-section'>
      {isDesktop && <div className='theme-title-v2'>EQbits Treasure</div>}
      {isTabletOrMobile && <div className='title-section'>
        <div className='title'>
          <h3>EQbits Treasure</h3>
        </div>
        <Link className='view-all-btn' to={{
          pathname: `${props.rootPath}/content`,
          search: qs.stringify({ categories: ["Ebits_Treasure"] }),
        }}>View All</Link>
      </div>}
      {Object.keys(props.labelsData) > 0 && <ul className='ebits-treasure-categories'>
        {props.rootPath !== "/ott" && Object.keys(props.labelsData) > 0 && props.labelsData.map((val, key) => {
          return (
            <li className={selectedFilter.includes(val.name) ? `active` : ""} onClick={() => handleFilterChange(val.name)}>{val.name}</li>)
        })}
      </ul>}

      <div className='trd-slider-section'>
        <TDSlider rootPath={props.rootPath} selectedFilterCategory={selectedFilter} handleRedirect={props.handleRedirect} handleShareBtnClick={handleShareBtnClick} />
        {isDesktop && <Link className='view-all-btn' to={{
          pathname: `${props.rootPath}/content`,
          search: qs.stringify({ categories: ["Ebits_Treasure"] }),
        }}>View All</Link>}
      </div>
    </div>
  )
}

export default Index
import React, { Fragment, useState, useEffect} from 'react'
import { GetCommonImagesData } from '../../api/home_api';
import GenerateImageUrl from "../../utils/utils"

function ListingBanner(props) {
  const [bannerImage, setBannerImage] = useState("");

  let module = ""
  if (props.rootPath === "/ott") {
    module = "listing_ott"
  } else if (props.rootPath === "/books") {
    module = "listing_books"
  } else if (props.rootPath === "/podcast") {
    module = "listing_podcast"
  } else if (props.rootPath === "/games") {
    module = "listing_games"
  } else if (props.rootPath === "/blogs") {
    module = "listing_blogs"
  }

  useEffect(() => {
    GetCommonImagesData()
      .then((response) => {
        let data = {};
        for (let idx in (response.data.result || [])) {
          let item = response.data.result[idx];
          data[item.type_name] = {
            image_url: item.image_url,
          };
        }
        setBannerImage(data[module]?.image_url );
      })
      .catch((error) => {
        console.error(error);
      });
  }, [props.rootPath])

  return (
    <Fragment>
      <div className='pb-section'>
        <div className='pb-image'>
          <img className='img-fit' src={GenerateImageUrl(bannerImage, 0, 0, false)} alt={props.image_alt} />
        </div>
        <div className='pb-content'>
          <h1 className='pb-title'>{props.title}</h1>
          <h6 className='pb-subtitle'>{props.subtitle}</h6>
        </div>
      </div>
    </Fragment>
  )
}

export default ListingBanner
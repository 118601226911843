import React from 'react'

function TimeIcon() {
    return (
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.375 1.3125C4.375 1.19647 4.42109 1.08519 4.50314 1.00314C4.58519 0.921094 4.69647 0.875 4.8125 0.875H8.3125C8.42853 0.875 8.53981 0.921094 8.62186 1.00314C8.70391 1.08519 8.75 1.19647 8.75 1.3125C8.75 1.42853 8.70391 1.53981 8.62186 1.62186C8.53981 1.70391 8.42853 1.75 8.3125 1.75H4.8125C4.69647 1.75 4.58519 1.70391 4.50314 1.62186C4.42109 1.53981 4.375 1.42853 4.375 1.3125Z" fill="white" />
            <path d="M6.5625 4.375C6.44647 4.375 6.33519 4.42109 6.25314 4.50314C6.17109 4.58519 6.125 4.69647 6.125 4.8125V8.3125C6.125 8.42853 6.17109 8.53981 6.25314 8.62186C6.33519 8.70391 6.44647 8.75 6.5625 8.75C6.67853 8.75 6.78981 8.70391 6.87186 8.62186C6.95391 8.53981 7 8.42853 7 8.3125V4.8125C7 4.69647 6.95391 4.58519 6.87186 4.50314C6.78981 4.42109 6.67853 4.375 6.5625 4.375Z" fill="white" />
            <path d="M11.8125 7.875C11.8125 9.26739 11.2594 10.6027 10.2748 11.5873C9.29024 12.5719 7.95489 13.125 6.5625 13.125C5.17011 13.125 3.83476 12.5719 2.85019 11.5873C1.86562 10.6027 1.3125 9.26739 1.3125 7.875C1.3125 6.48261 1.86562 5.14726 2.85019 4.16269C3.83476 3.17812 5.17011 2.625 6.5625 2.625C7.95489 2.625 9.29024 3.17812 10.2748 4.16269C11.2594 5.14726 11.8125 6.48261 11.8125 7.875ZM10.9375 7.875C10.9375 6.71468 10.4766 5.60188 9.65609 4.78141C8.83562 3.96094 7.72282 3.5 6.5625 3.5C5.40218 3.5 4.28938 3.96094 3.46891 4.78141C2.64844 5.60188 2.1875 6.71468 2.1875 7.875C2.1875 9.03532 2.64844 10.1481 3.46891 10.9686C4.28938 11.7891 5.40218 12.25 6.5625 12.25C7.72282 12.25 8.83562 11.7891 9.65609 10.9686C10.4766 10.1481 10.9375 9.03532 10.9375 7.875Z" fill="white" />
            <path d="M10.8967 2.3113C10.8564 2.26951 10.8081 2.23618 10.7547 2.21325C10.7013 2.19032 10.6439 2.17826 10.5858 2.17775C10.5277 2.17725 10.4701 2.18832 10.4164 2.21031C10.3626 2.23231 10.3137 2.2648 10.2727 2.30588C10.2316 2.34695 10.1991 2.3958 10.1771 2.44957C10.1551 2.50334 10.144 2.56094 10.1445 2.61904C10.1451 2.67713 10.1571 2.73454 10.1801 2.78791C10.203 2.84129 10.2363 2.88957 10.2781 2.92992L11.5057 4.15755C11.5461 4.19933 11.5944 4.23266 11.6477 4.25559C11.7011 4.27852 11.7585 4.29059 11.8166 4.2911C11.8747 4.2916 11.9323 4.28053 11.9861 4.25853C12.0398 4.23653 12.0887 4.20405 12.1298 4.16297C12.1708 4.12189 12.2033 4.07305 12.2253 4.01928C12.2473 3.96551 12.2584 3.9079 12.2579 3.84981C12.2574 3.79172 12.2453 3.73431 12.2224 3.68093C12.1995 3.62756 12.1661 3.57928 12.1243 3.53892L10.8967 2.3113Z" fill="white" />
        </svg>
    )
}

export default TimeIcon
import React, { useState, useEffect } from 'react'
import { GradientCircularProgress } from "react-circular-gradient-progress";
import ShareIcon from '@mui/icons-material/Share';
import GenerateImageUrl, { TruncateText } from "../../utils/utils"
import { Link } from 'react-router-dom';

function PlatformButtonComponant(props) {
    if (!props?.value || props.value.length <= 0) {
        return
    }
    let data = props.value[0]
    let buttonTtile = `Watch on ${data.name}`
    switch (props.module) {
        case '/games':
            buttonTtile = `Play on ${data.platform}`
            break;
        case '/podcast':
            buttonTtile = `Listen on ${data.platform}`
            break;
        case '/books':
            buttonTtile = `Read on ${data.platform}`
            break;
        default:
            buttonTtile = `Watch on ${data.platform}`
            break;
    }
    return <Link className='btn btn-prime' to={data.platform_url} target="_blank" rel="noopener noreferrer">{buttonTtile}</Link>;
}


function CollectionCard(props) {
    const [progressBarGraphData, setProgressBarGraphData] = useState([]);
    const [reviewTextLength, setReviewTextLength] = useState(500);
    const [slug, setSlug] = useState(props.data.movie_slug);

    useEffect(() => {
        var data = []
        if (props.rootPath === "/ott") {
            data = [
                {
                    "title": "Story",
                    "value": props.data.aspect_story
                },
                {
                    "title": "Direction",
                    "value": props.data.aspect_direction
                },
                {
                    "title": "Performance",
                    "value": props.data.aspect_performance
                },
                {
                    "title": "Music",
                    "value": props.data.aspect_music
                }
            ]
            setProgressBarGraphData(data)
            setSlug(props.data.movie_slug)
        } else if (props.rootPath === "/books") {
            data = [
                {
                    "title": "Plot",
                    "value": props.data.aspect_plot
                },
                {
                    "title": "Characters",
                    "value": props.data.aspect_characters
                },
                {
                    "title": "Point Of View",
                    "value": props.data.aspect_pointOfView
                },
                {
                    "title": "Style Of Writing",
                    "value": props.data.aspect_styleOfWriting
                }
            ]
            setProgressBarGraphData(data)
            setSlug(props.data.book_slug)
        } else if (props.rootPath === "/podcast") {
            data = [
                {
                    "title": "Introduction",
                    "value": props.data.aspect_introduction
                },
                {
                    "title": "Content",
                    "value": props.data.aspect_content
                },
                {
                    "title": "Audio Quality",
                    "value": props.data.aspect_audioQuality
                },
                {
                    "title": "Voices",
                    "value": props.data.aspect_voices
                }
            ]
            setProgressBarGraphData(data)
            setSlug(props.data.podcast_slug)
        } else if (props.rootPath === "/games") {
            data = [
                {
                    "title": "Graphics",
                    "value": props.data.aspect_graphics
                },
                {
                    "title": "Performance",
                    "value": props.data.aspect_performance
                },
                {
                    "title": "Animation",
                    "value": props.data.aspect_animation
                },
                {
                    "title": "Ease Of Use",
                    "value": props.data.aspect_easeOfUse
                }
            ]
            setProgressBarGraphData(data)
            setSlug(props.data.games_slug)
        }
    }, [props.data])

    return (
        <div className='collection-card' onClick={()=>{console.log(props)}}>
            <div className='collection-image'>
                <img src={GenerateImageUrl(props.data.image, 0, 0, false)} alt={props.data.name} />
            </div>
            <div className='collection-card-content'>
                <h4 className='collection-title'>{props.data.name} <span>({props.data?.release_date?.split("-")[0]})</span></h4>
                <ul className='collection-item-categories'>
                    <li>{props.data.genres?.split(",").join(" | ")}</li>
                </ul>
                {props.data?.podcasters && <ul className='collection-item-categories'>
                    <li>Host : {props.data?.podcasters}</li>
                </ul>}
                <div className='collection-description '>
                    {TruncateText(
                        props.data.description,
                        reviewTextLength
                    ) + " "}
                    {props.data.description &&
                        props.data.description.length > 500 &&
                        reviewTextLength <= 500 && (
                            // eslint-disable-next-line jsx-a11y/anchor-is-valid
                            <a onClick={() => setReviewTextLength(50000)} style={{ cursor : 'pointer'}}>
                                Read more
                            </a>
                        )}
                    {props.data.description &&
                        props.data.description.length > 500 &&
                        reviewTextLength === 50000 && (
                            // eslint-disable-next-line jsx-a11y/anchor-is-valid
                            <a onClick={() => setReviewTextLength(500)} style={{ cursor : 'pointer'}}>
                                Read less
                            </a>
                        )}
                </div>
                <div className='btn-group'>
                    <PlatformButtonComponant value={props.data.platform_details} module={props.rootPath} />
                    <button className='btn' style={{ cursor: `${!slug ? '' : 'pointer'}`, color: `${!slug ? 'gray' : ''}`, borderColor: `${!slug ? 'gray' : ''}` }} onClick={() => props.handleRedirect(slug)} disabled={!slug || slug.trim() === ''} >View Details</button>
                    {/* <button className='btn btn-icon'><BookmarkBorderIcon /> Wishlist</button> */}
                    <button className='btn btn-icon' style={{ cursor: `${!slug ? '' : 'pointer'}`, color: `${!slug ? 'gray' : ''}`, borderColor: `${!slug ? 'gray' : ''}` }} onClick={() => props.handleShareBtnClick(`${props.rootPath}/details/${slug}`)} disabled={!slug || slug.trim() === ''} ><ShareIcon /> Share</button>
                </div>
            </div>
            <div className='collection-card-graph'>
                <div className='circle-graph-content'>
                    <GradientCircularProgress
                        animate={true}
                        startColor="#FE316C"
                        middleColor="#ED2860"
                        endColor="#A5002F"
                        emptyColor="rgba(255, 255, 255, 0.5)"
                        size={72}
                        progress={props.data.ebitsRatings * 10}
                    />
                    <div className='ebit-rate'>{props.data.ebitsRatings < 10 ? parseFloat(props.data.ebitsRatings).toFixed(1): props.data.ebitsRatings}</div>
                    <div className='ebit-rating'>EQbits Ratings</div>
                </div>
                {progressBarGraphData && <div className='progress-bar-chart'>
                    <ul className='progress-bars'>
                        {progressBarGraphData.map((val, key) => {
                            return (
                                val.value &&<li key={key}>
                                    <div className='bar-graph'>
                                        <div className='bar-filled' style={{ width: `${val.value}%` }}></div>
                                    </div>
                                    <p className='graph-title'>{val.title}</p>
                                </li>)
                        })}
                    </ul>
                </div>}
            </div>
            <div className='mobile-btns'>
                <div className="mobile-btn-group">
                    <PlatformButtonComponant value={props.data.platform_details} module={props.rootPath} />
                    <button className='btn' style={{ cursor: `${!slug ? '' : 'pointer'}`, color: `${!slug ? 'gray' : ''}`, borderColor: `${!slug ? 'gray' : ''}` }} onClick={() => props.handleRedirect(slug)} disabled={!slug || slug.trim() === ''} >View Details</button>
                </div>
                <div className="mobile-icon-group">
                    {/* <button className='btn btn-icon'><BookmarkBorderIcon /> Wishlist</button> */}
                    <button className='btn btn-icon' style={{ cursor: `${!slug ? '' : 'pointer'}`, color: `${!slug ? 'gray' : ''}`, borderColor: `${!slug ? 'gray' : ''}` }} onClick={() => props.handleShareBtnClick(`${props.rootPath}/details/${slug}`)} disabled={!slug || slug.trim() === ''} ><ShareIcon /></button>
                </div>
            </div>
        </div>
    )
}

export default CollectionCard
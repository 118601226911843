import React, { Fragment, useEffect, useState } from "react";
// import PropTypes from 'prop-types'
import Box from "@mui/material/Box";
import { useAuthUser, useIsAuthenticated, useSignOut } from "react-auth-kit";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";
import { SignOut } from "../../api/auth";
import { GetSearchData } from "../../api/home_api";
import { UserIcon, UserIconRed } from "../../images/icons/Icon";
import MenuIcon from "../../images/icons/mobileMenu";
import searchIcon from "../../images/icons/search-icon.svg";
import logo from "../../images/logo.svg";
import GenerateImageUrl from "../../utils/utils";
import SearchInput from "./Search";

function TopBar(props) {
  const [isDivVisible, setIsDivVisible] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchData, setSearchData] = useState({});
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const auth = useAuthUser();
  const signOut = useSignOut();
  const isAuthenticated = useIsAuthenticated();
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);
  const [setsearchPlaceHolder, setSetsearchPlaceHolder] = useState('movie, series, revies or EQbits special collections')
  const placeholderList =['movies','series','reviews','eqbits collections', 'books', 'podcasts']
  const [placeholderIndex, setPlaceholderIndex] = useState(0);

  // Use useEffect to update the placeholder every two seconds
  useEffect(() => {
    const interval = setInterval(() => {
      // Get the next placeholder from the list based on the index
      const nextPlaceholder = placeholderList[placeholderIndex];

      // Increment the index for the next iteration
      setPlaceholderIndex((prevIndex) => (prevIndex + 1) % placeholderList.length);

      // Update the state with the new placeholder
      setSetsearchPlaceHolder(nextPlaceholder);
    }, 2000);

    // Clear the interval when the component is unmounted or dependencies change
    return () => clearInterval(interval);
  }, [placeholderIndex]); // Empty dependency array ensures the effect runs only once on mount

  const UrlMap = {
    movies: "/ott",
    podcasts: "/podcast",
    books: "/books",
    games: "/games",
  };
  const handleOutsideClick = () => {
    setIsDivVisible(false);
  };
  const handleShowSearbar = () => {
    setIsDivVisible(true);
  };

  useEffect(() => {
    const delay = 0; // 1 second in milliseconds

    const timer = setTimeout(() => {
      if (searchText.length >= 3) {
        getSearchData(searchText);
      }
    }, delay);

    return () => clearTimeout(timer);
  }, [searchText]);

  const getSearchData = (keyword) => {
    GetSearchData(keyword)
      .then((response) => {
        setSearchData(response.data.result || {});
        // setIsDivVisible(true);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleLogout = () => {
    SignOut()
      .then((res) => {
        signOut();
      })
      .catch((error) => {
        signOut();
        console.error(error);
      });
  };
  const toggleDropdown = () => {
    setIsDropDownOpen(!isDropDownOpen);
  };
  const handleClearSearch=()=> {setSearchText(""); handleOutsideClick(); setSearchData({})}

  // const handleSearch=(text)=>{
  //   setSearchText(text)
  //   setIsDivVisible(false);
    
  //   }

  return (
    <Fragment>
      <nav className="eqbits-top-bar">
        <Link to={"/"} className="eqbits-logo">
        <div className="logo_wrapper">
          <img className="logo" src={logo} alt="EQBITS" />
        </div>
          <div className="eqbits-brand">
            EQbits
          </div>
        </Link>
        <SearchInput onOutsideClick={handleOutsideClick}>
          <div className={`eqbits-search ${isDivVisible ? "active" : ""}`}>
            <Box sx={{ display: "flex", alignItems: "flex-end" }}>
              <label htmlFor="input-with-search"><img src={searchIcon} alt="search" /></label>
              <input
                onClick={handleShowSearbar}
                className="input-with-search"
                id="input-with-search"
                placeholder={`Search for ${setsearchPlaceHolder}`}
                // defaultValue={searchText}
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
              />
              <button
                className="search-clear"
                onClick={handleClearSearch}
              >
                <svg
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11 11L1 1"
                    stroke="#BBBBBB"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M1 11L11 1"
                    stroke="#BBBBBB"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </button>
            </Box>
            {isDivVisible && (
              <div className="search-item-container">
                  {<div className="search-result" onClick={handleOutsideClick}>
                  <ul className="search-item-list">
                    {Object.keys(searchData).map((rootKey) => (
                      searchData[rootKey].map((data) => (
                        <li className="search-item" key={data.slug}>
                          <Link
                            to={`${UrlMap[rootKey]}/details/${data.slug}`}
                          >
                            <div className="search-content">
                              <img
                                className=""
                                src={GenerateImageUrl(
                                  data.thumbnail_image_url,
                                  0,
                                  0,
                                  false
                                )}
                                alt={data.slug}
                              />
                              <div className="search-right">
                                <h6 className="search-item-title">
                                  {data.slug}
                                </h6>
                                <p>
                                  {data.release_date?.split("-")[0]} |{" "}
                                  {data.genres && data.genres.join("/")} | {data.duration}
                                </p>
                              </div>
                            </div>
                          </Link>
                        </li>
                      ))
                    ))}
                  </ul>
                </div>}
              </div>
            )}
          </div>
        </SearchInput>
        <div className="eqbits-menu-side">
          <ul className="drop-menu-list">
            {/* <li></li> */}
            <li className="drop-menu">
              {isAuthenticated && auth() && auth().name ? (
                <div className="dropdown-menu">
                  <Link onClick={toggleDropdown}>
                    <UserIconRed /> <span>{auth()?.name}</span>
                    {isDropDownOpen && (
                      <div className="dropdown-menu-item">
                        <ul className="dropdown-menu-list">
                          {isTabletOrMobile && <li><Link to="/#!">{auth()?.name}</Link></li>}
                          <li><Link onClick={handleLogout}>Logout</Link></li>
                        </ul>
                      </div>
                    )}
                  </Link>
                </div>
              ) : (
                  <Link to={"/login"} className="user-logout">
                  <UserIcon /> <span>Login</span>
                </Link>
              )}
            </li>
            {isTabletOrMobile === true && (
              <li className="drop-menu" onClick={props.handleMenu}>
                <MenuIcon />
              </li>
            )}
          </ul>
        </div>
      </nav>
    </Fragment>
  );
}

TopBar.propTypes = {};

export default TopBar;

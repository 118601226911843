import React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";

import Home from "./main-component/Home";
import NotFound from "./main-component/NotFound";
// OTT listing ------------------
import Ott from "./main-component/Ott";
import OttCollections from "./main-component/OttCollections";
import OttDetail from "./main-component/OttDetail";
import OttInfographics from "./main-component/OttInfographics";
import OttListing from "./main-component/OttListing";
import ForgotPassword from "./main-component/auth/forgotPassword";
import Login from "./main-component/auth/login";
import OneTimePassword from "./main-component/auth/oneTimePassword";
import NewPassword from "./main-component/auth/setNewPassword";
import SignUp from "./main-component/auth/signup";
import Blogs from "./main-component/blogs";
import Blogsdetail from "./main-component/blogs/detail";
import Contact from "./main-component/contact";
import About from "./main-component/about";
import GoogleCallback from "./main-component/auth/googleCallback";
import ScrollToTop from "./utils/ScrollToTop";

const AllRoute = () => {
  return (
    <div className="App">
      <Router>
      <ScrollToTop />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/ott" element={<Ott rootPath="/ott" />} />
          <Route
            path="/ott/:slug"
            element={
              <OttListing
                rootPath="/ott"
                title="Movies & Series"
                subtitle="Search for the perfect movie that suits your taste ..."
              />
            }
          />
          <Route
            path="/ott/details/:slug"
            element={<OttDetail rootPath="/ott" />}
          />
          <Route
            exact
            path="/ott/collections/:slug"
            element={<OttCollections rootPath="/ott" />}
          />
          <Route
            exact
            path="/ott/info-graphics/:slug"
            element={<OttInfographics rootPath="/ott" />}
          />

          <Route exact path="/podcast" element={<Ott rootPath="/podcast" />} />
          <Route
            path="/podcast/:slug"
            element={
              <OttListing
                rootPath="/podcast"
                title="Podcasts"
                subtitle="Search for the perfect podcast that suits your taste ..."
              />
            }
          />
          <Route
            path="/podcast/details/:slug"
            element={<OttDetail rootPath="/podcast" />}
          />
          <Route
            exact
            path="/podcast/collections/:slug"
            element={<OttCollections rootPath="/podcast" />}
          />
          <Route
            exact
            path="/podcast/info-graphics/:slug"
            element={<OttInfographics rootPath="/podcast" />}
          />

          <Route exact path="/books" element={<Ott rootPath="/books" />} />
          <Route
            path="/books/:slug"
            element={
              <OttListing
                rootPath="/books"
                title="Books"
                subtitle="Search for the perfect book that suits your taste ..."
              />
            }
          />
          <Route
            path="/books/details/:slug"
            element={<OttDetail rootPath="/books" />}
          />
          <Route
            exact
            path="/books/collections/:slug"
            element={<OttCollections rootPath="/books" />}
          />
          <Route
            exact
            path="/books/info-graphics/:slug"
            element={<OttInfographics rootPath="/books" />}
          />

          <Route exact path="/games" element={<Ott rootPath="/games" />} />
          <Route
            path="/games/:slug"
            element={
              <OttListing
                rootPath="/games"
                title="Games"
                subtitle="Search for the perfect game that suits your taste ..."
              />
            }
          />
          <Route
            path="/games/details/:slug"
            element={<OttDetail rootPath="/games" />}
          />
          <Route
            exact
            path="/games/collections/:slug"
            element={<OttCollections rootPath="/games" />}
          />
          <Route
            exact
            path="/games/info-graphics/:slug"
            element={<OttInfographics rootPath="/games" />}
          />
          <Route exact path="/blogs" element={<Blogs />} />
          <Route exact path="/blogs/:slug" element={<Blogsdetail />} />
          <Route exact path="/contact-us" element={<Contact />} />
          <Route exact path="/about-us" element={<About />} />
          <Route exact path="/blogs/:blogSlug/:slug" element={<Blogsdetail />} />
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/signup" element={<SignUp />} />
          <Route exact path="/forgot-password" element={<ForgotPassword />} />
          <Route exact path="/change-password" element={<NewPassword />} />
          <Route exact path="/otp-verify" element={<OneTimePassword />} />
          <Route exact path="/accounts/google/login/callback" element={<GoogleCallback />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </div>
  );
};

export default AllRoute;

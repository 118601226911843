import React from 'react'
import GenerateImageUrl from '../../utils/utils';
import DOMPurify from 'dompurify';
import { Sanitize } from '../../utils/sanitizeHtml';

function Articles(props) {
  return (
    <div className='blogs-container'>
      <div className='article-content'>
        {props.data?.descriptions?.map((item) => (
          <div className='content' key={item} >
          <div className='desc' dangerouslySetInnerHTML={{ __html: Sanitize(item?.description) }}></div>
            <br />
            <img src={GenerateImageUrl(item?.desc_image_url, 0, 0, false)} />
            {item?.desc_image_url !== null ? <br /> : null}
          </div>
        ))}

      </div>
    </div>
  )
}

export default Articles

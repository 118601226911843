import React, { Fragment, useState, useEffect } from "react";
import Navbar from '../components/Navbar';
import FlatBanner from "../components/FlatBanner";
import CollectionCard from "../components/CollectionCard";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import { GetCollentionsDetails } from "../api/module";
import GenerateImageUrl from "../utils/utils";
import Footer from "../components/Footer";
import ShareDialog from "../components/ShareModel";

function OttCollections(props) {
  const { slug: urlSlug } = useParams();

  const [collectionData, setCollectionData] = useState({});
  const [shareDialogOpen, setShareDialogOpen] = useState(false);
  const [sharelink, setSharelink] = useState("");

  useEffect(() => {
    GetCollentionsDetails(props.rootPath, urlSlug)
      .then((response) => {
        setCollectionData(response.data.collection[0] || {});
      })
      .catch((error) => {
        console.error(error);
      });
  }, [urlSlug, props.rootPath]);

  const navigate = useNavigate();

  const handleRedirect = (movieSlug) => {
    navigate(`${props.rootPath}/details/${movieSlug}`);
  };

  const handleShareBtnClick = (path) => {
    const url = `${window.location.protocol}//${window.location.host}${path}`;
    setShareDialogOpen(true);
    setSharelink(url);
  };

  return (
    <Fragment>
      <Navbar />
      <section className="ott-collection-section">
        <FlatBanner
          title={collectionData.name}
          image={GenerateImageUrl(collectionData.image_url, 0, 0, false)}
          img_alt={collectionData.name}
        />
        <div className="collection-content">
          <p className="collection-paragraph">{collectionData.description}</p>
        </div>
        <div className="collection-items">
          {collectionData?.entries &&
            collectionData.entries.map((data, index) => (
              <CollectionCard
                key={index}
                data={data}
                handleRedirect={handleRedirect}
                rootPath={props.rootPath}
                handleShareBtnClick={handleShareBtnClick}
              />
            ))}
        </div>
      </section>
      <br />
      <ShareDialog
        selectedValue={sharelink}
        open={shareDialogOpen}
        onClose={() => {
          setShareDialogOpen(false);
          setSharelink("");
        }}
      />
      <Footer />
    </Fragment>
  );
}

export default OttCollections;

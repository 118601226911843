import React, { Fragment, useEffect, useState } from "react";
import BlogBanner from "../../components/BlogBanner";
import Navbar from '../../components/Navbar';
import Footer from "../../components/Footer";

import { useParams } from "react-router-dom";
import { GetBlogsDetail } from "../../api/blog";
import Articles from "../../components/BlogContent/articles";
import Events from "../../components/BlogContent/events";
import Interview from "../../components/BlogContent/interview";
import GenerateImageUrl, {GetFormatedDate} from "../../utils/utils";

function Blogsdetail(props) {
  const { blogSlug, slug } = useParams();
  const [blogsData, setBlogsData] = useState({});
  const [events, setEvents] = useState([]);

  useEffect(() => {
    GetBlogsDetail(blogSlug, slug)
      .then((response) => {
        setBlogsData(response.data.result || {});
        getEvents();
      })
      .catch((error) => {
        console.error(error);
      });
  }, [blogSlug, slug]);

  useEffect(() => {
    getEvents();
  }, [blogsData]);

  const getEvents = () => {
    const eventList = [];

    if (blogsData?.program_name1 != null || blogsData?.program_time1 != null) {
      eventList.push({
        title: blogsData?.program_name1,
        date: blogsData?.program_time1,
      });
    }
    if (blogsData?.program_name2 != null || blogsData?.program_time2 != null) {
      eventList.push({
        title: blogsData?.program_name2,
        date: blogsData?.program_time2,
      });
    }
    if (blogsData?.program_name3 != null || blogsData?.program_time3 != null) {
      eventList.push({
        title: blogsData?.program_name3,
        date: blogsData?.program_time3,
      });
    }
    setEvents(eventList);
  };
  return (
    <Fragment>
      <Navbar />
      <section className="blogs-detail-section">
        <BlogBanner
          banner={GenerateImageUrl(blogSlug === "articles"? blogsData.banner_image_url : blogsData.bannerImage_url , 0, 0, false)}
          title={blogsData?.title}
          tagline={blogsData?.publish_date? GetFormatedDate(blogsData?.publish_date):""}
          bannerType={blogSlug}
          infoImg={GenerateImageUrl(blogsData.thumbnailImage_url, 0, 0, false)}
          infoTitle={blogsData.organiser_name}
          infoTag={blogsData.organiser_profile}
          venue={blogsData.venue}
          eventList={events}
        />
        {blogSlug === "articles" && Object.keys(blogsData).length > 0 && (
          <Articles data={blogsData} />
        )}
        {blogSlug === "events" && Object.keys(blogsData).length > 0 && (
          <Events data={blogsData} />
        )}
        {blogSlug === "interviews" && Object.keys(blogsData).length > 0 && (
          <Interview data={blogsData} />
        )}
      </section>
      <Footer />
    </Fragment>
  );
}

export default Blogsdetail;

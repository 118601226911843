import { methodEnum } from "../utils/constant";
import request from "../utils/request";

const ArticleUrl = "/blogs/";

const GetBlogsData = async (event, limit=10, offset=0) => {
  return await request({
    url: `${ArticleUrl}${event}/?limit=${limit}&offset=${offset}`,
    method: methodEnum.GET,
  });
};

const GetBlogsDetail = async (event, slug) => {
  return await request({
    url: `${ArticleUrl}${event}/${slug}`,
    method: methodEnum.GET,
  });
};

const AddBlogsLike = async (event, data) => {
  return await request({
    url: `${ArticleUrl}${event}/addlikes/`,
    method: methodEnum.POST,
    data,
  });
};

export { GetBlogsData, GetBlogsDetail, AddBlogsLike };

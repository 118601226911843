import React, { useState, useEffect, Fragment } from "react";
import Navbar from '../../components/Navbar';
import InfiniteScroll from "react-infinite-scroll-component";
import Footer from "../../components/Footer";
import ListingBanner from "../../components/ListingBanner";
import bannerImg from "../../images/banners/events-banner-img.png";

import { GetBlogsData } from "../../api/blog";
import ArticlesCard from "../../components/BlogsCard/articles";
import EventsCard from "../../components/BlogsCard/events";
import InterviewsCard from "../../components/BlogsCard/interviews";
import ShareDialog from "../../components/ShareModel";
import { useLocation } from "react-router-dom";
import { ResetTvRounded } from "@mui/icons-material";

const Blogs = (props) => {
  const [blogsData, setBlogsData] = useState([]);
  const [shareDialogOpen, setShareDialogOpen] = useState(false);
  const [sharelink, setSharelink] = useState("");
  const [offset, setOffset] = useState(0);
  const [totalEntries, setTotalEntries] = useState(0);
  const [limit] = useState(20);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const type = queryParams.get("type");  
  const [activeItem, setActiveItem] = useState(type?type:'articles');

  useEffect(() => {
    setOffset(0);
    setBlogsData([])
    window.scrollTo({ top: 0, behavior: 'instant' }); // Scroll to top
    getBlogsData(0);
  }, [activeItem]);
  
  useEffect(() => {
    setActiveItem(type?type:'articles')
  }, [location])
  
  const onClickTab = (blog) => {
    setActiveItem(blog);
  };
  const getBlogsData = (offset) => {
    setBlogsData([])
    GetBlogsData(activeItem, limit, offset)
      .then((response) => {
        let data = response.data.result || [];
        
        setBlogsData(data);
        setTotalEntries(response.data.totalEntries);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getNextModuleData = () => {
    let updatedOffset = offset;
    if (updatedOffset + limit < totalEntries + limit) {
      updatedOffset += limit;
    }
    setOffset(updatedOffset);
    GetBlogsData(activeItem, limit, updatedOffset)
      .then((response) => {
        let data = response.data.result || [];
        const combinedBlogsData = blogsData
          .concat(data)
          .filter(
            (obj, index, self) =>
              index === self.findIndex((item) => item.slug === obj.slug)
          );
        setBlogsData(combinedBlogsData);
        setTotalEntries(response.data.totalEntries);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleShareBtnClick = (path) => {
    const url = `${window.location.protocol}//${window.location.host}${path}`;
    setShareDialogOpen(true);
    setSharelink(url);
  };

  return (
    <Fragment>
      <Navbar />
      <section className="blogs-section">
        <ListingBanner
          title={
            activeItem === "articles"
              ? "Articles"
              : activeItem === "events"
              ? "Events Buzz"
              : activeItem === "interviews"
              ? "Interviews"
              : null
          }
          image={bannerImg}
          image_alt={""}
          subtitle={"HERE'S HOW WE HELP YOU FIND STUFF YOU'LL LOVE…"}
          rootPath={"/blogs"}
        />
        <div className="blogs-grid-container">
          <ul className="blogs-item-menu">
            <li
              className={`${activeItem === "articles" ? "active" : ""}`}
              onClick={() => onClickTab("articles")}
            >
              Featured Content
            </li>
            <li
              className={`${activeItem === "events" ? "active" : ""}`}
              onClick={() => onClickTab("events")}
            >
              Events Buzz
            </li>
            <li
              className={`${activeItem === "interviews" ? "active" : ""}`}
              onClick={() => onClickTab("interviews")}
            >
              Conversations
            </li>
          </ul>
          <InfiniteScroll
            dataLength={blogsData.length}
            next={getNextModuleData}
            hasMore={true}
            loader={<h4>Loading...</h4>}
          >
            <div className="blogs-items">
              {activeItem === "articles" && (
                <ArticlesCard
                  blogsData={blogsData}
                  setBlogsData={setBlogsData}
                  handleShareBtnClick={handleShareBtnClick}
                />
              )}
              {activeItem === "events" && (
                <EventsCard
                  blogsData={blogsData}
                  setBlogsData={setBlogsData}
                />
              )}
              {activeItem === "interviews" && (
                <InterviewsCard
                  blogsData={blogsData}
                  setBlogsData={setBlogsData}
                />
              )}
            </div>
          </InfiniteScroll>
          <div className="grid-paging">
            <div className="grid-item-count">
              Showing {blogsData.length} results
            </div>
          </div>
        </div>
        <ShareDialog
          selectedValue={sharelink}
          open={shareDialogOpen}
          onClose={() => {
            setShareDialogOpen(false);
            setSharelink("");
          }}
        />
      </section>
      <Footer />
    </Fragment>
  );
};

export default Blogs;

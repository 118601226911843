import React, { Fragment, useEffect, useState } from 'react'
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
// images --------------------------------------
import bannerImg from "../images/banners/aboutus-banner.png";
import img1 from '../images/about/img1.gif';
import img2 from '../images/about/img2.gif';
import img3 from '../images/about/img3.gif';
import img4 from '../images/about/img4.gif';
import img5 from '../images/about/img5.gif';
import img6 from '../images/about/img6.gif';
import img7 from '../images/about/img7.gif';
import { Link } from 'react-router-dom';
function About() {
    const [path, setPath] = useState('');

    useEffect(() => {
      const paths = ['ott', 'games', 'podcast', 'books'];
      const randomIndex = Math.floor(Math.random() * paths.length);
      const randomPath = paths[randomIndex];
      setPath(randomPath);
    }, []); // Empty dependency array to run this effect only once on component mount
      
    return (
        <Fragment>
            <Navbar />
            <div className='aboutus-section'>
                <div className='pb-section'>
                    <div className='pb-image'>
                        <img className='img-fit' src={bannerImg} alt="About E-bits" />
                    </div>
                    <div className='pb-content'>
                        <h1 className='pb-title'>About E-bits</h1>
                        <h6 className='pb-subtitle'>HERE'S HOW WE HELP YOU FIND STUFF YOU'LL LOVE…</h6>
                    </div>
                </div>
                <div className='aboutus-container'>
                    <div className='about-card'>
                        <div className='about-content'>
                            <h4 className='about-content-title'>Confused with too many reviews and opinions ?</h4>
                            <p className='about-description'>E-bits to the save! We are the games, podcasts, movies, and TV recommender system with a bird eye view on every popular online content and help you choose the best.
                            </p>
                        </div>
                        <div className='about-image'>
                            <img src={img1} alt='' />
                        </div>
                    </div>
                    <div className='about-card'>
                        <div className='about-image'>
                            <img src={img2} alt='' />
                        </div>
                        <div className='about-content'>
                            <h4 className='about-content-title'>EQbits to the Rescue!!!</h4>
                            <p className='about-description'>Our AI, NLP algorithms collects and process the data, comments, reactions, sentiments from real users from all over the world posted on social media and other platforms to give you a single score so that you can choose wisely and easily. <br />We dont stop just at this, further more we provide you with the analysis based on direction, story, performance, music, etc.</p>
                            <Link to={'/ott/content?layout=list'} className='button'>Check out E-bits Score</Link>
                        </div>
                    </div>
                    <div className='about-card'>
                        <div className='about-content'>
                            <h4 className='about-content-title'>Explore Tailormade Collections and Blogs </h4>
                            <p className='about-description'>Compare what's new this week, what's great this year, and all-time favorites.
                                Finding quality entertainment has never been so easy....all thanks to the E-bits scorecard.</p>
                            <Link to={`/${path}`} className='button'>Explore Collections</Link>
                        </div>
                        <div className='about-image'>
                            <img src={img3} alt='' />
                        </div>
                    </div>
                    <div className='about-card'>
                        <div className='about-image'>
                            <img src={img4} alt='' />
                        </div>
                        <div className='about-content'>
                            <h4 className='about-content-title'>User Ratings</h4>
                            <p className='about-description'>We value our users' opinions. In addition to streamlining our user voting process, we've elevated the the status of the average user score throughout the site.</p>
                            <Link to={'/ott/content'} className='button'>Rate your favourite Content</Link>
                        </div>

                    </div>
                    <div className='about-card'>

                        <div className='about-content'>
                            <h4 className='about-content-title'>Critic Reviews & Ratings</h4>
                            <p className='about-description'>We Provide you with all the critic reviews and ratings from all the renowned medias, to help you choose the content</p>
                        </div>
                        <div className='about-image'>
                            <img src={img5} alt='' />
                        </div>
                    </div>
                    <div className='about-card'>
                        <div className='about-image'>
                            <img src={img6} alt='' />
                        </div>
                        <div className='about-content'>
                            <h4 className='about-content-title'>Promote to the perfect audience </h4>
                            <p className='about-description'>Wanna promote your channel for movies,TV, games or podcasts ? E-bits reaches out to wider spectra of viewers. Hence, an ideal medium to promote your channels.</p>
                            <Link to={'/contact-us'} className='button'>Contact us for more</Link>
                        </div>

                    </div>
                    <div className='about-card'>

                        <div className='about-content'>
                            <h4 className='about-content-title'>Events, Interviews & Articles</h4>
                            <p className='about-description'>We Provide you with all the critic reviews and ratings from all the renowned medias, to help you choose the content</p>
                            <Link to={'/blogs'} className='button'>Explore</Link>
                        </div>
                        <div className='about-image'>
                            <img src={img7} alt='' />
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </Fragment>
    )
}

export default About
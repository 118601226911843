import Cookies from "js-cookie";

const TokenKey = "_auth";

// -----------------  Token  ----------------
export default function IsAuthenticated() {
  if (Cookies.get(TokenKey) !== undefined && Cookies.get(TokenKey) !== null) {
    return true;
  }
  return false;
}

export function GetToken() {
  return `Token ${Cookies.get(TokenKey)}`;
}

export function RemoveToken() {
  return Cookies.remove(TokenKey);
}

export function RemoveAllCookies() {
  const cookies = Cookies.get();

  // Iterate over each cookie and remove it
  Object.keys(cookies).forEach((cookieName) => {
    Cookies.remove(cookieName);
  });
}

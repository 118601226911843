import React from 'react'
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import ReactPlayer from 'react-player/youtube'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "fit-content",
    boxShadow: 24,
};

function VideoPlayer({ videoUrl, closeModal }) {
    const [open, setOpen] = React.useState(true);
    const handleClose = () => (setOpen(false), closeModal(''));
    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            style={{
                background: '#03060ac9'
            }}
        >
            <Box sx={style}>
                <div className="video-popup">
                {videoUrl !== '' && <ReactPlayer pip={true} controls={true} url={videoUrl} />}
                </div>
            </Box>
        </Modal>
    )
}

export default VideoPlayer
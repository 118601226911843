function MenuIcon() {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="17" viewBox="0 0 20 17" fill="none">
                <rect y="0.996094" width="20" height="2" rx="1" fill="#C4C4C4" />
                <rect x="4" y="7.99609" width="16" height="2" rx="1" fill="#C4C4C4" />
                <rect x="8" y="14.9961" width="12" height="2" rx="1" fill="#C4C4C4" />
            </svg>
        </>
    )
}
export default MenuIcon
import React from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/pagination";

import { Link } from 'react-router-dom';
import GenerateImageUrl from "../../utils/utils"
import CollectionSliderCard from '../CollectionSliderCard/CollectionSliderCard';


function index(props) {


  const UrlMap = {
    "movie": "/ott",
    "podcast": "/podcast",
    "book": "/books",
    "game": "/games",
  }

  return (
    <div className='collection-slider'>
      <Swiper
        slidesPerView={"auto"}
        spaceBetween={36}
        autoplay={{
          delay: 5000,
          pauseOnMouseEnter: true
        }}
        breakpoints={{
          320: {
            spaceBetween: 16,
          },
          1024: {
            spaceBetween: 24,
          },
        }}
        modules={[Autoplay]}
        className="mySwiper"
      >
        {props.collentionsData && props.collentionsData.map((item, index) => (
          <SwiperSlide className='collection-slide' data-swiper-autoplay="2000" onClick={() => props.handleShowCollection(index,item.slug)}>
            <CollectionSliderCard item={item} rootpath={props.rootpath} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  )
}

index.propTypes = {}

export default index

import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

import GenerateImageUrl, { TruncateText, TruncateTextHtml } from '../../utils/utils';

function InterviewsCard(props) {
    return (
        <Fragment>
            {props.blogsData.map((val, key) => (
                <div className='event-col' key={key}>
                    <div className='event-card interview'>
                        <div className='event-image'>
                            <img src={GenerateImageUrl(val.thumbnailImage_url, 0, 0, false)} alt={val.title} />
                            <h5 className='event-subtitle'>Interview</h5>
                        </div>
                        <div className='event-contents'>
                            <h4 className='event-title'><Link to={`/blogs/interviews/${val.slug}`}>{val.title}</Link></h4>
                            <div className='event-desc'>
                                <p> {TruncateTextHtml(val.introduction, 50)}</p>
                            </div>
                            <Link to={`/blogs/interviews/${val.slug}`} className='event-read'>Read More</Link>
                        </div>
                    </div>
                </div>
            ))}
        </Fragment>

    )
}

export default InterviewsCard
import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import StarIcon from '@mui/icons-material/Star';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import ReactPlayer from 'react-player/youtube'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ListModuleData } from "../../api/module";
import GenerateImageUrl from "../../utils/utils"
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
var qs = require('qs');


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // width: "500px",
    boxShadow: 24,
    p: 4,
};

const UpcomingMovies = (props) => {
    const [nav1, setNav1] = useState(null);
    const [nav2, setNav2] = useState(null);
    const [slider1, setSlider1] = useState(null);
    const [slider2, setSlider2] = useState(null);
    const [videoUrl, setVideoUrl] = useState("");
    const [open, setOpen] = React.useState(false);
    const handleOpen = (trailer) => (setOpen(true), setVideoUrl(trailer));
    const handleClose = () => (setOpen(false), setVideoUrl(''));
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' })

    useEffect(() => {
        setNav1(slider1);
        setNav2(slider2);
    }, [slider1, slider2]);

    const settingsMain = {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        infinite:false,
        dots: false,
        fade: true,
        asNavFor: '.slider-nav'
    };
    let settingsThumbs;
     if(isTabletOrMobile){
        settingsThumbs = {
            slidesToShow: 3,
            slidesToScroll: 1,
            asNavFor: '.slider-for',
            dots: false,
            infinite: false,
            arrows: false,
            centerMode: false,
            swipeToSlide: true,
            focusOnSelect: true,
            centerPadding: '36px',
            responsive: [
                {
                  breakpoint: 768,
                  settings: {
                    slidesToShow: 4,
                    centerPadding: '16px',
                  }
                },
                {
                  breakpoint: 480,
                  settings: {
                    slidesToShow: 3,
                    centerPadding: '16px',
                  }
                },
                {
                  breakpoint: 380,
                  settings: {
                    slidesToShow: 2.5,
                    centerPadding: '16px',
                  }
                },
                {
                  breakpoint: 320,
                  settings: {
                    slidesToShow: 2,
                    centerPadding: '16px',
                  }
                },
            ]
        };
     }else{
        settingsThumbs = {
            slidesToShow: 3,
            slidesToScroll: 1,
            asNavFor: '.slider-for',
            dots: false,
            infinite: false,
            arrows: false,
            centerMode: false,
            swipeToSlide: true,
            focusOnSelect: true,
            centerPadding: '36px',
            responsive: [
                {
                  breakpoint: 1599,
                  settings: {
                    slidesToShow: 3,
                    centerPadding: '16px',
                  }
                },
                {
                  breakpoint: 1280,
                  settings: {
                    slidesToShow: 1.5,
                    centerPadding: '16px',
                  }
                },
                {
                  breakpoint: 1024,
                  settings: {
                    slidesToShow: 1.5,
                    centerPadding: '16px',
                  }
                },
                {
                  breakpoint: 992,
                  settings: {
                    slidesToShow: 1,
                    centerPadding: '16px',
                  }
                },
                {
                  breakpoint: 768,
                  settings: {
                    slidesToShow: 1,
                    centerPadding: '16px',
                  }
                },
                {
                  breakpoint: 992,
                  settings: {
                    slidesToShow: 1,
                    centerPadding: '16px',
                  }
                },
            ]
        };
     }

    const [moviesData, setMoviesData] = useState([]);

    useEffect(() => {
        let requestBody = {
            categories: ["Upcoming"],
            offsetRange: { "offset": 0, "limit": 20 }
        };
        ListModuleData(props.rootPath, requestBody)
            .then((response) => {
                let data = []
                if (props.rootPath === "/podcast") {
                    data = response.data.podcasts;
                } else if (props.rootPath === "/books") {
                    data = response.data.books;
                } else if (props.rootPath === "/games") {
                    data = response.data.games;
                } else if (props.rootPath === "/ott") {
                    data = response.data.movies;
                }

                setMoviesData(data || []);
            })
            .catch((error) => {
                console.error(error);
            });
    }, []);

    return (
        <div className='upcomming-movies-section' >
            <div className="section-heading">
                <h3 className="title">Upcoming</h3>
                {isTabletOrMobile && <Link className='view-all-btn' to={{
                    pathname: `${props.rootPath}/content`,
                    search: qs.stringify({"categories": ["New Releases"]}),
                }}>View All</Link>}
            </div>
            <Slider
                className="slider-thumb-multi"
                {...settingsMain}
                asNavFor={nav2}
                ref={slider => (setSlider1(slider))}
            >
                {moviesData.map((slide, key) =>
                    <div className="upcomming-slide" key={key}>
                        <img className="upcomming-slide-image" src={`${GenerateImageUrl(slide.photos?.Banner_Upcoming_Movies, 1690, 686)}`} alt="" />
                        <div className="upcomming-slider-meta">
                            <div className="upcomming-slide-title" onClick={() => props.handleRedirect(slide.slug)}><span className="title">{slide.Title}</span> <div className="rating"><StarIcon /> {slide.ebitsRating}</div></div>
                            {slide?.genres && <ul className='upcomming-movies-categaries'>
                                {slide?.genres && slide.genres.map((val, key) => {
                                    return (<li key={key}>{val}</li>)
                                })}
                            </ul>}
                            <div className="btn-group">
                                <button className="btn btn-play" onClick={() => handleOpen(slide.trailers[0])}><PlayCircleIcon /> Play Trailer</button>
                                {/* <button className="btn btn-notify"><AccessTimeFilledIcon /> Notify Me</button> */}
                            </div>
                        </div>
                    </div>
                )}
            </Slider>
            {
                moviesData.length > 1 &&
                (<div className="thumbnail-slider-upcomming">
                    <div className="thumbnail-slider-card">
                        <div className="thumbnail-upcomming-title">New trailer releases</div>
                        <Slider
                            className="slider-thumb"
                            {...settingsThumbs}
                            draggable={false}
                            autoplay={true}
                            asNavFor={nav1}
                            ref={slider => (setSlider2(slider))}>
                            {moviesData.map((slide, key) =>
                                <div className="upcomming-slide" key={key}>
                                    <img className="upcomming-slide-image" src={`${GenerateImageUrl(slide?.photos?.Cards_Upcoming_Movies, 176, 260)}`} alt="" />
                                    <div className="thumbnail-meta">
                                        <span>Release Date</span>
                                        <label>{slide.ReleaseDate}</label>
                                    </div>
                                </div>
                            )}
                        </Slider>
                    </div>
                </div>)}
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    {videoUrl !== '' && <ReactPlayer pip={true} controls={true} url={videoUrl} />}
                </Box>
            </Modal>
        </div>
    )
}

export default UpcomingMovies
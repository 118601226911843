import React, { useState, useEffect } from 'react'

import bgImg from '../../images/grid-collection-bg.svg';
import GridSlider from '../GridSlider';
import { Link } from 'react-router-dom';
import GenerateImageUrl from "../../utils/utils"
import { ListModuleData } from "../../api/module"
import { useRef } from "react";
import { useDraggable } from "react-use-draggable-scroll";
var qs = require('qs');

function MoodSlider(props) {
    const [activeMood, setActiveMood] = useState(props.moodsData[0].name);
    const [moviesData, setMoviesData] = useState([]);
    const ref = useRef(); // We will use React useRef hook to reference the wrapping div:
    const { events } = useDraggable(ref); // Now we pass the reference to the useDraggable hook:
  
    useEffect(() => {
        setActiveMood(props.moodsData[0].name)
    }, [props.moodsData]);


    useEffect(() => {
        let requestBody = {
            moods: [activeMood],
            offsetRange: { "offset": 0, "limit": 20 }
        };

        GetModuleData(requestBody)

    }, [activeMood, props.rootPath, props.moodsData]);

    const GetModuleData = (requestBody) => {
        ListModuleData(props.rootPath, requestBody)
            .then((response) => {
                let data = []
                if (props.rootPath === "/podcast") {
                    data = response.data.podcasts;
                } else if (props.rootPath === "/books") {
                    data = response.data.books;
                } else if (props.rootPath === "/games") {
                    data = response.data.games;
                } else if (props.rootPath === "/ott") {
                    data = response.data.movies;
                }

                setMoviesData(data || []);
            })
            .catch((error) => {
                console.error(error);
            });
    }

    const Categries = (value) => {
        setActiveMood(value);
    };

    return (
        <div className='mood-slider-section'>
            <div className='mood-categories'>
                <div className='mood-slider-title'>
                    <h2 className='title'>Tell us what are you in <span>mood</span> for today ...</h2>
                </div>
                <ul className='mood-categories-list'
                    {...events}
                    ref={ref} // add reference and events to the wrapping div 
                >
                    {props.moodsData.map((item, key) => {
                        return (
                            <li className={`list-item ${activeMood === item.name ? 'active' : ''}`} key={key} onClick={() => Categries(item.name)}>
                                <div className='item-img'>
                                    <img src={GenerateImageUrl(item.url, 0, 0, false)} alt={item.name} />
                                </div>
                                <h4 className='mood-categories-title'>{item.name}</h4>
                            </li>
                        )
                    })}
                </ul>
            </div>
            <GridSlider data={moviesData} bgImg={bgImg} rootPath={props.rootPath} />
            <div className='btn-view-all'>
                <Link to={{
                    pathname: `${props.rootPath}/content`,
                    search: qs.stringify({ "moods": [activeMood] }),
                }}>View All</Link>
            </div>
        </div>
    )
}

export default MoodSlider
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { PasswordReset } from "../../api/auth";
import PopupMessage from "../../components/PopupMessage";
import banner from "../../images/auth-page-banner.webp";

function ForgotPassword() {
  const navigate = useNavigate();
  const [showPopup, setShowPopup] = useState(false);
  const [showSucess, setShowSucess] = useState(false);
  const [email, setEmail] = useState("");
  const [emailRequire, setEmailRequire] = useState();
  const [popUpDescription, setPopUpDescription] = useState();


  const handleForgetPassword = (event) => {
    event.preventDefault();
    if(!email){
      setEmailRequire("Please Enter Email.");
      return
    }else if(
      !/^\S+@\S+\.\S+$/.test(email)
      ){
        setEmailRequire("This is not a valid Email.")
      }
    let data = { email: decodeURIComponent(email) };
    PasswordReset(data)
      .then((res) => {
        setShowSucess(true);
        setTimeout(() => {
          setShowSucess(false);
          navigate(`/otp-verify?email=${decodeURIComponent(email)}&action=reset`);
        }, 2000);
      })
      .catch((error) => {
        console.error(error);
        if(error?.response?.data?.email){
          console.log(error?.response?.data?.email[0])
          setPopUpDescription(error?.response?.data?.email[0]);
        }else{
          setPopUpDescription("There is error while completing the action")
        }
        setShowPopup(true);
      });
  };

  const handleClose = () => {
    setShowPopup(false);
    navigate(`/`);
  };

  return (
    <section className="login-section">
      <div className="login-banner">
        <img className="login-image" src={banner} alt="login" />
      </div>
      <div className="login-form-container">
        <form className="login-form" onSubmit={handleForgetPassword}>
          <div className="login-header">
            <h1 className="login-title">Forgot Password</h1>
          </div>
          <div className="form-input">
            <div className="from-group">
              <label htmlFor="">Email ID</label>
              <input
                type="text"
                defaultValue={email}
                onChange={(e) => setEmail(e.target.value)}
                name="email"
              />
              {emailRequire && <span className="error" style={{color:'#f80046'}}>Please Enter email</span>}
            </div>
          </div>
          <button className="btn btn-primary" type="submit">
            Send OTP
          </button>
          {showSucess && (
            <div className="form-msg">We’ve sent OTP to your email address</div>
          )}
        </form>
      </div>
      {showPopup && (
        <PopupMessage
          type="error"
          message="Error!"
          desc={popUpDescription}
          buttonTitle="Back to Home"
          open={showPopup}
          handleClose={()=>setShowPopup(false)}
          handleSubmit={handleClose}
        />
      )}
    </section>
  );
}

export default ForgotPassword;

import React, { useEffect, useState } from "react";
import { useSignIn } from "react-auth-kit";
import { useLocation, useNavigate } from "react-router-dom";
import { VerifyGoogleLogin } from "../../api/auth";
import PopupMessage from "../../components/PopupMessage";

function CallbackPage() {
  const location = useLocation();
  const signIn = useSignIn();
  const [showPopup, setShowPopup] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // Extract the code parameter from the URL
    const searchParams = new URLSearchParams(location.search);
    const code = searchParams.get("code");

    let data = {
      code: code,
    };
    // Make a POST request to your backend API to exchange the code for a token
    VerifyGoogleLogin(data)
      .then((res) => {
        const currentDate = new Date();
        const futureDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth() + 1,
          currentDate.getDate()
        );

        signIn({
          token: res.data.key,
          expiresIn: futureDate.getTime() - currentDate.getTime(),
          tokenType: "Token",
          authState: { name: "Jhon", email: "jhon@auth.com" },
        });
        navigate(`/`);
      })
      .catch((error) => {
        setShowPopup(true);
        console.error(error);
      });
  }, [location]);

  const handleClose = () => {
    navigate(`/login`);
  };
  return (
    <div>
      Processing...{" "}
      {showPopup && (
        <PopupMessage
          type="error"
          message="Error!"
          desc="There is error while login with google"
          buttonTitle="Back"
          open={showPopup}
          handleSubmit={handleClose}
          handleClose={()=>{setShowPopup(false)}}
        />
      )}
    </div>
  );
}

export default CallbackPage;

import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import React, { useEffect, useState } from "react";
import { useSignIn } from "react-auth-kit";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { VerifyGoogleLogin, SignIn } from "../../api/auth";
import PopupMessage from "../../components/PopupMessage";
import banner from "../../images/auth-page-banner.webp";
import FacebookIcon from "../../images/icons/facebook";
import GoggleIcon from "../../images/icons/google";
import logIcon from "../../images/icons/loginIcon.svg";
import { useForm } from "react-hook-form";
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

function Login() {
  const navigate = useNavigate();
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordType, setPasswordType] = useState("password");
  const signIn = useSignIn();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const nextUrl = queryParams.get("next");
  const GAuthCode = queryParams.get("code");
  const schema = yup.object().shape({
    email: yup.string().email('Invalid email').required('Email is required'),
    password: yup.string().required('Password is required').min(8, 'Password must be at least 8 characters'),
  });
  const { register, handleSubmit, formState: { errors }, setError } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if(GAuthCode){
      let data = {code:decodeURIComponent(GAuthCode)}
      console.clear()
      console.log(data)
      handleGoogleLogin(data)
    }
  
    
  }, [])
  
  const handleGoogleLogin = async (data) => {
    try {
      const res = await VerifyGoogleLogin(data);
      const currentDate = new Date();
      const futureDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() + 1,
        currentDate.getDate()
      );
        console.log(res)
      signIn({
        token: res.data.key,
        expiresIn: futureDate.getTime() - currentDate.getTime(),
        tokenType: "Token",
        authState: { name: res.data.user, email: res.data.email, rest:res},
      });

      navigate(nextUrl || `/`);
    }catch(error){ 
      {
        setPopupMessage("There is error while completing the action");
        // setPopupMessage(error);
        console.log(error)
      }
      setShowPopup(true);
    }
  };

  const handleLogin = async (data) => {
    try {
      const res = await SignIn(data);
      const currentDate = new Date();
      const futureDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() + 1,
        currentDate.getDate()
      );

      signIn({
        token: res.data.key,
        expiresIn: futureDate.getTime() - currentDate.getTime(),
        tokenType: "Token",
        authState: { name: res.data.user, email: res.data.email, rest:res},
      });

      navigate(nextUrl || `/`);
    }catch(error){
      if (error?.response?.status === 400) {
        for (const key in error.response?.data || []) {
          const errorMessages = error.response.data[key];
          if (errorMessages == "E-mail is not verified.") {
            navigate(`/otp-verify?email=${email}`);
          }
          setPopupMessage(errorMessages);
        }
      } else {
        setPopupMessage("There is error while completing the action");
      }
      setShowPopup(true);
    }
  };
  
  const handleClose = () => {
    setShowPopup(false);
  };

  const handleSocialRedirect = (url) => {
    window.location.replace(url);
  };
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  return (
    <section className="login-section">
      <div className="login-banner">
        <img className="login-image" src={banner} alt="login" />
      </div>
      <div className="login-form-container">
        <form className="login-form" onSubmit={handleSubmit(handleLogin)}>
          <div className="login-header">
            <img className="login-logo" src={logIcon} alt="EQbits" />
            <h4 className="login-tagline">Hey ! Please login to continue</h4>
          </div>
          <div className="form-input">
            <div className="from-group">
              <label htmlFor="">Email ID</label>
              <input
                type="text"
                {...register("email", { required: "Email is required" })}
              />
              {errors.email && <span className="error">{errors.email.message}</span>}

            </div>
            <div className="from-group">
              <label htmlFor="">Password</label>
              <div className="input-with-btn">
                <input
                  type={passwordType}
                  {...register("password", { required: "Password is required" })}
                />
                <span type="button" className="btn" onClick={togglePassword}>
                  {passwordType === "password" ? (
                    <VisibilityOffOutlinedIcon />
                  ) : (
                    <VisibilityOutlinedIcon />
                  )}
                </span>
              </div>
              {errors.password && <span className="error">{errors.password.message}</span>}
            </div>
          </div>
          <div className="form-flex">
            <FormControlLabel control={<Checkbox />} label="Remember Me" />
            <Link className="forget-link" to="/forgot-password">
              Forgot Password?
            </Link>
          </div>
          <button className="btn btn-primary" type="submit">
            Login
          </button>
          <div className="login-divder">
            <span>OR</span>
          </div>
          <h4 className="form-tag">Login with</h4>
          <div className="login-other">
            <button
              type="button"
              onClick={() =>
                handleSocialRedirect(process.env.REACT_APP_GOOGLE_LOGIN_URL)
              }
            >
              <GoggleIcon />
              <span>Google</span>
            </button>
            <button type="button">
              <FacebookIcon />
              <span>Facebook</span>
            </button>
          </div>
          <div className="form-links">
            Don’t have an account ? <Link to="/signup">Sign Up</Link>
          </div>
        </form>
      </div>
      {showPopup && (
        <PopupMessage
          type="error"
          message="Error!"
          desc={popupMessage}
          buttonTitle="Back"
          open={showPopup}
          handleClose={()=>setShowPopup(false)}
          handleSubmit={handleClose}
        />
      )}
    </section>
  );
}

export default Login;

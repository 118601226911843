import React, { Component, Fragment } from 'react'
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import Grid from '@mui/material/Grid';
// images --------------------------------------
import bannerImg from "../images/banners/events-banner-img.png";
import { writeToUs } from '../api/module';
import PopupMessage from '../components/PopupMessage';
import { useForm } from "react-hook-form";
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

export class Contact extends Component {
    constructor(props) {
        super(props)

        this.state = {
            fullName: "",
            email: "",
            company: "",
            designation: "",
            message: "",
            formSubmit: false
        }
    }
     onsubmit=(data)=>{
        this.setState({isSubmitting:true})
        console.log('texted', data)
        writeToUs('', {data} )
      .then((response) => {
          this.setState({ formSubmit: true });
          this.setState({isSubmitting:false})
      })
      .catch((error) => {
        console.error(error);
        this.setState({isSubmitting:false})
      });
    }
    handlePopupClose = (reset) => {
        reset(); // Reset the form fields
        this.setState({formSubmit:false}); // Set formSubmit to false
      };
    render() {
        return (
            <Fragment>
                <Navbar/>
                <div className='contact-section'>
                    <div className='pb-section'>
                        <div className='pb-image'>
                            <img className='img-fit' src={bannerImg} alt="Contact Us" />
                        </div>
                        <div className='pb-content'>
                            <h1 className='pb-title'>Contact Us</h1>
                            <h6 className='pb-subtitle'>Norem ipsum dolor sit amet, consectetur adipiscing elit.</h6>
                       </div>
                    </div>
                    <div className='contact-form-container'>
                        <div className='contact-title-box'>
                            <h2 className='contact-title'>Let’s Get in Touch</h2>
                            <p className='contact-subtitle'>Vorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.</p>
                        </div>
                        <div className='contact-form'>
                            <ContactForm formSubmit={this.state.formSubmit} onSubmit={this.onsubmit} isSubmitting={this.state.isSubmitting} handlePopupClose={this.handlePopupClose} />
                        </div>
                    </div>
                </div>
                <Footer/>
            </Fragment>
        )
    }
}
// Separate functional component for the contact form
function ContactForm({ formSubmit, onSubmit, handlePopupClose, isSubmitting }) {
    const schema = yup.object().shape({
      name: yup.string().required('Name is required'),
      email: yup.string()
        .required('Email is required')
        .matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{3,}$/, 'Invalid email address'),
      company: yup.string().required('Company Name is required'),
      designation: yup.string().required('Designation is required'),
      message: yup.string().required('Message is required'),
    });
    
    const { register, handleSubmit, formState: { errors }, reset } = useForm({
      resolver: yupResolver(schema),
    });
     
    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={4} columns={12}>
  <Grid item md={3} xs={12}>
    <label>Name</label>
    <input
      className={`form-control ${errors.name ? 'error' : ''}`}
      placeholder="Enter name"
      {...register("name")}
    />
    {errors.name && <span className="error">{errors.name.message}</span>}
  </Grid>

  <Grid item md={3} xs={12}>
    <label>Email address</label>
    <input
      className={`form-control ${errors.email ? 'error' : ''}`}
      placeholder="Enter Email"
      {...register("email")}
    />
    {errors.email && <span className="error">{errors.email.message}</span>}
  </Grid>

  <Grid item md={3} xs={12}>
    <label>Company Name</label>
    <input
      className={`form-control ${errors.company ? 'error' : ''}`}
      placeholder="Enter company name"
      {...register("company")}
    />
    {errors.company && <span className="error">{errors.company.message}</span>}
  </Grid>

  <Grid item md={3} xs={12}>
    <label>Designation</label>
    <input
      className={`form-control ${errors.designation ? 'error' : ''}`}
      placeholder="Enter designation"
      {...register("designation")}
    />
    {errors.designation && <span className="error">{errors.designation.message}</span>}
  </Grid>

  <Grid item md={3} xs={12}>
    <label>Message</label>
    <textarea
      className={`form-control ${errors.message ? 'error' : ''}`}
      placeholder="Type here.."
      {...register("message")}
    ></textarea>
    {errors.message && <span className="error">{errors.message.message}</span>}
  </Grid>
</Grid>

        <div className="contact-action">
          <button className="contact-btn" type="submit" disabled={isSubmitting}>
            {!isSubmitting?'Register':'Submitting...'}
          </button>
        </div>
        {formSubmit && (
          <PopupMessage
            type="success"
            message="Success!"
            desc="Form submitted successfully."
            buttonTitle="Close"
            open={formSubmit}
            handleClose={() => { handlePopupClose(reset) }}
          />
        )}
      </form>
    );
  }
  
export default Contact
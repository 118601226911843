import React, { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Autoplay } from "swiper";
import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";
import bgImg from '../../images/grid-collection-bg.svg';
import GenerateImageUrl from "../../utils/utils";

function GridCollection(props) {
    const [load, setload] = useState(false);
    const [data, setData] = useState([]);
    const [title, setTitle] = useState([]);

    useEffect(() => {
        setData(props.data)
        setload(true)
        if (typeof props.title === 'string') {
            setTitle(props.title.split(" "))
        } else {
            console.log('The variable does NOT store a string');
        }
    }, [props.data, props.title])

    return (
        <Fragment>
            {load === true &&
                <div className='grid-collection-section'>
                    {props.hide !== 1 && <div className="grid-collection">
                        <div className='grid-img'>
                            <img src={props.banner} alt={data?.img_alt} />
                        </div>
                        <div className='grid-content'>
                            <h2 className='theme-title'>
                                {title.map((word, index) => {
                                    if (index === title.length - 1) {
                                        return <span key={index}>{word}</span>;
                                    } else {
                                        return `${word} `;
                                    }
                                })}
                            </h2>
                            <p>{props.paragraph}</p>
                            <Link to={`${props.rootPath}/content/`} className='theme-btn'>Explore</Link>
                        </div>
                    </div>}
                    <div className='grid-slider-box'>
                        <div className='grid-slider-img'>
                            <div className='grid-slider-background' style={{ background: `url(${bgImg})` }}></div>
                            <img className='grid-slider-thumb' src={props.thumbImg} alt='thumb' />
                            <Swiper
                                slidesPerView={'auto'}
                                spaceBetween={16}
                                autoplay={{
                                    delay: 5000,
                                }}
                                modules={[Autoplay]}
                                className="grid-swiper"
                            >
                                {props.data.map((val, key) => {
                                    return (
                                        <SwiperSlide className='grid-slide' key={key}>
                                            <Link to={`${props.rootPath}/details/${val.slug}`}>
                                                <img src={GenerateImageUrl(val?.photos?.Cards_Your_Mood, 300, 235, 0, 0, false)} className='grid-slide-img' alt={val.Title} />
                                            </Link>
                                        </SwiperSlide>
                                    )
                                })}
                            </Swiper>
                        </div>
                    </div>
                </div>}
        </Fragment>
    )
}

export default GridCollection
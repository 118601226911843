import React, { Fragment } from 'react'
export function GenerateImageUrl(
  path,
  width = 268,
  height = 335,
  shouldResize = false
) {
  if (path === undefined || path == null || path.length <= 0) {
    return "";
  }
  if (path.includes("https://ik.imagekit.io/")) {
    return path
  } else {
    return `https://ik.imagekit.io/eqbits/${path.replace("eqbits/", "")}`;
  }
}
function FlatBanner(props) {
  return (
    <Fragment>
        <div className='flat-banner-section' onClick={()=>{console.log(props.image,`       `,GenerateImageUrl(props.image))}}>
        <img className='flat-banner-img' src={GenerateImageUrl(props.image)} alt={props.img_alt} />
            <h1 className='flat-banner-title'>{props.title}</h1>
        </div>
    </Fragment>
  )
}

export default FlatBanner
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import banner from "../../images/auth-page-banner.webp";
import FacebookIcon from "../../images/icons/facebook";
import GoggleIcon from "../../images/icons/google";
import logIcon from "../../images/icons/loginIcon.svg";
import { Signup, VerifyGoogleLogin } from "../../api/auth";
import PopupMessage from "../../components/PopupMessage";
import { useForm } from "react-hook-form";
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Label } from "recharts";
import CheckIcon from '@mui/icons-material/Check';
import TermsPopUP from "../../components/Terms&Conditions";
import PrivacyPopUP from "../../components/PrivacyPolicy";
import { useSignIn } from "react-auth-kit";

function SignUp() {
  const navigate = useNavigate();
  const [showPopup, setShowPopup] = useState(false);
  const [showTerms, setShowTerms] = useState(false);
  const [showpolicy, setShowpolicy] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");
  const [passwordType, setPasswordType] = useState("password");
  const [confirmPasswordType, setConfirmPasswordType] = useState("password");
  const [popupButtonTitle, setPopupButtonTitle] = useState("Back");
  const [popupBackUrl, setPopupBackUrl] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const signIn = useSignIn();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const nextUrl = queryParams.get("next");
  const GAuthCode = queryParams.get("code");
  useEffect(() => {
    if(GAuthCode){
      let data = {code:decodeURIComponent(GAuthCode)}
      console.clear()
      console.log(data)
      handleGoogleLogin(data)
    }
  
    
  }, [])
  
  const handleGoogleLogin = async (data) => {
    try {
      const res = await VerifyGoogleLogin(data);
      const currentDate = new Date();
      const futureDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() + 1,
        currentDate.getDate()
      );
        console.log(res)
      signIn({
        token: res.data.key,
        expiresIn: futureDate.getTime() - currentDate.getTime(),
        tokenType: "Token",
        authState: { name: res.data.user, email: res.data.email, rest:res},
      });

      navigate(nextUrl || `/`);
    }catch(error){ 
      {
        setPopupMessage("There is error while completing the action");
        // setPopupMessage(error);
        console.log(error)
      }
      setShowPopup(true);
    }
  };
  const schema = yup.object().shape({
    username: yup.string().required('User Name is required'),
    email: yup.string().email('Invalid email').required('Email is required'),
    mobileNumber: yup.string().required('Mobile Number is required'),
    password1: yup.string().required('Password is required').min(8, 'Password must be at least 8 characters'),
    password2: yup.string().oneOf([yup.ref('password1'), null], 'Passwords must match'),
  });
  const { register, handleSubmit, formState: { errors }, setError } = useForm({
      resolver: yupResolver(schema),
    });

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };
  const toggleConfirmPassword = () => {
    if (confirmPasswordType === "password") {
      setConfirmPasswordType("text");
      return;
    }
    setConfirmPasswordType("password");
  };

  const handleSignUp = async (data) => {
    try {
      const res = await Signup(data);
      navigate(`/otp-verify?email=${data.email}`);
    } catch (error) {
      if (error?.response?.status === 400) {
        for (const key in error.response?.data || []) {
          const errorMessages = error.response.data[key];
          if (errorMessages === "A user is already registered with this e-mail address.") {
            setPopupButtonTitle("Go to Login");
            setPopupBackUrl("/login");
          }
          setPopupMessage(errorMessages);
        }
      } else {
        setPopupMessage("There is an error while completing the action");
      }
      setShowPopup(true);
    }
  };


  const handleSocialRedirect = (url) => {
    window.location.replace(url);
  };

  const handleClose = () => {
    setShowPopup(false);
    if (popupBackUrl.length>0) {
      navigate(popupBackUrl)
    }
  };
  return (
    <section className="login-section">
      <div className="login-banner">
        <img className="login-image" src={banner} alt="login" />
      </div>
      <div className="login-form-container">
        <form className="login-form sign-up-form" onSubmit={handleSubmit(handleSignUp)}>
          <div className="login-header">
            <img className="login-logo" src={logIcon} alt="Ebits" />
            <h4 className="login-tagline">Hey ! Please signup to continue</h4>
          </div>
          <div className="form-input">
            <div className="from-group">
              <label htmlFor="">User Name</label>
              <input type="text" {...register("username", { required: "User Name is required" })} />
              {errors.username && <span className="error">{errors.username.message}</span>}
            </div>
            <div className="from-group">
              <label htmlFor="">Email ID</label>
              <input type="text" {...register("email", { required: "Email is required" })} />
              {errors.email && <span className="error">{errors.email.message}</span>}
            </div>
            <div className="from-group">
              <label htmlFor="">Mobile Number</label>
              <input type="tel" {...register("mobileNumber", { required: "Mobile Number is required" })} />
              {errors.mobileNumber && <span className="error">{errors.mobileNumber.message}</span>}
            </div>
            <div className="from-group">
              <label htmlFor="">Password</label>
              <div className="input-with-btn">
              <input type={passwordType} {...register("password1", { required: "password is required"})} />
                <span className="btn" onClick={togglePassword}>
                  {passwordType === "password" ? (
                    <VisibilityOffOutlinedIcon />
                  ) : (
                    <VisibilityOutlinedIcon />
                  )}
                </span>
              </div>
              {errors.password1 && <span className="error">{errors.password1.message}</span>}
            </div>
            <div className="from-group">
              <label htmlFor="">Confirm Password</label>
              <div className="input-with-btn">
              <input type={confirmPasswordType} {...register("password2", { required: "password is required" })} />
                <span
                  className="btn"
                  onClick={toggleConfirmPassword}
                >
                  {confirmPasswordType === "password" ? (
                    <VisibilityOffOutlinedIcon />
                  ) : (
                    <VisibilityOutlinedIcon />
                  )}
                </span>
              </div>
              {errors.password2 && <span className="error">{errors.password2.message}</span>}
            </div>
          </div>
          <div className="form-flex" style={{position:'relative', marginTop:'10px'}}>
          <input
                  required
                  type="checkbox"
                  name="rcheck1"
                  id="rcheck1"
                  onChange={(e) => {
                    setIsChecked(e.target.checked);
                    e.target.setCustomValidity('');
                }}
                  onInvalid={(e) => e.target.setCustomValidity('Please accept terms & conditions to proceed.')}
                  onBlur={(e) => e.target.setCustomValidity('')}
                  style={{
                    opacity:'0'
                  }}
                />
          <div style={{display:'flex', justifyContent:'start', position:'absolute',height: "-webkit-fill-available"}}>
            <label  htmlFor="rcheck1"
            style={{
              background: isChecked ? '#D21A4E' : '#07111a',
              color: isChecked ? 'transparent' : '#D21A4E',
              border: isChecked ? 'none' : '2px solid #D21A4E',
              width:'16px',
              height:'16px',
              marginRight:'10px',
              borderRadius:'2px'
            }}
            >
              <span><CheckIcon style={{color:'#07111a', width:'100%', fontWeight:'bold', height:'100%'}}/></span>
            </label>
            <span style={{fontSize:'12px', color:'#959595',fontfamily: "Roboto",fontstyle: 'normal',fontweight: '400'}}>
              I accept the <b onClick={()=>setShowTerms(true)}>Terms & Conditions</b> as well as <b onClick={()=>setShowpolicy(true)}>Privacy Policy</b>.</span>
                </div>
          </div>
          <button className="btn btn-primary" type="submit">
            Signup
          </button>
          <div className="login-divder">
            <span>OR</span>
          </div>
          <h4 className="form-tag">Login with</h4>
          <div className="login-other">
            <button
              type="button"
              onClick={() =>
                handleSocialRedirect(process.env.REACT_APP_GOOGLE_LOGIN_URL)
              }
            >
              <GoggleIcon />
              <span>Google</span>
            </button>
            <button type="button">
              <FacebookIcon />
              <span>Facebook</span>
            </button>
          </div>
          <div className="form-links">
            Don’t have an account ? <Link to="/login">Login</Link>
          </div>
        </form>
      </div>
      {showPopup && (
        <PopupMessage
          type="error"
          message="Error!"
          desc={popupMessage}
          buttonTitle={popupButtonTitle}
          open={showPopup}
          handleClose={()=>setShowPopup(false)}
          handleSubmit={handleClose}
        />
      )}
      {showTerms && (
        <TermsPopUP open={showTerms} handleClose={()=>setShowTerms(false)}/>
      )}
      {showpolicy && (
        <PrivacyPopUP
          open={showpolicy} handleClose={()=>{setShowpolicy(false)}}
        />
      )}
    </section>
  );
}

export default SignUp;

import React, { useEffect, useState } from 'react';
import { PolarAngleAxis, PolarGrid, PolarRadiusAxis, Radar, RadarChart, ResponsiveContainer, LabelList } from 'recharts';
import { chartcolors } from '../../utils/constant';
import { useMediaQuery } from '@mui/material';

function SpiderRadarChart(props) {
    const isTab  = useMediaQuery('(max-width:1024px)')

    const [activeLabel2, setactiveLabel2] = useState(0);
    const [data, setdata] = useState([]);
    const [labels, setlabels] = useState([]);
    const [outerRadius, setouterRadius] = useState(isTab?70:120);

    useEffect(() => {
        setdata(props.data)
        let temp = [];
        if(props.outerRadius){
            setouterRadius(props.outerRadius)
        }
        Object.keys(props.data[0]).map((val,_) => {
            if(val !== 'name') {
                temp.push({ key: val, label: val.replace(/[^a-zA-Z ]/g, " "), fill: chartcolors[_].hex })
            }
        })
        setlabels(temp)
    }, [ props.data, outerRadius]);

    const RenderCustomTick = ({ payload, x, y, dy, angle, textAnchor }) => {
        return (
          <text
            x={x}
            y={y}
            dy={dy}
            textAnchor={textAnchor}
            style={{ fontSize: 12, fill: '#fff' }}
          >
            {payload.value}
          </text>
        );
      };
      
    return (
        <>{data && props.height && <>
            <ResponsiveContainer className="radar-chart" width="100%" height={parseInt(props.height)}>
                <RadarChart outerRadius={props.size?props.size:outerRadius} data={data} height={parseInt(props.height)} onClick={(e) => { e?.activeTooltipIndex !== undefined && setactiveLabel2(e.activeTooltipIndex) }}>
                <PolarGrid/>
                <PolarAngleAxis 
                dataKey="name"
                tick={(props) => (
                    <RenderCustomTick
                      {...props}
                      angle={props.angle}
                    />
                  )}

                />
                    <PolarRadiusAxis angle={45} domain={[0, 10]} />
                {labels.map((val, key) => {
                    return (
                        <Radar
                            name={val.key}
                            dataKey={val.key}
                            stroke={val.fill}
                            fill={val.fill}
                            strokeOpacity={activeLabel2===key?1:0.5}
                            fillOpacity={activeLabel2 === key ? 0.4 : 0}
                        >
                            {activeLabel2 === key && <LabelList dataKey={val.key} position="outside" angle="0" fill='#fff' />}
                        </Radar>
                    )
                })}
            </RadarChart>

        </ResponsiveContainer>
            <div className='chart-meta-info'>
                <h6 className='about-chart-label'>{props.title}</h6>
                <div className='about-meta-slider'>
                    <ul className='chart-data-list'>
                        {labels.map((val, key) => {
                            return (
                                <li
                                    onMouseEnter={() => setactiveLabel2(key)}
                                    onClick={() => { setactiveLabel2(key) }}
                                    key={key}
                                    className={`${activeLabel2 === key ? 'active' : ''}`}
                                    style={{ background: `${activeLabel2 === key ? val.fill + '60' : ''}` }}
                                    data-color={val.fill}>
                                    <span style={{ background: val.fill }}></span><p>{val.label}</p>
                                </li>
                            )
                        })}
                    </ul>
                </div>
            </div>
        </>}</>
    )
}

export default SpiderRadarChart
import React, { useEffect, useState } from 'react';
import { GradientCircularProgress } from "react-circular-gradient-progress";
import TimerIcon from '../../images/icons/timer';
import { useMediaQuery } from 'react-responsive'
import VideoPlayer from '../VideoPlayer';

// icons ---------
// import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import PlayCircleFilledOutlinedIcon from '@mui/icons-material/PlayCircleFilledOutlined';
import ShareIcon from '@mui/icons-material/Share';
import StarOutlineRoundedIcon from '@mui/icons-material/StarOutlineRounded';
import GenerateImageUrl, { GetImageUrl } from '../../utils/utils';
import PopupMessage from '../PopupMessage';

function HeroBanner(props) {
    const [progressBarGraphData, setProgressBarGraphData] = useState([]);
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' })
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 992px)' })
    const [videoUrl, setVideoUrl] = useState("");
    const [errorPopUp, seterrorPopUp] = useState(false);

    useEffect(() => {
        var data = []
        if (props.rootPath === "/ott") {
            data = [
                {
                    "title": "Story",
                    "value": props.moviesData.aspects.story
                },
                {
                    "title": "Direction",
                    "value": props.moviesData.aspects.direction
                },
                {
                    "title": "Performance",
                    "value": props.moviesData.aspects.performance
                },
                {
                    "title": "Music",
                    "value": props.moviesData.aspects.music
                }
            ]
            setProgressBarGraphData(data)
        } else if (props.rootPath === "/books") {
            data = [
                {
                    "title": "Plot",
                    "value": props.moviesData.aspects.plot
                },
                {
                    "title": "Setting",
                    "value": props.moviesData.aspects.setting
                },
                {
                    "title": "Characters",
                    "value": props.moviesData.aspects.characters
                },
                {
                    "title": "Point Of View",
                    "value": props.moviesData.aspects.pointOfView
                },
                {
                    "title": "Style Of Writing",
                    "value": props.moviesData.aspects.styleOfWriting
                },
                {
                    "title": "Takeaway",
                    "value": props.moviesData.aspects.takeaway
                }
            ]
            setProgressBarGraphData(data)
        } else if (props.rootPath === "/podcast") {
            data = [
                {
                    "title": "Introduction",
                    "value": props.moviesData.aspects.introduction
                },
                {
                    "title": "Content",
                    "value": props.moviesData.aspects.content
                },
                {
                    "title": "Audio Quality",
                    "value": props.moviesData.aspects.audioQuality
                },
                {
                    "title": "Voices",
                    "value": props.moviesData.aspects.voices
                },
                {
                    "title": "Outro",
                    "value": props.moviesData.aspects.outro
                }
            ]
            setProgressBarGraphData(data)
        } else if (props.rootPath === "/games") {
            data = [
                {
                    "title": "Graphics",
                    "value": props.moviesData.aspects.graphics
                },
                {
                    "title": "Performance",
                    "value": props.moviesData.aspects.performance
                },
                {
                    "title": "Animation",
                    "value": props.moviesData.aspects.animation
                },
                {
                    "title": "Ease Of Use",
                    "value": props.moviesData.aspects.easeOfUse
                }
            ]
            setProgressBarGraphData(data)
        }
    }, [props.rootPath])



    const handleBtnAction = (path) => {
        if (path === '/ott' || path==='/games') {
            if (props.moviesData?.overview?.gallery?.trailers !== undefined && props.moviesData?.overview?.gallery?.trailers.length > 0) {
                setVideoUrl(props.moviesData?.overview?.gallery?.trailers[0])
            } else {
                console.log('No Trailer Available')
                seterrorPopUp(true)
            }
        }else{
            alert('Not Available')
            // Open the link in a new tab
            // window.open(path, '_blank');
        }
    }

    return (
        <>
            {isTabletOrMobile &&<div className='hero-banner-section mobile'>
                <div className="top">
                    {/* <img className='hero-img' src={GenerateImageUrl(props.moviesData?.overview?.gallery?.photos?.Banner_Details_Page, 0, 0, false)} alt='' /> */}
                    <img className='hero-img' src={GenerateImageUrl(isMobile?(props.moviesData?.overview?.gallery?.photos?.Top_Image_Banner_HomePage_Responsive):(props.moviesData?.overview?.gallery?.photos?.Banner_Details_Page), 0, 0, false)} alt='' />
                    <div className="details">
                    {
                            props.moviesData?.isSeries == 1 ?
                                props.moviesData.episodes && (<h6 className='item-time'><TimerIcon /> <span>{props.moviesData.episodes} episodes</span></h6>) :
                                props.moviesData.length && (<h6 className='item-time'><TimerIcon /> <span>{props.moviesData.length} mins</span></h6>)
                        }
                        <h1 className='item-title'>{props.moviesData.title}</h1>
                        <ul className='item-categaries'>
                            {props.moviesData.genres.slice(0,3).map((val, key) => (
                                <li key={key}>{val}</li>
                            ))}
                        </ul>
                    </div>
                </div>
                <div className='hero-right'>
                            <div className='rating-box-grid'>
                                <div className='rating-box your-rating' onClick={() => props.handleRating()}><StarOutlineRoundedIcon /> <div className='rating-label'>Your Rating</div></div>
                                <div className='rating-box'><GradientCircularProgress
                                    animate={true}
                                    startColor="#FE316C"
                                    middleColor="#ED2860"
                                    endColor="#A5002F"
                                    emptyColor="rgba(255, 255, 255, 0.5)"
                                    size={40}
                                    progress={props.moviesData?.avgUserReviews.avgUserRating * 10 || 0 * 10}
                                /> <div className='rating-label-detail'>
                                        <h6 className='rating-label'>{(props.moviesData?.avgUserReviews.avgUserRating || 0).toFixed(2)} <span>User Rating</span></h6>
                                        <p className='rating-desc'>Based on {props.moviesData?.avgUserReviews.totalReviews || 0} Reviews</p>
                                    </div>
                                </div>
                                <div className='rating-box'><GradientCircularProgress
                                    animate={true}
                                    startColor="#FE316C"
                                    middleColor="#ED2860"
                                    endColor="#A5002F"
                                    emptyColor="rgba(255, 255, 255, 0.5)"
                                    size={40}
                                    progress={props.moviesData?.overview?.averageCriticsRating*10 || 0 * 10}
                                /> <div className='rating-label-detail'>
                                        <h6 className='rating-label'>{props.moviesData?.overview?.averageCriticsRating || 0} <span>Critic Score</span></h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='bottom-content'>
                        <div className='info-graph'>
                            <div className='main-graph'>
                                <GradientCircularProgress
                                    animate={true}
                                    startColor="#FE316C"
                                    middleColor="#ED2860"
                                    endColor="#A5002F"
                                    emptyColor="rgba(255, 255, 255, 0.5)"
                                    size={75}
                                    progress={props.moviesData?.overview?.ebitsRating * 10}
                                />
                                <span className='main-graph-rating'>{props.moviesData?.overview?.ebitsRating}</span>
                                <h4 className='main-graph-title'>EQbits Ratings</h4>
                            </div>
                            <div className='sec-graph'>
                                <ul className='progress-bars'>
                                    {progressBarGraphData.map((val, key) => {
                                        return (
                                            <li key={key}>
                                                <div className='bar-graph'>
                                                    <div className='bar-filled' style={{ width: `${val.value}%` }}></div>
                                                </div>
                                                <p className='graph-title'>{val.title}</p>
                                            </li>
                                        )
                                    })}
                                </ul>
                            </div>
                        </div>
                        <div className='hero-btn'>
                            <button type='button' onClick={() => handleBtnAction(props.rootPath)} className='btn btn-play'><PlayCircleFilledOutlinedIcon />
                                {props.rootPath === '/ott' ? 'Play Trailer' : null}
                                {props.rootPath === '/books' ? 'Read Books' : null}
                                {props.rootPath === '/podcast' ? 'Listen Now' : null}
                                {props.rootPath === '/games' ? 'Play Trailer' : null}
                            </button>
                            <button type='button' className='btn' onClick={() => props.handleReview()}>Write a Review</button>
                            {/* <button type='button' className='btn'><BookmarkBorderIcon /> Wishlist</button> */}
                            <button type='button' className='btn' onClick={() => props.handleShareBtnClick(`${props.rootPath}/details/${props.moviesData.slug}`)}><ShareIcon /> Share</button>
                        </div>
                    </div>
                        <div className='middle-content'>
                        <div className='item-value'>Sentimeter</div>
                        <div className='item-grid'>
                            <div className='item-graph'>
                                <GradientCircularProgress
                                    animate={true}
                                    startColor="#3FFFE8"
                                    middleColor="#3FFFE8"
                                    endColor="#34CEFF"
                                    emptyColor="rgba(255, 255, 255, 0.5)"
                                    size={34}
                                    progress={props.moviesData?.sentimeter?.positive}
                                />
                                <h6 className='graph-title'><span>{props.moviesData?.sentimeter?.positive}</span> Positive</h6>
                            </div>
                            <div className='item-graph'>
                                <GradientCircularProgress
                                    animate={true}
                                    startColor="#FFCE1E"
                                    middleColor="#FFCE1E"
                                    endColor="#FF7B43"
                                    emptyColor="rgba(255, 255, 255, 0.5)"
                                    size={34}
                                    progress={props.moviesData?.sentimeter?.neutral}
                                />
                                <h6 className='graph-title'><span>{props.moviesData?.sentimeter?.neutral}</span> Mixed</h6>
                            </div>
                            <div className='item-graph'>
                                <GradientCircularProgress
                                    animate={true}
                                    startColor="#FF3B3B"
                                    middleColor="#FF3B3B"
                                    endColor="#ED112C"
                                    emptyColor="rgba(255, 255, 255, 0.5)"
                                    size={34}
                                    progress={props.moviesData?.sentimeter?.negative}
                                />
                                <h6 className='graph-title'><span>{props.moviesData?.sentimeter?.negative}</span> Negative</h6>
                            </div>
                        </div>
                    </div>
                    
            </div>}
            {!isTabletOrMobile && <div className='hero-banner-section'>
                <img className='hero-img' src={GenerateImageUrl(props.moviesData?.overview?.gallery?.photos?.Banner_Details_Page, 0, 0, false)} alt='' />
                <div className='hero-left'>
                    <div className='top-content'>
                        {
                            props.moviesData?.isSeries == 1 ?
                                props.moviesData.episodes && (<h6 className='item-time'><TimerIcon /> <span>{props.moviesData.episodes} episodes</span></h6>) :
                                props.moviesData.length && (<h6 className='item-time'><TimerIcon /> <span>{props.moviesData.length} mins</span></h6>)
                        }
                        <h1 className='item-title'>{props.moviesData.title}</h1>
                        <ul className='item-categaries'>
                            {props.moviesData.genres.slice(0,3).map((val, key) => (
                                <li key={key}>{val}</li>
                            ))}
                        </ul>
                        {isTabletOrMobile && <div className='hero-right' datatype='ok'>
                            <div className='rating-box-grid'>
                                <div className='rating-box your-rating' onClick={() => props.handleRating()}><StarOutlineRoundedIcon /> <div className='rating-label'>Your Rating</div></div>
                                <div className='rating-box'><GradientCircularProgress
                                    animate={true}
                                    startColor="#FE316C"
                                    middleColor="#ED2860"
                                    endColor="#A5002F"
                                    emptyColor="rgba(255, 255, 255, 0.5)"
                                    size={40}
                                    progress={props.moviesData?.avgUserReviews.avgUserRating || 0 * 10}
                                /> <div className='rating-label-detail'>
                                        <h6 className='rating-label'>{(props.moviesData?.avgUserReviews.avgUserRating || 0).toFixed(2)} <span>User Rating</span></h6>
                                        <p className='rating-desc'>Based on {props.moviesData?.avgUserReviews.totalReviews || 0} Reviews</p>
                                    </div>
                                </div>
                                <div className='rating-box'><GradientCircularProgress
                                    animate={true}
                                    startColor="#FE316C"
                                    middleColor="#ED2860"
                                    endColor="#A5002F"
                                    emptyColor="rgba(255, 255, 255, 0.5)"
                                    size={40}
                                    progress={props.moviesData?.overview?.averageCriticsRating || 0 * 10}
                                /> <div className='rating-label-detail'>
                                        <h6 className='rating-label'>{props.moviesData?.overview?.averageCriticsRating || 0} <span>Critic Score</span></h6>
                                    </div>
                                </div>
                            </div>
                        </div>}
                    </div>
                    <div className='middle-content'>
                        <div className='item-grid'>
                            <div className='item-value'>Sentimeter</div>
                            <div className='item-graph'>
                                <GradientCircularProgress
                                    animate={true}
                                    startColor="#3FFFE8"
                                    middleColor="#3FFFE8"
                                    endColor="#34CEFF"
                                    emptyColor="rgba(255, 255, 255, 0.5)"
                                    size={34}
                                    progress={props.moviesData?.sentimeter?.positive}
                                />
                                <h6 className='graph-title'><span>{props.moviesData?.sentimeter?.positive}</span> Positive</h6>
                            </div>
                            <div className='item-graph'>
                                <GradientCircularProgress
                                    animate={true}
                                    startColor="#FFCE1E"
                                    middleColor="#FFCE1E"
                                    endColor="#FF7B43"
                                    emptyColor="rgba(255, 255, 255, 0.5)"
                                    size={34}
                                    progress={props.moviesData?.sentimeter?.neutral}
                                />
                                <h6 className='graph-title'><span>{props.moviesData?.sentimeter?.neutral}</span> Mixed</h6>
                            </div>
                            <div className='item-graph'>
                                <GradientCircularProgress
                                    animate={true}
                                    startColor="#FF3B3B"
                                    middleColor="#FF3B3B"
                                    endColor="#ED112C"
                                    emptyColor="rgba(255, 255, 255, 0.5)"
                                    size={34}
                                    progress={props.moviesData?.sentimeter?.negative}
                                />
                                <h6 className='graph-title'><span>{props.moviesData?.sentimeter?.negative}</span> Negative</h6>
                            </div>
                        </div>
                    </div>
                    <div className='bottom-content'>
                        <div className='info-graph'>
                            <div className='main-graph'>
                                <GradientCircularProgress
                                    animate={true}
                                    startColor="#FE316C"
                                    middleColor="#ED2860"
                                    endColor="#A5002F"
                                    emptyColor="rgba(255, 255, 255, 0.5)"
                                    size={75}
                                    progress={props.moviesData?.overview?.ebitsRating * 10}
                                />
                                <span className='main-graph-rating'>{props.moviesData?.overview?.ebitsRating}</span>
                                <h4 className='main-graph-title'>EQbits Ratings</h4>
                            </div>
                            <div className='sec-graph'>
                                <ul className='progress-bars'>
                                    {progressBarGraphData.map((val, key) => {
                                        return (
                                            <li key={key}>
                                                <div className='bar-graph'>
                                                    <div className='bar-filled' style={{ width: `${val.value}%` }}></div>
                                                </div>
                                                <p className='graph-title'>{val.title}</p>
                                            </li>
                                        )
                                    })}
                                </ul>
                            </div>
                        </div>
                        <div className='hero-btn'>
                            <button type='button' onClick={() => handleBtnAction(props.rootPath)} className='btn btn-play'><PlayCircleFilledOutlinedIcon />
                                {props.rootPath === '/ott' ? 'Play Trailer' : null}
                                {props.rootPath === '/books' ? 'Read Books' : null}
                                {props.rootPath === '/podcast' ? 'Listen Now' : null}
                                {props.rootPath === '/games' ? 'Play Trailer' : null}
                            </button>
                            <button type='button' className='btn' onClick={() => props.handleReview()}>Write a Review</button>
                            {/* <button type='button' className='btn'><BookmarkBorderIcon /> Wishlist</button> */}
                            <button type='button' className='btn' onClick={() => props.handleShareBtnClick(`${props.rootPath}/details/${props.moviesData.slug}`)}><ShareIcon /> Share</button>
                        </div>
                    </div>
                </div>
                {!isTabletOrMobile && <div className='hero-right'>
                    <div className='rating-box-grid'>
                        <div className='rating-box your-rating' onClick={() => props.handleRating()}><StarOutlineRoundedIcon /> <div className='rating-label'>Your Rating</div></div>
                        <div className='rating-box'><GradientCircularProgress
                            animate={true}
                            startColor="#FE316C"
                            middleColor="#ED2860"
                            endColor="#A5002F"
                            emptyColor="rgba(255, 255, 255, 0.5)"
                            size={40}
                            progress={props.moviesData?.avgUserReviews.avgUserRating * 10 || 0 * 10}
                        /> <div className='rating-label-detail'>
                                <h6 className='rating-label'>{(props.moviesData?.avgUserReviews.avgUserRating || 0).toFixed(2)} <span>User Rating</span></h6>
                                <p className='rating-desc'>Based on {props.moviesData?.avgUserReviews.totalReviews || 0} Reviews</p>
                            </div>
                        </div>
                        <div className='rating-box'><GradientCircularProgress
                            animate={true}
                            startColor="#FE316C"
                            middleColor="#ED2860"
                            endColor="#A5002F"
                            emptyColor="rgba(255, 255, 255, 0.5)"
                            size={40}
                            progress={props.moviesData?.overview?.averageCriticsRating * 10 || 0 * 10}
                        /> <div className='rating-label-detail'>
                                <h6 className='rating-label'>{props.moviesData?.overview?.averageCriticsRating || 0} <span>Critic Score</span></h6>
                            </div>
                        </div>
                    </div>
                </div>}
            </div>}
            {videoUrl && <VideoPlayer videoUrl={videoUrl} closeModal={() => setVideoUrl("")} />}
            {errorPopUp && <PopupMessage 
            type="error" 
            message="No Trailer Available" 
            desc="Please wait for the trailer to be released" 
            buttonTitle="Okay" 
            open={errorPopUp} 
            handleClose={() => seterrorPopUp(false)} />}
        </>
    )
}

export default HeroBanner